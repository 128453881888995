import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";

class ChatService {
  /** Conversations */
  getAllConversations(page = 1, searchTerm, take = 50) {
    let url = UrlConstants.GET_ALL_CONVERSATIONS;

    url = url + "?take=" + take + "&page=" + page;
    if (searchTerm?.length > 0) {
      url = url + "&q=" + searchTerm;
    }

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  createConversation(payload) {
    let url = UrlConstants.CREATE_CONVERSATION;

    return apiClient().post(url, payload).catch(handleError);
  }

  deleteConversation(conversationId) {
    const url = UrlConstants.DELETE_CONVERSATION.replace(
      /{conversationId}/,
      conversationId
    );

    return apiClient().delete(url).then(handleResponse).catch(handleError);
  }

  getConversationMessages(conversationId, page = 1, searchTerm, take = 50) {
    let url = UrlConstants.GET_CONVERSATION_MESSAGES.replace(
      /{conversationId}/,
      conversationId
    );
    url = url + "?take=" + take + "&page=" + page;
    if (searchTerm?.length > 0) {
      url = url + "&q=" + searchTerm;
    }

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  createNewMessage(conversationId, payload) {
    let url = UrlConstants.CREATE_NEW_MESSAGE.replace(
      /{conversationId}/,
      conversationId
    );
    // const formData = new FormData();
    // Object.entries(payload).forEach(([key, value]) => {
    //   formData.append(key, value);
    // });
    return apiClient()
      .sendMessage(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  createMessage(id, type, payload) {
    const url =
      type === "private"
        ? `/conversations/${id}/messages`
        : `/groups/${id}/messages`;

    return apiClient()
      .sendMessage(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  conversationMarkedAsRead = (conversationId) => {
    const url = UrlConstants.CONVERSATION_MARKED_READ.replace(
      /{conversationId}/,
      conversationId
    );

    return apiClient().put(url).then(handleResponse).catch(handleError);
  };

  /** Groups */
  createGroup(payload) {
    const url = UrlConstants.CREATE_GROUP;

    return apiClient().post(url, payload);
  }

  getAllGroups() {
    const url = UrlConstants.GET_ALL_GROUPS;

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  fetchGroups() {
    const url = UrlConstants.GET_ALL_GROUPS;

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  fetchGroupById(groupId) {
    const url = UrlConstants.FETCH_GROUP_BY_ID.replace(/{groupId}/, groupId);

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  deleteGroup(groupId) {
    const url = UrlConstants.DELETE_GROUP.replace(/{groupId}/, groupId);

    return apiClient().delete(url).then(handleResponse).catch(handleError);
  }

  fetchGroupMessages(groupId, page = 1, searchTerm, take = 50) {
    let url = UrlConstants.FETCH_GROUP_MESSAGES.replace(/{groupId}/, groupId);

    url = url + "?take=" + take + "&page=" + page;
    if (searchTerm?.length > 0) {
      url = url + "&q=" + searchTerm;
    }

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  postGroupMessage({ groupId, content }) {
    const url = UrlConstants.POST_GROUP_MESSAGE.replace(/{groupId}/, groupId);

    const payload = {
      content,
    };

    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  addGroupRecipient({ groupId, userId }) {
    const url = UrlConstants.ADD_GROUP_RECIPIENT.replace(/{groupId}/, groupId);

    const payload = {
      recipientId: userId,
    };

    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  removeGroupRecipient({ groupId, userId }) {
    const url = UrlConstants.REMOVE_GROUP_RECIPIENT.replace(
      /{groupId}/,
      groupId
    ).replace(/{userId}/, userId);

    return apiClient().delete(url).then(handleResponse).catch(handleError);
  }

  groupMarkedAsRead = (groupId) => {
    const url = UrlConstants.GROUP_MARKED_READ.replace(/{groupId}/, groupId);

    return apiClient().put(url).then(handleResponse).catch(handleError);
  };

  /** RECIPIENTS */

  fetchRecipients = () => {
    const url = UrlConstants.FETCH_RECIPIENT;

    return apiClient().get(url).then(handleResponse).catch(handleError);
  };

  fetchRecipientById = (recipientId) => {
    const url = UrlConstants.FETCH_RECIPIENT_BY_ID.replace(
      /{recipientId}/,
      recipientId
    );

    return apiClient().get(url).then(handleResponse).catch(handleError);
  };

  /** UPLOAD MEDIA and IMAGES */
  getChatPresignedURL(file) {
    let url =
      UrlConstants.GET_PRESIGNED_URL + "?key=chat&op=upload&file=" + file;

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  uploadChatImagePut(imageData, urlAws) {
    let url = urlAws;

    const options = {
      headers: {
        "Content-Type": "image/*",
      },
    };

    return apiClient()
      .uploadImage(url, imageData, options)
      .then(this.handleS3ImageUploadResponse)
      .catch(handleError);
  }

  handleS3ImageUploadResponse(response) {
    if (response.status === 200) {
      return response.config.data;
    } else {
      handleError(response);
    }
  }

  getChatImageURL(fileName) {
    let url =
      UrlConstants.GET_PRESIGNED_URL + "?key=chat&op=get&file=" + fileName;
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }
}

export default new ChatService();
