import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid, Card, InputLabel, Input, FormHelperText, Button, Link } from "@material-ui/core";
import PhoneInput, { getCountryCallingCode, isPossiblePhoneNumber } from 'react-phone-number-input';
import { validateMandatoryValue, validateMaximumLength } from "../../../helpers/Validation";
import ChildStepper from "../ChildStepper/ChildStepper";
import { APP_CONSTANTS } from "../../../constants/GenericConstants";

const StepTwo = (props) => {

    const { t } = useTranslation();

    const { step, data, setData, errors, setErrors, onCancel, onSubmit, changeStep, stepBack } = props;
    const { homeAddress, schoolName, schoolAddress, contactName1, contactPhone1, contactName2, contactPhone2, newsletterSubscribed } = data;
    const { schoolNameError, contactName1Error, contactPhone1Error, contactName2Error, contactPhone2Error } = errors;

    const [formSubmitted, setFormSubmitted] = useState(false);

    var phoneCode1 = ""
    var phoneCode2 = ""


    const handleInputChange = (event) => {
        const name = event.target && event.target.name;
        const value = event.target && event.target.value ? event.target.value : "";
        setData({
            ...data,
            [name]: value,
        });
        if (name === "schoolName") {
            validateSchoolName(value);
        }
        if (name === "contactName1") {
            validateContactName1(value);
        }
        if (name === "contactName2") {
            validateContactName2(value);
        }
    }

    const validateSchoolName = (schoolName) => {
        if (validateMandatoryValue(schoolName) && !validateMaximumLength(schoolName, APP_CONSTANTS.MAX_SCHOOL_NAME_LENGTH)) {
            setErrors({ ...errors, schoolNameError: t("nameLengthErrorMessage").replace(/{maxLength}/, APP_CONSTANTS.MAX_SCHOOL_NAME_LENGTH) });
        } else {
            setErrors({ ...errors, schoolNameError: "" });
        }
    }

    const validateContactName1 = (contactName) => {
        if (validateMandatoryValue(contactName) && !validateMaximumLength(contactName, APP_CONSTANTS.MAX_LENGTH_CONTACT)) {
            setErrors({ ...errors, contactName1Error: t("contactNameLengthError").replace(/{maxLength}/, APP_CONSTANTS.MAX_LENGTH_CONTACT) });
        } else {
            setErrors({ ...errors, contactName1Error: "" });
        }
    }
    const validateContactName2 = (contactName) => {
        if (validateMandatoryValue(contactName) && !validateMaximumLength(contactName, APP_CONSTANTS.MAX_LENGTH_CONTACT)) {
            setErrors({ ...errors, contactName2Error: t("contactNameLengthError").replace(/{maxLength}/, APP_CONSTANTS.MAX_LENGTH_CONTACT) });
        } else {
            setErrors({ ...errors, contactName2Error: "" });
        }
    }

    const handleContact1PhoneChange = (value) => {

        if (value === undefined) {
            // setData({
            //     ...data,
            //     contactPhone1: phoneCode1,
            // });
        }
        else {
            setData({
                ...data,
                contactPhone1: value,
            });
        }

        if (formSubmitted) {
            validatePhone1(value)
        }

    }

    const handleContact2PhoneChange = (value) => {

        if (value === undefined) {
            // setData({
            //     ...data,
            //     contactPhone2: phoneCode2,
            // });
        }
        else {
            setData({
                ...data,
                contactPhone2: value,
            });
        }

        if (formSubmitted) {
            validatePhone2(value)
        }

    }

    const validatePhone1 = (phone) => {
        if (validateMandatoryValue(phone) && !isPossiblePhoneNumber(phone)) {
            setErrors({
                ...errors,
                contactPhone1Error: t("invalidPhone"),
            });
        } else {
            setErrors({
                ...errors,
                contactPhone1Error: "",
            });
        }
    }
    const validatePhone2 = (phone) => {
        if (validateMandatoryValue(phone) && !isPossiblePhoneNumber(phone)) {
            setErrors({
                ...errors,
                contactPhone2Error: t("invalidPhone"),
            });
        } else {
            setErrors({
                ...errors,
                contactPhone2Error: "",
            });
        }
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    const onCancelPress = () => {
        onCancel();
    }

    const isFormValid = () => {
        let localSchoolNameError = "";
        let localContactName1Error = "";
        let localContactPhone1Error = "";
        let localContactName2Error = "";
        let localContactPhone2Error = "";
        let valid = true;

        if (validateMandatoryValue(schoolName) && !validateMaximumLength(schoolName, APP_CONSTANTS.MAX_SCHOOL_NAME_LENGTH)) {
            localSchoolNameError = t("nameLengthErrorMessage").replace(/{maxLength}/, APP_CONSTANTS.MAX_SCHOOL_NAME_LENGTH);
            valid = false;
        }

        if (validateMandatoryValue(contactName1) && !validateMaximumLength(contactName1, APP_CONSTANTS.MAX_LENGTH_CONTACT)) {
            localContactName1Error = t("contactNameLengthError").replace(/{maxLength}/, APP_CONSTANTS.MAX_LENGTH_CONTACT);
            valid = false;
        }
        if (validateMandatoryValue(contactName2) && !validateMaximumLength(contactName2, APP_CONSTANTS.MAX_LENGTH_CONTACT)) {
            localContactName2Error = t("contactNameLengthError").replace(/{maxLength}/, APP_CONSTANTS.MAX_LENGTH_CONTACT);
            valid = false;
        }

        if (validateMandatoryValue(contactPhone1) && !isPossiblePhoneNumber(contactPhone1)) {
            localContactPhone1Error = t("invalidPhone");
            valid = false;
        }
        if (validateMandatoryValue(contactPhone2) && !isPossiblePhoneNumber(contactPhone2)) {
            localContactPhone2Error = t("invalidPhone");
            valid = false;
        }



        setErrors({
            ...errors,
            schoolNameError: localSchoolNameError,
            contactName1Error: localContactName1Error,
            contactPhone1Error: localContactPhone1Error,
            contactName2Error: localContactName2Error,
            contactPhone2Error: localContactPhone2Error,
        });
        return valid;
    }

    const onSubmitPress = () => {
        setFormSubmitted(true)
        if (!isFormValid()) {
            return;
        }
        onSubmit();
    }

    const goToOtherStep = (index) => {
        if (!isFormValid()) {
            return;
        }
        changeStep(index);
    }

    const onStepBackPress = () => {
        if (!isFormValid()) {
            return;
        }
        stepBack();
    }

    const handleSubscriptionChange = (value) => {

        if (value !== undefined) {
            setData({
                ...data,
                newsletterSubscribed: value
            });
        }
    }

    return (
        <div>
            <Card className="add-child-flow">
                <ChildStepper activeStep={step} goToOtherStep={goToOtherStep} />
                <form className="py-15">
                    <Container maxWidth="false">
                        <Grid container spacing={2} className="pb-16">
                            <Grid item md={12} sm={6} xs={12}>                                <div>
                                    <InputLabel htmlFor="homeAddress">{t("homeAddress")}</InputLabel>
                                    <Input id="homeAddress" name="homeAddress" type="text" value={homeAddress || ""} onChange={handleInputChange} placeholder={t("homeAddressPlaceholder")} />
                                </div>
                            </Grid>
                        </Grid>

                        {/* <h6 className="title-left-stroke"><span>{t("schoolDetails")}</span></h6> */}

                        {/* <Grid container spacing={2} className="pb-16">
                            <Grid item sm={6} xs={12}>
                                <div>
                                    <InputLabel htmlFor="schoolName">{t("schoolName")}</InputLabel>
                                    <Input id="schoolName" name="schoolName" type="text" value={schoolName || ""} onChange={handleInputChange} placeholder={t("schoolNamePlaceholder")} />
                                    {
                                        showInputError(schoolNameError) && <FormHelperText error={showInputError(schoolNameError)}>{schoolNameError}</FormHelperText>
                                    }
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <div>
                                    <InputLabel htmlFor="schoolAddress">{t("schoolAddress")}</InputLabel>
                                    <Input id="schoolAddress" name="schoolAddress" type="text" value={schoolAddress || ""} onChange={handleInputChange} placeholder={t("schoolAddressPlaceholder")} />
                                </div>
                            </Grid>
                        </Grid> */}

                        {/* <h6 className="title-left-stroke"><span>{t("emergencyContactDetails")}</span></h6> */}

                        <Grid container spacing={2} className="pb-16">
                            {/* <Grid item sm={6} xs={12}>
                                <div>
                                    <InputLabel htmlFor="contactName1">{t("contactNamePlaceholder")}</InputLabel>
                                    <Input id="contactName1" name="contactName1" type="text" value={contactName1 || ""} onChange={handleInputChange} placeholder={t("contactNamePlaceholder")} />
                                    {
                                        showInputError(contactName1Error) && <FormHelperText error={showInputError(contactName1Error)}>{contactName1Error}</FormHelperText>
                                    }
                                </div>
                            </Grid> */}
                            <Grid item sm={6} xs={12}>
                                <div>
                                    <InputLabel>{t("contactPhonePlaceholder")}</InputLabel>
                                    <PhoneInput
                                        value={contactPhone1}
                                        defaultCountry={"US"}
                                        placeholder={t("contactPhonePlaceholder")}
                                        countryCallingCodeEditable={false}
                                        withCountryCallingCode
                                        international
                                        onCountryChange={(e) => {
                                            try {
                                                phoneCode1 = "+" + getCountryCallingCode(e)
                                            }
                                            catch (err) { }
                                        }}
                                        countryOptionsOrder={["US", "..."]}
                                        code
                                        onChange={handleContact1PhoneChange} />
                                    {
                                        showInputError(contactPhone1Error) && <FormHelperText error={showInputError(contactPhone1Error)}>{contactPhone1Error}</FormHelperText>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={2} className="pb-16">
                        {/* 
                            <Grid item sm={6} xs={12}>
                                <div>
                                    <InputLabel htmlFor="contactName2">{t("contactNamePlaceholder")} 2</InputLabel>
                                    <Input id="contactName2" name="contactName2" type="text" value={contactName2 || ""} onChange={handleInputChange} placeholder={t("contactNamePlaceholder")} />
                                    {
                                        showInputError(contactName2Error) && <FormHelperText error={showInputError(contactName2Error)}>{contactName2Error}</FormHelperText>
                                    }
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <div>
                                    <InputLabel>{t("contactPhonePlaceholder")} 2</InputLabel>
                                    <PhoneInput
                                        value={contactPhone2}
                                        defaultCountry={"US"}
                                        placeholder={t("contactPhonePlaceholder")}
                                        withCountryCallingCode
                                        countryCallingCodeEditable={false}
                                        international
                                        onCountryChange={(e) => {
                                            try {
                                                phoneCode2 = "+" + getCountryCallingCode(e)
                                            }
                                            catch (err) { }
                                        }}
                                        countryOptionsOrder={["US", "..."]}
                                        code
                                        onChange={handleContact2PhoneChange} />
                                    {
                                        showInputError(contactPhone2Error) && <FormHelperText error={showInputError(contactPhone2Error)}>{contactPhone2Error}</FormHelperText>
                                    }
                                </div>
                            </Grid>
                            */}
                            <Grid item md={12} sm={6} xs={12}>
                            <div className="subscribe-newsletter">
                                <label>
                                <input type="checkbox" 
                                // value={newsletterSubscribed} onChange={handleSubscriptionChange}
                                 defaultChecked={newsletterSubscribed}
                                 onChange={() => handleSubscriptionChange(!newsletterSubscribed)}
                                 ></input>
                                    {t("subscribeToNewsletter")}
                                </label>
                            </div>
                        </Grid>
                        </Grid> 

                    </Container>
                </form>
            </Card>
            <div className="buttons-footer">
                <div className="btn-delete">
                    <Link onClick={onCancelPress}>{t("cancel")}</Link>
                </div>
                <div className="btns-right">
                    <Button variant="contained" disableElevation onClick={onStepBackPress}>{t("back")}</Button>
                    <Button color="primary" variant="contained" disableElevation onClick={onSubmitPress}>{t("continue")}</Button>
                </div>
            </div>
        </div>
    );
};

export default StepTwo;