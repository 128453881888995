import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { parentAddedEntity } from "../../helpers/GenericHelper";
import AssetConstants from "../../constants/AssetConstants";

const UserCell = (props) => {
  const { t } = useTranslation();
  const { entity } = props;
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="table-profile">
      <div className="imgLoader">
        <figure>
          {!loaded && <p className="loader"></p>}
          <img
            onLoad={() => {
              setLoaded(true);
            }}
            src={
              entity.createdByImage && entity.createdByImage.includes(".")
                ? entity.createdByImage
                : AssetConstants.profilePlaceholder
            }
            alt="Author Profile"
          />
        </figure>
      </div>
      {parentAddedEntity(entity) ? entity.createdByName : t("thrive")}
    </div>
  );
};

export default UserCell;
