import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Input, FormHelperText, Button } from "@material-ui/core";
import { validateEmail, validateMandatoryValue } from "../../helpers/Validation";
import AssetConstants from "../../constants/AssetConstants";
import { forgotPassword } from "../../store/actions/Authentication";
import { Alert } from "@material-ui/lab";
import Loader from "../../components/Loader/Loader";
import SnackbarAlert from "../../components/SnackbarAlert/SnackbarAlert";


const ForgotPasswordScreen = (props) => {
    document.title = "Thrive App - Forgot Password"
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.authentication);

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [apiError, setApiError] = useState("");
    const [apiSuccess, setApiSuccess] = useState("");


    const handleChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";

        switch (event.target.name) {
            case "email":
                setEmail(input);
                break;

            default:
                break;
        }

        if (formSubmitted) {
            validateEmailCurrent(input)
        }
    }


    const validateEmailCurrent = (email) => {
        if (!validateMandatoryValue(email)) {
            setEmailError(t("emailRequired"));
            return false;
        } else if (!validateEmail(email)) {
            setEmailError(t("invalidEmailError"));
            return false;
        } else {
            setEmailError("");
            return true;
        }
    }



    const showInputError = (field) => {
        return field.length > 0;
    }



    const onSubmit = (event) => {
        event.preventDefault();
        setFormSubmitted(true);
        setApiError("");
        setApiSuccess("")
        if (validateEmailCurrent(email)) {
            const data = {
                "email": email.toLocaleLowerCase().trim()
            };
            dispatch(forgotPassword(data, onEmailSentSuccess, onEmailSentFailure));
        }
    }

    const onEmailSentSuccess = (res) => {
        setApiSuccess(res.message)
    }
    const onEmailSentFailure = (msg) => {
        setApiError(msg)
    }

    return (
        <div className="is-login">

            {userState.loading && <Loader />}

            <SnackbarAlert show={apiSuccess !== ""} message={apiSuccess} duration={4000} onCloseAlert={() => setApiSuccess("")} />

            <Grid container>
                <Grid item xs={12} sm={6} className="login-form">
                    <div className="login-form-content forgotpassword">
                        <img className="login-logo" src={AssetConstants.logo} />
                        <form>
                            <h1><span><i onClick={() => history.goBack()} class="ticon-arrow-right"></i></span> {t("forgotPasswordHeading")}</h1>
                            <p className="forgotPasswordText">{t("forgotPasswordText")}</p>
                            {
                                apiError && <Alert severity="error">{apiError}</Alert>
                            }

                            <div className="form-field">
                                {/* <InputLabel htmlFor="email" required>{t("email")}</InputLabel> */}
                                <Input id="email" name="email" required type="email" value={email || ""} placeholder={t("emailPlaceholder")} onChange={handleChange} error={showInputError(emailError)} />
                                {
                                    showInputError(emailError) && <FormHelperText error={showInputError(emailError)}>{emailError}</FormHelperText>
                                }
                            </div>


                            <div className="form-field login-button">
                                <Button type="submit" color="primary" variant="contained" disableElevation onClick={onSubmit}>{t("resetForgotPasswordButtonTitle")}</Button>
                            </div>
                        </form>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} className="screen-half-splash">

                </Grid>
            </Grid>
        </div>
    );
};

export default ForgotPasswordScreen;