import React, { forwardRef, Fragment, useRef } from "react";
import { Avatar, Box, makeStyles } from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { MessageItemContainerBody } from "./MessageItemContainerBody";
import { formatDateTime } from "../../../../helpers/GenericHelper";
import MessageTime from "./ MessageTime";
import { fetchMessages } from "../../../../store/actions/Chat";
import { useParams } from "react-router-dom";
import { fetchGroupMessages } from "../../../../store/actions/Chat/Group";

const useStyles = makeStyles((theme) => ({
  messageContainerStyle: {
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    padding: "10px 0",
    display: "flex",
    flexDirection: "column-reverse",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      // backgroundColor: ${({ theme }) => theme.background.primary},
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      // backgroundColor: ${({ theme }) => theme.background.secondary},
      backgroundColor: "#babac0",
      width: "5px",
      borderRadius: "20px",
    },
  },

  messageItemContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "flex-start",
    // padding: "5px 18px",
    wordBreak: " break-word",
  },

  messageItemWrapper: {
    padding: "5px 18px",
  },

  messageItemWrapperTitle: {
    fontSize: 12,
    color: "#cce",
  },
}));

const MessageContainer = forwardRef((_, ref) => {
  const classes = useStyles();
  const { id } = useParams();

  const chatContainerRef = useRef(null);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authentication.user);

  const {
    messages: conversationMessages,
    searchTerm: c_search,
    loading: conversationMessagesLoading,
    meta: conversationMessagesMeta,
  } = useSelector((state) => state.messages);
  // conversationMessages = conversationMessages.find((conversationMessage) => conversationMessage.id === id);//TODO:

  const {
    messages: groupMessages,
    searchTerm: g_search,
    loading: groupMessagesLoading,
    meta: groupMessagesMeta,
  } = useSelector((state) => state.groupMessages);
  // groupMessages = groupMessages.find((groupMessage) => groupMessage.id === id);//TODO:

  // const conversationMessages = useSelector((state) =>
  //   selectConversationMessage(state, parseInt(id))
  // );
  // const groupMessages = useSelector((state) =>
  //   selectGroupMessage(state, parseInt(id))
  // );
  const selectedType = useSelector(
    (state) => state.selectedConversationType.type
  );

  const mapMessages = (message, index, messages) => {
    const currentMessage = messages[index];
    const nextMessage = messages[index + 1];
    const isAuthor = user?.id === currentMessage?.user_id;

    const getDate = () => {
      if (
        currentMessage?.createdAt.split("T")[0] !==
        nextMessage?.createdAt.split("T")[0]
      ) {
        let previousDate = formatDateTime(new Date(currentMessage?.createdAt));
        // currentDate = message.data.dateTime;

        return (
          <p
            style={{
              marginTop: "20px",
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "OpenSans",
              textAlign: "center",
              color: "#666666",
            }}
          >
            {previousDate}
          </p>
        );
      } else {
        return null;
      }
    };

    return (
      <Fragment key={message.id}>
        <Box className={classes.messageItemWrapper}>
          <Box
            className={classes.messageItemContainer}
            style={{
              justifyContent: isAuthor ? "flex-end" : "flex-start",
            }}
          >
            {!isAuthor ? (
              <Avatar
                sizes="small"
                alt={currentMessage?.user?.photo}
                src={currentMessage?.user?.photo}
              />
            ) : null}

            {isAuthor ? <MessageTime message={currentMessage} /> : null}

            <MessageItemContainerBody message={message} isAuthor={isAuthor} />

            {!isAuthor ? <MessageTime message={currentMessage} /> : null}
          </Box>
        </Box>
        {getDate()}
      </Fragment>
    );
  };

  const isLoading =
    selectedType === "private"
      ? conversationMessagesLoading
      : groupMessagesLoading;

  const hasMore =
    selectedType === "private"
      ? conversationMessagesMeta?.hasNextPage
      : groupMessagesMeta?.hasNextPage;

  const fetchMore = () => {
    if (!isLoading && hasMore) {
      if (selectedType === "private") {
        dispatch(
          fetchMessages(id, conversationMessagesMeta.page + 1, c_search)
        );
      }

      if (selectedType === "group") {
        dispatch(fetchGroupMessages(id, groupMessagesMeta.page + 1, g_search));
      }
    }
  };

  // function handleChatScroll() {
  //   const chatContainer = chatContainerRef.current;
  //   if (
  //     chatContainer.scrollTop >=
  //     chatContainer.scrollHeight - chatContainer.offsetHeight - 100
  //   ) {
  //     console.log("true");
  //     // fetchMore();
  //   }
  // }

  return (
    <Box
      ref={ref}
      className={classes.messageContainerStyle}
      // onScroll={handleChatScroll}
      // onScroll={(e) => {
      //   const node = e.target;
      //   const scrollTopMax = node.scrollHeight - node.clientHeight;
      //   console.log({ scrollTopMax, client: node.scrollTop - 50 });
      //   console.log(
      //     node.scrollTop <=
      //     node.scrollHeight - node.offsetHeight - 100
      //   );
      //   if (-scrollTopMax === node.scrollTop - 1) {
      //     console.log("scroll");
      //     fetchMore();
      //   }
      // }}
    >
      <>
        {selectedType === "private"
          ? conversationMessages?.map(mapMessages)
          : groupMessages?.map(mapMessages)}
      </>

      {/* {isLoading && <div className="loader mr-auto" />} */}
    </Box>
  );
});

export default MessageContainer;
