import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getImageURL } from "../../store/actions/Child";
import AssetConstants from "../../constants/AssetConstants";

const ChildSelectItem = ({ child, formData, setFormData }) => {
  const { children } = formData;
  const dispatch = useDispatch();
  const [childImage, setChildImage] = useState(
    AssetConstants.profilePlaceholder
  );

  useEffect(() => {
    if (child?.childPhoto?.includes(".")) {
      dispatch(getImageURL(child?.childPhoto, onGetUrl, onFailedUrl));
    }
  }, []);

  const onGetUrl = (res) => {
    setChildImage(res.data.url);
  };
  const onFailedUrl = (err) => {
    // alert(JSON.stringify(err))
  };

  return (
    <div className="check-block">
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              const input =
                e.target && e.target.checked ? e.target.checked : false;
              if (input) {
                var arr = children;
                arr.push(child.childId);
                setFormData({ ...formData, children: arr });
                // alert("Child ID: " + child.childId + " Added!")
              } else {
                var arr = children;
                arr = arr.filter((item) => item !== child.childId);
                setFormData({ ...formData, children: arr });
                // alert("Child ID: " + child.childId + " Removed!")
              }
            }}
          />
        }
        label={
          // <React.Fragment>
          <p>
            <figure>
              <img
                src={childImage}
                alt={child.childFirstName}
                className="profile-img"
              />
            </figure>
            <strong>{child.childFirstName}</strong>
          </p>
          // </React.Fragment>
        }
      />
    </div>
  );
};
export default ChildSelectItem;
