import { CONVERSATIONS } from "../../../constants/ActionConstants";

const INITIAL_STATE = {
  loading: false,
  conversations: [],
  search: "",
  error: null,
};

function conversations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONVERSATIONS.CONVERSATIONS_FETCH_PENDING:
      return { ...state, loading: true };

    case CONVERSATIONS.CONVERSATIONS_FETCH_SUCCESS:
      return {
        ...state,
        conversations: action.payload.data,
        loading: false,
        error: null,
      };

    case CONVERSATIONS.CONVERSATIONS_CREATE_SUCCESS:
      const all_Conversations = state.conversations.filter(
        (conversation) => conversation.id !== action.payload.data.id
      );


      return {
        ...state,
        conversations: [action.payload.data, ...all_Conversations],
      };

    case CONVERSATIONS.CONVERSATIONS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error:
          action?.payload?.data?.message || "Unable to create conversation",
      };

    case CONVERSATIONS.CONVERSATIONS_DELETE:
      return { ...state, loading: true };

    case CONVERSATIONS.CONVERSATIONS_DELETE_SUCCESS:
      const conversations = state.conversations.filter(
        (conversation) => conversation.id !== action.payload.data.id
      );

      return {
        ...state,
        conversations: [...conversations],
        loading: false,
      };

    case CONVERSATIONS.CONVERSATIONS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error:
          action?.payload?.data?.message || "Unable to delete the conversation",
      };

    case CONVERSATIONS.CONVERSATION_REMOVE:
      const updatedConversations = state.conversations.filter(
        (conversation) => conversation.id !== action.payload.id
      );

      return {
        ...state,
        conversations: [...updatedConversations],
      };

    case CONVERSATIONS.CONVERSATION_ADD:
      const conversationToAdd = action.payload;
      // const conversationIndex = state.conversations.findIndex(
      //   (c) => c.id === conversationToAdd.id
      // );
      // state.conversations.splice(conversationIndex, 1);
  
      const allConversationsToAdd = state.conversations.filter(
        (c) => c.id !== conversationToAdd.id
      );

      return {
        ...state,
        conversations: [conversationToAdd, ...allConversationsToAdd],
      };

    case CONVERSATIONS.CONVERSATION_UPDATE:
      const conversation = action.payload;
      const allConversations = state.conversations.filter(
        (c) => c.id !== conversation.id
      );

      // const index = state.conversations.findIndex(
      //   (c) => c.id === conversation.id
      // );
      // allConversations.splice(index, 1);

      return {
        ...state,
        conversations: [conversation, ...allConversations],
      };

    case CONVERSATIONS.CONVERSATION_MARKED_READ:
      const updatedConversationsMarkedRead = state.conversations.map(
        (conversation) => {
          if (conversation.id === action.payload.id) {
            return {
              ...action.payload,
            };
          }

          return conversation;
        }
      );

      return { ...state, conversations: updatedConversationsMarkedRead };

    case CONVERSATIONS.CONVERSATION_RESET:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}

export default conversations;
