import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError, handleS3ImageUploadResponse } from "./GenericResponseHandler";

class AuthenticationService {

    signUp(payload) {
        let url = UrlConstants.SIGN_UP;

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    getHearAboutUSOptions() {
        let url = UrlConstants.HEAR_ABOUT_US;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    forgotPassword(payload) {
        let url = UrlConstants.FORGOT_PASSWORD;

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    resetForgottenPassword(payload) {
        let url = UrlConstants.RESET_FORGOTTEN_PASSWORD;

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    verifyForgotCode(payload) {
        let url = UrlConstants.VERIFY_FORGOTTEN_CODE;

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }


    login(email, password) {
        let url = UrlConstants.LOGIN;

        const payload = {
            emailAddress: email,
            password,
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    firstLogin() {
        let url = UrlConstants.FIRST_LOGIN;

        return apiClient()
            .post(url, {})
            .then(handleResponse)
            .catch(handleError);
    }

    registerDeviceForNotification(playerId) {
        let url = UrlConstants.REGISTER_DEVICE_FOR_NOTIFICATIONS;

        const payload = {
            deviceIdentifier: "deviceIdentifier",
            notficationIdentifier: playerId,
            deviceModel: "deviceModel",
            deviceOS: "deviceSystemName",
            deviceVersion: "deviceSystemVersion"
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    unRegisterDeviceForNotification(playerId) {
        const url = UrlConstants.UNREGISTER_DEVICE_FOR_NOTIFICATIONS;

        const payload = {
            deviceIdentifier: "deviceIdentifier",
            notficationIdentifier: playerId,
            deviceModel: "deviceModel",
            deviceOS: "deviceSystemName",
            deviceVersion: "deviceSystemVersion"
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    confirmEmail(code) {
        let url = UrlConstants.CONFIRM_EMAIL;

        const payload = {
            confirmationCode: code,
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    resetPassword(cPassword, password, confPassword,) {
        let url = UrlConstants.RESET_PASSWORD;

        const payload = {
            currentPassword: cPassword,
            password: password,
            confirmPassword: confPassword,
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    getEmailFromInviteCode(code) {
        let url = UrlConstants.GET_EMAIL_FROM_INVITE_CODE;

        const payload = {
            confirmationCode: code,
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    resendConfirmationEmail(email) {
        let url = UrlConstants.RESEND_CONFIRMATION_EMAIL;

        const payload = {
            emailAddress: email,
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }


    getAllCountries() {
        let url = UrlConstants.GET_ALL_COUNTRIES;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getUser() {
        let url = UrlConstants.VIEW_USER;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    // getCountryByCode(countryCode) {
    //     let url = UrlConstants.GET_COUNTRY_BY_CODE.replace(/{countryCode}/, countryCode);

    //     return apiClient()
    //         .get(url)
    //         .then(handleResponse)
    //         .catch(handleError);
    // }

    getStatesOfCountry(countryCode) {
        let url = UrlConstants.GET_STATES_OF_COUNTRY.replace(/{countryCode}/, countryCode);

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    editUser(profile) {
        let url = UrlConstants.EDIT_USER;

        const payload = {
            "id": profile.id,
            "firstName": profile.firstName,
            "lastName": profile.lastName,
            "country": profile.country,
            "city": profile.city,
            "state": profile.countryState,
            "phoneNumber": profile.phoneNumber,
            "countryPhoneCode": profile.country.phonecode,
            "zipCode": profile.zipCode,
            "photo": Object.entries(profile.childImage).length !== 0 ? profile.childImage.file.name : profile.photo

        };


        return apiClient()
            .put(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    getCitiesOfState(countryCode, stateCode) {
        let url = UrlConstants.GET_CITIES_OF_STATE.replace(/{countryCode}/, countryCode).replace(/{stateCode}/, stateCode);

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getSubscriptionList() {
        let url = UrlConstants.GET_SUBSCRIPTION_LIST;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    createPaymentSession(subscriptionId, accountId) {
        let url = UrlConstants.CREATE_PAYMENT_SESSION;

        const payload = {
            "subscriptionId": subscriptionId,
            "accountId": accountId,
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    cancelSubscription() {
        let url = UrlConstants.CANCEL_SUBSCRIPTION;

        return apiClient()
            .put(url)
            .then(handleResponse)
            .catch(handleError);
    }

    updatePaymentCard() {
        let url = UrlConstants.UPDATE_PAYMENT_CARD;

        return apiClient()
            .put(url)
            .then(handleResponse)
            .catch(handleError);
    }

    resubscribePayment(planId) {
        let url = UrlConstants.RESUBSCRIBE_PAYMENT.replace(/{planId}/, planId);

        return apiClient()
            .put(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getPresignedURL(file) {
        let url = UrlConstants.GET_PRESIGNED_URL + "?key=user&op=upload&file=" + file;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getImageURL(fileName) {
        let url = UrlConstants.GET_PRESIGNED_URL + "?key=user&op=get&file=" + fileName;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }


    uploadImagePut(imageData, urlAws) {

        let url = urlAws
        var options = {
            headers: {
                'Content-Type': "image/*"
            }
        };

        return apiClient()
            .uploadImage(url, imageData, options)
            .then(handleS3ImageUploadResponse)
            .catch(handleError);
    }

    logout(data = {}) {
        let url = UrlConstants.LOGOUT;

        return apiClient()
            .post(url, data)
            .then(handleResponse)
            .catch(handleError);
    }

    getGenderList(payload) {
        let url = UrlConstants.USER_GENDER;

        return apiClient()
            .get(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

}

export default new AuthenticationService();