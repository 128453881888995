import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Container, Button } from "@material-ui/core";
import RouteConstants from "../../constants/RouteConstants";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ExternalUsersTable from "../../components/ExternalUsersTable/ExternalUsersTable";
import { getExternalUsers } from "../../store/actions/ExternalUser";
import SnackbarAlert from "../../components/SnackbarAlert/SnackbarAlert";

const ExternalUsersScreen = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const externalUsersState = useSelector((state) => state.externalUser);
    const [showAlert, setShowAlert] = useState(false);
    const [statusUpdateAlert, setStatusUpdateAlert] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);


    useEffect(() => {
        dispatch(getExternalUsers());
        if (location.state) {
            if (location.state.apiMsg !== "") {
                setShowAlert(true)
            }
        }
    }, []);

    const handleClose = () => {
        if (statusUpdateAlert) {
            setStatusUpdateAlert(false);
        } else {
            setDeleteSuccess(false);
        }
    }

    return (
        <div>
            {externalUsersState.loading && <Loader />}
            <SnackbarAlert show={statusUpdateAlert || deleteSuccess} message={statusUpdateAlert ? t("statusUpdateSuccessMessage") : t("exUserDeleteSuccessMessage")} duration={3000} onCloseAlert={handleClose} />

            <Grid container className="app">
                <Grid xs={12} item className="app-header">
                    <Header />
                </Grid>
                <Grid item xs={12} className="grid-body">
                    <Container maxWidth="xl">
                        <div>
                            {location.state && location.state.apiMsg ?
                                <SnackbarAlert show={showAlert} message={location.state.apiMsg} duration={3000} onCloseAlert={() => setShowAlert(false)} />
                                : null
                            }


                            <Container maxWidth="lg">
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <h1 className="title-has-icon mb-15"><i className="ticon-arrow-right back-arrow" onClick={() => history.replace({ pathname: RouteConstants.DASHBOARD })}></i>{t("externalUsers")}</h1 >
                                    </Grid>
                                    <Grid item xs={6} className="d-flex justify-content-end my-10">
                                        <Button
                                            variant="contained"
                                            className="btn-rounded"
                                            color="primary"
                                            size="large"
                                            onClick={() => {
                                                history.push("/addExUsers")
                                            }}
                                        // startIcon={<AddCircle />}
                                        >
                                            <i className="ticon-plus"></i>
                                            {t("addNewUser")}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {
                                    !externalUsersState.loading && <ExternalUsersTable usersData={externalUsersState.externalUsersList} setStatusUpdateAlert={(status) => setStatusUpdateAlert(status)} setDeleteSuccess={(status) => setDeleteSuccess(status)} />
                                }
                            </Container >
                        </div>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="xl">
                        <Grid container spacing={6} className="my-0">
                            <Grid item xs={12}>
                                <Footer />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
}

export default ExternalUsersScreen;