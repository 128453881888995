import React, { useState } from "react";
import PropTypes from "prop-types";

import { Box, Button, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import useToast from "../../../../hooks/useToast";

import { selectGroupById } from "../../../../helpers/GenericHelper";
import { useParams } from "react-router-dom";
import { addGroupRecipient } from "../../../../store/actions/Chat/Group";

const useStyles = makeStyles((theme) => ({
  createButton: {
    borderRadius: 10,
    backgroundColor: "#032C61",
    color: "#FFF",
    padding: "0.8rem 1.8rem",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
  },
  cancelButton: {
    borderRadius: "20px",
    backgroundColor: "#FFF",
    border: "1px solid #979797",
    color: "#9E9E9E",
    padding: "0.8rem 2rem",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "18px",
  },
  buttonsDiv: {
    columnGap: "4px",
    flexDirection: "row-reverse",
    display: "flex",
    marginTop: "20px",
  },
}));

export const GroupRecipientAddForm = ({ setShowModal }) => {
  const classes = useStyles();
  const { id: groupId } = useParams();

  const dispatch = useDispatch();
  const { success, error } = useToast();

  const [selectedUser, setSelectedUser] = useState(null);
  const group = useSelector((state) => selectGroupById(state, groupId));
  const groupUserIds = group?.users?.filter(user => Boolean(user)).map((users) => users.id);

  let recipients = useSelector((state) => state.recipients.recipients);
  recipients = recipients
    .filter((recipient) => !groupUserIds.includes(recipient.id))
    .map((recipient) => {
      return {
        label: `${recipient.firstName} ${recipient.lastName}`,
        user: recipient,
      };
    });

  const handleChange = (_, value) => {
    setSelectedUser(value);
  };

  const handleSubmit = async () => {
    const payload = {
      groupId,
      userId: selectedUser.user.id,
    };

    console.log({ payload });

    await dispatch(addGroupRecipient(payload, { success, error }));
  };

  return (
    <Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          padding: "4px",
          borderRadius: "8px",
          border: "1px solid #E0E0E0",
        }}
      >
        <Autocomplete
          id="combo-box-demo"
          options={recipients}
          getOptionLabel={(option) => option.label}
          style={{ width: "100%", outline: "none" }}
          onChange={handleChange}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <input
                style={{ width: "100%", border: "none", outline: "none" }}
                type="text"
                placeholder="Search recipient to add"
                {...params.inputProps}
              />
            </div>
          )}
        />
      </div>

      <div className={classes.buttonsDiv}>
        <Button
          variant="contained"
          className={classes.createButton}
          onClick={handleSubmit}
          disabled={!Boolean(selectedUser)}
        >
          Add
        </Button>
      </div>
    </Box>
  );
};

export default GroupRecipientAddForm;

GroupRecipientAddForm.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};
