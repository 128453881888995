import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AssetConstants from "../../constants/AssetConstants";

const styles = makeStyles((theme) => ({
  conversationChannelPageStyle: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "#FFF",
  },
  text: {
    textAlign: 'center'
  }
}));

export const SelectedConversationPage = () => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <Box className={classes.conversationChannelPageStyle}>
      <Box>
        <img src={AssetConstants.chatPlaceholder} alt="select conversation" />
        <Typography component="h1" variant="h5" className={classes.text}>
          {t("selectaconversation")}
        </Typography>
      </Box>
    </Box>
  );
};
