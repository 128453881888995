import React from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Link } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorIcon from '@material-ui/icons/Error';

const SnackbarAlert = (props) => {

    const { t } = useTranslation();
    const { show, message, duration, onCloseAlert, showUndo, onUndoPress, isError, onMessageClick } = props;

    return (
        <Snackbar open={show} autoHideDuration={duration > 0 ? duration : 3000} onClose={onCloseAlert} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
            <div className="actArcMessage error-message">
                {isError ? <span className="errorIcon"><ErrorIcon /></span> : <CheckCircleRoundedIcon />}
                {
                    onMessageClick ?
                        <Link onClick={onMessageClick}><span style={props.fontSize && { fontSize: props.fontSize }}>{message}</span></Link> :
                        <span style={props.fontSize && { fontSize: props.fontSize }}>{message}</span>
                }
                {showUndo && <span className="undo" onClick={onUndoPress}>{t("undo")}</span>}
                <CloseIcon className="closeIcon" onClick={onCloseAlert} />
            </div>
        </Snackbar>
    );

};

export default SnackbarAlert;