import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ToggleButton } from '@material-ui/lab';
import { Checkbox, Switch } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { getPermissionOfView, getPermissionOfEdit, calculatePermissionOfView } from '../../helpers/PermissionCalculator';
import React, { useEffect, useState } from 'react'
import { PRIV_CONSTS, USER_PRIVILEGES } from '../../constants/StaticData'



const AccessPriv = (props) => {


    const [view, setView] = useState(props.privileges.view);
    const [edit, setEdit] = useState(props.privileges.edit);

    const [privName, setPrivName] = useState(props.privileges.name);
    const [priv, setPriv] = useState(props.privileges);
    const [role, setRole] = useState(props.role);


    useEffect(() => {

        setView(getPermissionOfView(props.permissions, props.privileges));
        setEdit(getPermissionOfEdit(props.permissions, props.privileges));
        setPrivName(props.privileges.name)
        setRole(props.role)
        setPriv(props.privileges)

    }, [props])

    const viewSwitchChanged = (value, object) => {
        if (value == false && edit == true) {
            setEdit(value)
            props.editSwitchChanged(value, object);
            setView(value);
            props.viewSwitchChanged(value, object);

        } else {
            setView(value);
            props.viewSwitchChanged(value, object);
        }
    }

    const editSwitchChanged = (value, object) => {
        if (value == true && view == false) {
            setView(value)
            props.viewSwitchChanged(value, object);
            setEdit(value);
            props.editSwitchChanged(value, object);
        }
        else {
            setEdit(value);
            props.editSwitchChanged(value, object);
        }
    }

    const shouldDisableView = () => {
        //    return props.privileges.disabled || ((role.roleName === "TEACHER" || role.roleName === "THERAPIST") && privName === USER_PRIVILEGES[PRIV_CONSTS.CHILDREN_PRIV].name)
        return props.privileges.disabled || !getPermissionOfView(parseInt(role?.defaultPermissions), priv)
    }
    const shouldDisableEdit = () => {
        return props.privileges.disabled || !getPermissionOfEdit(parseInt(role?.defaultPermissions), priv)
    }
    return (

        // {rows.map((row) => (
        <TableRow key={props.privileges.id}>
            <TableCell scope="row">
                {props.privileges.name}
            </TableCell>
            <TableCell align="center">
                <Checkbox
                    checked={view}
                    defaultChecked
                    disabled={shouldDisableView()}
                    onChange={e => { viewSwitchChanged(e.target.checked, props.privileges) }}
                    color="primary" />
            </TableCell>
            <TableCell align="center">
                <Checkbox
                    checked={edit}
                    defaultChecked
                    disabled={shouldDisableEdit()}
                    onChange={e => { editSwitchChanged(e.target.checked, props.privileges) }}
                    color="primary" />
            </TableCell>
            {/* <TableCell align="right">{row.child_profile}</TableCell> */}
        </TableRow>
        // ))}

    );
}

export default AccessPriv
