import { ADD_EX_USER_ACTIONS, GET_EX_USER_LIST_ACTIONS, CONFIRM_EXTERNAL_USER_ACTIONS, EXTERNAL_USER_INVITE_CODE_ACTIONS, SIGN_OUT_ACTIONS, VIEW_EX_USER_ACTIONS, EDIT_EX_USER_ACTIONS, UPDATE_EXTERNAL_USER_STATUS_ACTIONS, DELETE_EXTERNAL_USER_ACTIONS, DELETE_LIST_EXTERNAL_USER_ACTIONS, GET_ROLE_LIST_ACTIONS } from "../../constants/ActionConstants";


let initialState = {
    loading: false,
    statusLoading: false,
    externalUsersList: [],
    roles: [],
    inviteCode: "",
};

const externalUser = (state = initialState, action) => {
    switch (action.type) {
        case ADD_EX_USER_ACTIONS.ADD_EX_USER:
            return {
                ...state,
                loading: true,
            };

        case ADD_EX_USER_ACTIONS.ADD_EX_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                externalUsersList: state.externalUsersList.concat(action.payload.data),
            };

        case ADD_EX_USER_ACTIONS.ADD_EX_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case EDIT_EX_USER_ACTIONS.EDIT_EX_USER:
            return {
                ...state,
                loading: true,
            };

        case EDIT_EX_USER_ACTIONS.EDIT_EX_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case EDIT_EX_USER_ACTIONS.EDIT_EX_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_EX_USER_LIST_ACTIONS.GET_EX_USER_LIST:
            return {
                ...state,
                loading: true,
            };

        case VIEW_EX_USER_ACTIONS.VIEW_EX_USER:
            return {
                ...state,
                loading: true,
            };

        case VIEW_EX_USER_ACTIONS.VIEW_EX_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case VIEW_EX_USER_ACTIONS.VIEW_EX_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_EX_USER_LIST_ACTIONS.GET_EX_USER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                externalUsersList: action.payload.data,
            };

        case GET_EX_USER_LIST_ACTIONS.GET_EX_USER_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                externalUsersList: [],
            };

        case CONFIRM_EXTERNAL_USER_ACTIONS.CONFIRM_EXTERNAL_USER:
            return {
                ...state,
                loading: true,
            };

        case CONFIRM_EXTERNAL_USER_ACTIONS.CONFIRM_EXTERNAL_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case CONFIRM_EXTERNAL_USER_ACTIONS.CONFIRM_EXTERNAL_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case EXTERNAL_USER_INVITE_CODE_ACTIONS.SAVE_INVITE_CODE:
            return {
                ...state,
                inviteCode: action.payload.inviteCode,
            };

        case EXTERNAL_USER_INVITE_CODE_ACTIONS.REMOVE_INVITE_CODE:
            return {
                ...state,
                inviteCode: "",
            };

        case SIGN_OUT_ACTIONS.SIGN_OUT_SUCCESS:
            return {
                ...state,
                loading: false,
                externalUsersList: [],
                inviteCode: "",
            };

        case UPDATE_EXTERNAL_USER_STATUS_ACTIONS.UPDATE_EXTERNAL_USER_STATUS:
            return {
                ...state,
                statusLoading: true,
            };

        case UPDATE_EXTERNAL_USER_STATUS_ACTIONS.UPDATE_EXTERNAL_USER_STATUS_DETAIL:
            return {
                ...state,
                loading: true,
            }

        case UPDATE_EXTERNAL_USER_STATUS_ACTIONS.UPDATE_EXTERNAL_USER_STATUS_SUCCESS:
            return {
                ...state,
                statusLoading: false,
                loading: false,
            };

        case UPDATE_EXTERNAL_USER_STATUS_ACTIONS.UPDATE_EXTERNAL_USER_STATUS_FAILURE:
            return {
                ...state,
                statusLoading: false,
                loading: false,
            };

        case DELETE_EXTERNAL_USER_ACTIONS.DELETE_EXTERNAL_USER:
            return {
                ...state,
                loading: true,
            };

        case DELETE_EXTERNAL_USER_ACTIONS.DELETE_EXTERNAL_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case DELETE_EXTERNAL_USER_ACTIONS.DELETE_EXTERNAL_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case DELETE_LIST_EXTERNAL_USER_ACTIONS.DELETE_LIST_EXTERNAL_USER:
            return {
                ...state,
                statusLoading: true,
            };

        case DELETE_LIST_EXTERNAL_USER_ACTIONS.DELETE_LIST_EXTERNAL_USER_SUCCESS:
            return {
                ...state,
                statusLoading: false,
            };

        case DELETE_LIST_EXTERNAL_USER_ACTIONS.DELETE_LIST_EXTERNAL_USER_FAILURE:
            return {
                ...state,
                statusLoading: false,
            };



        case GET_ROLE_LIST_ACTIONS.GET_ROLE_LIST:
            return {
                ...state,
                loading: true,
            };

        case GET_ROLE_LIST_ACTIONS.GET_ROLE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload.data,
            };

        case GET_ROLE_LIST_ACTIONS.GET_ROLE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                roles: [],
            };


        default:
            return state;
    }
};

export default externalUser;
