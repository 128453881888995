import React, { useState, useEffect, useRef } from "react";
import { SCHEDULE_TYPE, CALENDAR_VIEW } from "../../constants/GenericConstants";
import momentTz from 'moment-timezone';
import { Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const DayHeaderBar = ({ selectedView, selectedDate, onSelectDay }) => {
    // selected Date = moment object

    const { t } = useTranslation();

    const daysArray = [
        { label: t("sunday"), value: 0 },
        { label: t("monday"), value: 1 },
        { label: t("tuesday"), value: 2 },
        { label: t("wednesday"), value: 3 },
        { label: t("thursday"), value: 4 },
        { label: t("friday"), value: 5 },
        { label: t("saturday"), value: 6 }
    ];
    
    const selectWeekDay = (dayObj, index) => {
        if(selectedView == CALENDAR_VIEW.WEEK)
            onSelectDay(dayObj.date);
    }

    const getWeeksArray = () => {
        let startDayOfWeek = momentTz(selectedDate).startOf('week');
        let weekArray = daysArray.map((item, index) => {
            return {...item, date: momentTz(startDayOfWeek).add(index, 'days') }
        });
        return weekArray;
    }

    const getMonthsArray = () => {
        let startDayOfMonth = momentTz(selectedDate).startOf('month');
        let endDayOfMonth = momentTz(selectedDate).endOf('month');
        let monthArray = [];
        for(let dateIndex = 0; dateIndex < endDayOfMonth.date(); dateIndex++) {
            let theDate = momentTz(startDayOfMonth).add(dateIndex, 'days');
            monthArray.push({
                ...daysArray[theDate.day()],
                date: theDate
            });
        }
        return monthArray;
    }

    const getDaysArray = () => {
        switch(selectedView) {
            case CALENDAR_VIEW.DAY:
                return [{
                    ...daysArray[selectedDate.day()],
                    date: momentTz(selectedDate)
                }]
            case CALENDAR_VIEW.WEEK:
                console.log(getWeeksArray());
                return getWeeksArray();
            case CALENDAR_VIEW.MONTH: 
                return getMonthsArray();
            default:
                return [{
                    ...daysArray[selectedDate.day()],
                    date: momentTz(selectedDate)
                }];
        }
    }
    
    const renderDaysRow = () => {
        const array = getDaysArray();
        if (selectedView == CALENDAR_VIEW.WEEK) {
            return array.map((day, index) => {
                let isToday = momentTz().isSame(day.date, "day");
                return <Link onClick={() => selectWeekDay(day, index)}>
                    {
                        isToday ?
                            <div>
                                <span style={{ color: '#02c8a7' }}>{day.date.date()}</span>
                                <span style={{ color: '#02c8a7' }}>{day.label}</span>
                            </div> :
                            <div>
                                <span>{day.date.date()}</span>
                                <span>{day.label}</span>
                            </div>
                    }
                </Link>
            });
        } else if (selectedView == CALENDAR_VIEW.MONTH) {
            return (
                <div>
                    <span style={{ color: '#02c8a7' }}>
                        {array[0].date.format('MMMM')}
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    <span style={{ color: '#02c8a7' }}>{array[0].date.date()}</span>
                    <span style={{ color: '#02c8a7' }}>{array[0].label}</span>
                </div>
            )
        } 
    }

    return <>
        {/* {selectedDate.format()} */}
        {renderDaysRow()}
    </>
    
}

export default DayHeaderBar;