import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { InputLabel, Input, FormHelperText, FormControlLabel, Checkbox, Button, Slider, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, MenuItem, Select } from "@material-ui/core";
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Cropper from 'react-easy-crop';
import { validateMandatoryValue, validateLength100C } from "../../helpers/Validation";
import { getCroppedImg } from '../../helpers/ImageCrop';
import { getPresignedURL, uploadImage, addImageInImageLibrary, showLoader } from "../../store/actions/ImageLibrary";

import imageCompression from 'browser-image-compression';
import Loader from "../Loader/Loader";
import { getTimeInMS } from "../../helpers/GenericHelper";

import { getDropdownSkintones } from "../../helpers/GenericHelper";

const AddNewImage = (props) => {

    const { t } = useTranslation();
    const imageLibraryState = useSelector((state) => state.imageLibrary);
    const childState = useSelector((state) => state.child);
    const dispatch = useDispatch();
    const { addedImage, closeDialog, onSave } = props;
    const [imageTitle, setImageTitle] = useState(addedImage.file.name);
    const [imageTitleError, setImageTitleError] = useState("");
    
    const [categories, setCategories] = useState(imageLibraryState.imageCategories.map((category) => {
        return {
            ...category,
            selected: false,
        };
    }));
    const [categoriesError, setCategoriesError] = useState("");
    
    const [dialogOpen, setDialogOpen] = useState(false);

    const [sliderValue, setSliderValue] = useState(1.5);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1.5);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [skinToneId, setSkinToneId] = useState("");

    let croppedImageFile;
    var compressedFileTemp = {};


    const [compressing, setCompressing] = useState(false);


    useEffect(() => {
        validateImageTitle(addedImage.file.name);
    }, []);

    const handleSliderValueChange = (event, newValue) => {
        setSliderValue(newValue);
        setZoom(newValue);
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }

    const handleImageTitleChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setImageTitle(input);
        validateImageTitle(input);
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const checkChanged = () => {
        let changed = false
        // if (imageTitle !== addedImage.file.name || categories.filter(i => i.selected === true).length !== 3) {
        //     changed = true
        // }
        return true;
    }

    const validateImageTitle = (imageTitle) => {
        if (!validateMandatoryValue(imageTitle)) {
            setImageTitleError(t("imageTitleRequired"));
            return false;
        } else if (!validateLength100C(imageTitle)) {
            setImageTitleError(t("imageTitleLengthError"));
            return false;
        } else {
            setImageTitleError("");
            return true;
        }
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    const handleCheckboxChange = (event, index) => {
        const input = event.target && event.target.checked ? event.target.checked : false;
        categories[index].selected = input;
        setCategories([...categories]);
        validateCategories(categories);
    }

    const validateCategories = (categories) => {
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].selected) {
                setCategoriesError("");
                return true;
            }
        }
        setCategoriesError(t("categoryRequiredError"));
        return false;
    }

    const getCategoriesCheckboxes = () => {
        return categories.map((category, index) => {
            return (
                <FormControlLabel
                    key={index}
                    control={<Checkbox checked={category.selected} onChange={(event) => handleCheckboxChange(event, index)} name={category.name} />}
                    label={category.value}
                />
            );
        });
    }

    const onAddImageSuccess = (data) => {
        onSave(data);
    }

    const onAddImageFailure = (error) => {
        console.log(error);
    }

    const onGetUrl = (res) => {
        dispatch(uploadImage(compressedFileTemp, res.data.url,
            (res) => {
                const selectedCategories = categories.filter((category) => category.selected);
                const categoryNames = selectedCategories.map((category) => category.name);
                const data = {
                    imagePath: res,
                    imageTitle: imageTitle,
                    categories: categoryNames,
                    accountId: childState.selectedChild.accountId,
                    childId: childState.selectedChild.childId
                    // skinToneId: skinToneId
                };
                dispatch(addImageInImageLibrary(data, onAddImageSuccess, onAddImageFailure));
            },
            (err) => {
                console.log("Error", err);
            }
        ))
    }

    const onFailedUrl = (error) => {
        console.log(error);
    }

    const onSubmit = () => {
        if (validateImageTitle(imageTitle) && validateCategories(categories)) {
            dispatch(showLoader());
            getCroppedImg(addedImage.image.src, croppedAreaPixels, addedImage.file.type, 0, afterCrop);
        }
    }

    const afterCrop = (blob) => {
        croppedImageFile = new File([blob], addedImage.file.name, { type: blob.type });
        compressImage(croppedImageFile)
    }


    const compressImage = (croppedImageFile) => {
        // setCompressing(true)
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 200,
            useWebWorker: true
        }
        if (croppedImageFile) {
            imageCompression(croppedImageFile, options)
                .then(function (compressedFile) {

                    // setCompressing(false)
                    compressedFileTemp = compressedFile;
                    compressedFileTemp.name = getTimeInMS(compressedFileTemp.name);
                    dispatch(getPresignedURL(compressedFile.name, onGetUrl, onFailedUrl))


                })
                .catch(function (error) {

                });
        }
    }

    const getSkintones = () => {
        return getDropdownSkintones(imageLibraryState.imageSkintones).map((skintone, index) => {
            return <MenuItem key={index} value={skintone.skinTone}>{skintone.skinToneLabel}</MenuItem>;
        });
    }

    const handleCurrentSkintoneChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        if(input){
            setSkinToneId(input);
        }
    }

    return (
        <div className="c-AddPopupBox">

            {compressing && <Loader />}


            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("informationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={closeDialog} color="primary">{t("leavePage")}</Button>
                    <Button variant="contained" onClick={handleCloseDialog} color="primary">{t("cancel")}</Button>
                </DialogActions>
            </Dialog>



            <Grid container spacing={3}>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="imgCropContainer">
                        <Cropper
                            image={addedImage.image.src}
                            crop={crop}
                            zoom={zoom}
                            showGrid={false}
                            onCropChange={(value) => {
                                setCrop(value)
                            }}
                            onCropComplete={onCropComplete}
                            aspect={1}

                        />
                    </div>
                    <div className="slider">
                        <div className="c-slider">
                            <ImageOutlinedIcon />
                            <Slider value={sliderValue} onChange={handleSliderValueChange} min={1} max={3} step={0.1} />
                            <ImageOutlinedIcon />
                        </div>
                    </div>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="PopupRightSide">
                        <InputLabel htmlFor="imageTitle" required>{t("imageTitle")}</InputLabel>
                        <Input id="imageTitle" name="imageTitle" required type="text" value={imageTitle || ""} placeholder={t("exampleToothbrushes")} onChange={handleImageTitleChange} error={showInputError(imageTitleError)} />
                        {
                            showInputError(imageTitleError) && <FormHelperText error={showInputError(imageTitleError)}>{imageTitleError}</FormHelperText>
                        }
                        <div className="mt-30">
                            <InputLabel required>{t("assignToCategories")}</InputLabel>
                        </div>
                        <div className="CategoriesCheckboxes">
                            {getCategoriesCheckboxes()}
                        </div>
                        {
                            showInputError(categoriesError) && <FormHelperText error={showInputError(categoriesError)}>{categoriesError}</FormHelperText>
                        }
                        {/* <div className="mt-30">
                            <InputLabel>{"Select Skintone"}</InputLabel>
                        </div>
                        <Select id="skintoneSelector" className="ImageLib-Select" name="skintoneSelector" value={skintone === "" ? "none" : skintone} onChange={handleCurrentSkintoneChange}>
                            {getSkintones()}
                        </Select> */}

                    </div>
                </Grid>

            </Grid>

            <footer className="AddPopup-Footer">
                <div className="buttons-footer">
                    <Button variant="contained" onClick={() => {
                        if (checkChanged()) {
                            setDialogOpen(true)
                        }
                        else {
                            closeDialog()
                        }

                    }}>{t("cancel")}</Button>
                    <Button variant="contained" onClick={onSubmit} color="primary">{t("save")}</Button>
                </div>
            </footer>

        </div>
    );
}

export default AddNewImage;