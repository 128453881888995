import { ACTIVITIES_SELECT_IMAGE_ACTIONS, ADD_ACTIVITY_ACTIONS, SAVE_ACTIVITY_ACTIONS, GET_ACTIVITY_LIST_ACTIONS, GET_COLOR_LIST_ACTIONS, GET_ACTIVITY_ACTIONS, UPDATE_ACTIVITY_ACTIONS, ARCHIVE_ACTIVITY_ACTIONS } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    activityImage: {},
    subTaskImage: {},
    tempActivityData: {},
    activityList: [],
    activityListMeta: {},
    currentActivity: {},
    editActivityImage: {},
    editSubTaskImage: {},
    tempEditActivityData: {},
    listingCurrentPageData: {},
    activityColorList: [],
    activityColorLoading: false
};

const activities = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVITIES_SELECT_IMAGE_ACTIONS.SELECT_ACTIVITY_IMAGE:
            return {
                ...state,
                activityImage: action.payload.image,
            };

        case ACTIVITIES_SELECT_IMAGE_ACTIONS.SELECT_SUBTASK_IMAGE:
            return {
                ...state,
                subTaskImage: action.payload.image,
            };

        case ACTIVITIES_SELECT_IMAGE_ACTIONS.CLEAR_SUBTASK_IMAGE:
            return {
                ...state,
                subTaskImage: {},
            };

        case ADD_ACTIVITY_ACTIONS.ADD_ACTIVITY:
            return {
                ...state,
                loading: true,
            };

        case ADD_ACTIVITY_ACTIONS.ADD_ACTIVITY_SUCCESS:
            return {
                ...state,
                // loading: false,
            };

        case ADD_ACTIVITY_ACTIONS.ADD_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case SAVE_ACTIVITY_ACTIONS.SAVE_ACTIVITY:
            return {
                ...state,
                tempActivityData: action.payload.data,
            };

        case SAVE_ACTIVITY_ACTIONS.CLEAR_ACTIVITY:
            return {
                ...state,
                activityImage: {},
                subTaskImage: {},
                tempActivityData: {},
            };

        case GET_ACTIVITY_LIST_ACTIONS.GET_ACTIVITY_LIST:
            return {
                ...state,
                loading: true,
            };

        case GET_ACTIVITY_LIST_ACTIONS.GET_ACTIVITY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                activityList: action.payload.data.data,
                activityListMeta: action.payload.data.meta,
            };

        case GET_ACTIVITY_LIST_ACTIONS.GET_ACTIVITY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_ACTIVITY_ACTIONS.GET_ACTIVITY:
            return {
                ...state,
                loading: true,
                currentActivity: {},
                editActivityImage: {},
            };

        case GET_ACTIVITY_ACTIONS.GET_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                currentActivity: action.payload.data,
                editActivityImage: action.payload.data.image,
            };

        case GET_ACTIVITY_ACTIONS.GET_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case ACTIVITIES_SELECT_IMAGE_ACTIONS.SELECT_EDIT_SUBTASK_IMAGE: {
            return {
                ...state,
                editSubTaskImage: action.payload.image,
            };
        }

        case ACTIVITIES_SELECT_IMAGE_ACTIONS.CLEAR_EDIT_SUBTASK_IMAGE: {
            return {
                ...state,
                editSubTaskImage: {},
            };
        }

        case SAVE_ACTIVITY_ACTIONS.SAVE_EDIT_ACTIVITY:
            return {
                ...state,
                tempEditActivityData: action.payload.data,
            };

        case ACTIVITIES_SELECT_IMAGE_ACTIONS.SELECT_EDIT_ACTIVITY_IMAGE:
            return {
                ...state,
                editActivityImage: action.payload.image,
            };

        case UPDATE_ACTIVITY_ACTIONS.UPDATE_ACTIVITY:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_ACTIVITY_ACTIONS.UPDATE_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_ACTIVITY_ACTIONS.UPDATE_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case SAVE_ACTIVITY_ACTIONS.CLEAR_EDIT_ACTIVITY:
            return {
                ...state,
                currentActivity: {},
                editActivityImage: {},
                editSubTaskImage: {},
                tempEditActivityData: {},
            };

        case ARCHIVE_ACTIVITY_ACTIONS.ARCHIVE_ACTIVITY:
            return {
                ...state,
                loading: true,
            };

        case ARCHIVE_ACTIVITY_ACTIONS.ARCHIVE_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case ARCHIVE_ACTIVITY_ACTIONS.ARCHIVE_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_ACTIVITY_LIST_ACTIONS.SAVE_CURRENT_PAGE:
            return {
                ...state,
                listingCurrentPageData: action.payload.data,
            };

        case GET_ACTIVITY_LIST_ACTIONS.CLEAR_CURRENT_PAGE:
            return {
                ...state,
                listingCurrentPageData: {},
            };

        case GET_COLOR_LIST_ACTIONS.GET_COLOR_LIST:
            return {
                ...state,
                loading: true,
            };

        case GET_COLOR_LIST_ACTIONS.GET_COLOR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                activityColorList: action.payload.data,
            };

        case GET_COLOR_LIST_ACTIONS.GET_COLOR_LIST_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};

export default activities;
