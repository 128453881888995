import { RECIPIENT } from "../../../constants/ActionConstants";

const INITIAL_STATE = {
  recipients: [],
  isLoading: false,
  error: null,
};

const recipientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECIPIENT.FETCH_RECIPIENT_REQUEST:
      return { ...state, isLoading: true, error: null };

    case RECIPIENT.FETCH_RECIPIENT_SUCCESS:
      return { ...state, isLoading: false, recipients: action.payload.data };

    case RECIPIENT.FETCH_RECIPIENT_FAILURE:
      return { ...state, isLoading: false, error: action.payload.data };
    default:
      return state;
  }
};

export default recipientReducer;
