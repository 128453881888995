import { format, formatDistance, isToday } from "date-fns";

class DateHelper {
  formatDate = (date, formatString) => {
    return format(date, formatString);
  };

  getDateObject = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const date = dateString.substring(8);

    return new Date(year, month - 1, date);
  };

  getDate = (dateString, timeString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const date = dateString.substring(8);
    const hour = timeString.substring(0, 2);
    const minutes = timeString.substring(3, 5);
    const seconds = timeString.substring(6);

    return new Date(year, month - 1, date, hour, minutes, seconds);
  };

  formatDateForNotifications(date) {
    const givenDate = new Date(date);
    let result = isToday(givenDate);
    if (result) {
      return formatDistance(givenDate, new Date(), { addSuffix: true })
    } else {
      return this.formatDate(givenDate, 'MMM dd, hh:mm aaa');
    }
  }

}

export default new DateHelper();
