import { failure, request, success } from "..";
import { GET_ALL_GROUPS_ACTIONS } from "../../../constants/ActionConstants";
import RouteConstants from "../../../constants/RouteConstants";
import ChatService from "../../../services/api/ChatService";

export const fetchAllGroups = (onSuccess) => {
  return async (dispatch) => {
    dispatch(request(GET_ALL_GROUPS_ACTIONS.GET_ALL_GROUPS, {}));
    try {
      const response = await ChatService.fetchGroups();
      dispatch(
        success(GET_ALL_GROUPS_ACTIONS.GET_ALL_GROUPS_SUCCESS, {
          data: response.data,
        })
      );
      if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error.response);
      dispatch(failure(GET_ALL_GROUPS_ACTIONS.GET_ALL_GROUPS_FAILURE, {}));
    }
  };
};

export const fetchGroupMessages = (groupId, page = 1, searchTerm) => {
  return async (dispatch) => {
    dispatch(request(GET_ALL_GROUPS_ACTIONS.FETCH_GROUP_MESSAGES, {}));
    try {
      const response = await ChatService.fetchGroupMessages(
        groupId,
        page,
        searchTerm
      );

      dispatch(
        success(GET_ALL_GROUPS_ACTIONS.FETCH_GROUP_MESSAGES_SUCCESS, {
          data: response.data,
        })
      );
    } catch (error) {
      console.log(error.response);
      dispatch(
        failure(GET_ALL_GROUPS_ACTIONS.FETCH_GROUP_MESSAGES_FAILURE, error?.response || 'something went wrong')
      );
    }
  };
};

export const createNewGroup = (payload, history, toasts) => {
  return async (dispatch) => {
    dispatch(request(GET_ALL_GROUPS_ACTIONS.GROUP_CREATE, {}));

    try {
      const newGroup = await ChatService.createGroup(payload);
      await dispatch(
        success(GET_ALL_GROUPS_ACTIONS.GROUP_CREATE_SUCCESS, {
          data: newGroup.data,
        })
      );

      history.replace({ pathname: `/group/${newGroup.data.id}` });
      return newGroup.data;
    } catch (error) {
      console.log(error.response);
      toasts.error(
        error?.response?.data?.message || "Unable to create the group"
      );
      await dispatch(
        success(GET_ALL_GROUPS_ACTIONS.GROUP_CREATE_FAILURE, error?.response)
      );
    }
  };
};

export const deleteGroup = (groupId, history) => {
  return async (dispatch) => {
    dispatch(request(GET_ALL_GROUPS_ACTIONS.GROUP_DELETE, {}));

    try {
      const deletedGroup = await ChatService.deleteGroup(groupId);

      await dispatch(
        success(GET_ALL_GROUPS_ACTIONS.GROUP_DELETE_SUCCESS, {
          data: deletedGroup.data,
        })
      );
      history.replace({ pathname: RouteConstants.CHAT });
      return deletedGroup.data;
    } catch (error) {
      console.log(error.response);
      alert(error?.response?.data?.message || "Unable to delete the group");
      await dispatch(
        success(GET_ALL_GROUPS_ACTIONS.GROUP_DELETE_FAILURE, error?.response)
      );
    }
  };
};

export const addGroupRecipient = (payload, toasts) => {
  const { groupId, userId } = payload;

  return async (dispatch) => {
    dispatch(request(GET_ALL_GROUPS_ACTIONS.ADD_USER_IN_GROUP, {}));
    try {
      const userAddedInGroup = await ChatService.addGroupRecipient({
        groupId,
        userId,
      });

      dispatch(success(GET_ALL_GROUPS_ACTIONS.ADD_USER_IN_GROUP_SUCCESS), {
        data: userAddedInGroup.data,
      });

      toasts.success("User added in group");
    } catch (error) {
      toasts.error("Cannot Add User");
      console.log({ error });
      dispatch(failure(GET_ALL_GROUPS_ACTIONS.ADD_USER_IN_GROUP_FAILURE), {});
    }
  };
};

export const removeGroupRecipient = (groupId, userId) => {
  return async (dispatch) => {
    dispatch(request(GET_ALL_GROUPS_ACTIONS.REMOVE_USER_IN_GROUP, {}));
    try {
      const userRemovedInGroup = await ChatService.removeGroupRecipient({
        groupId,
        userId,
      });

      dispatch(success(GET_ALL_GROUPS_ACTIONS.REMOVE_USER_IN_GROUP_SUCCESS), {
        data: userRemovedInGroup.data,
      });
    } catch (error) {
      console.log({ error });
      dispatch(
        failure(GET_ALL_GROUPS_ACTIONS.REMOVE_USER_IN_GROUP_FAILURE),
        {}
      );
    }
  };
};

export const groupMarkedAsRead = (groupId) => {
  return async () => {
    try {
      await ChatService.groupMarkedAsRead(groupId);
    } catch (error) {
      console.log(error?.response);
    }
  };
};

export const addGroup = (payload) => {
  return request(GET_ALL_GROUPS_ACTIONS.GROUP_ADD, payload);
};

export const updateGroup = (payload) => {
  return request(GET_ALL_GROUPS_ACTIONS.GROUP_UPDATE, payload);
};

export const removeGroup = (payload) => {
  return request(GET_ALL_GROUPS_ACTIONS.GROUP_REMOVE, payload);
};

export const markedAsReadGroup = (payload) => {
  return request(GET_ALL_GROUPS_ACTIONS.GROUP_MARKED_READ, payload);
};

export const resetGroup = () => {
  return request(GET_ALL_GROUPS_ACTIONS.GROUP_RESET, {});
};

export const filteredGroups = (state) => {
  const {
    groups: { groups },
    filters: { search },
  } = state;

  return groups.filter((group) =>
    group?.title?.toLowerCase().includes(search?.toLowerCase())
  );
};
