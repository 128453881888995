import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Container, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { viewExternalUser, editExternalUser, deleteExternalUser, updateExternalUserStatus, getExternalRoles } from "../../store/actions/ExternalUser";
import ViewExternalUser from "../../components/ViewExternalUser/ViewExternalUser";
import { validateMandatoryValue, validateAtLeastOne, validateLength45C } from "../../helpers/Validation";
import { EXTERNAL_USER_STATUS } from "../../constants/GenericConstants";
import SnackbarAlert from "../../components/SnackbarAlert/SnackbarAlert";
import { Mixpanel } from "../../lib/mixpanleEvents";
import { MIX_PANEL_EVENTS } from "../../constants/MixPanelConstants";

const ViewExternalUserScreen = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const userState = useSelector((state) => state.authentication);
    const externalUserStore = useSelector((state) => state.externalUser);


    const [exUserData, setExUserData] = useState({})

    var permLocal = useRef(exUserData.userPermission);

    const setPermission = (val) => {
        permLocal.current = val
    }

    const [formData, setFormData] = useState(
        {
            alias: "",
            children: [],
            permissions: 0,
            status: false,
            role: ""
        }
    )

    const [formDataErrors, setFormDataErrors] = useState(
        {
            aliasError: "",
            childrenError: "",
            permissionsError: "",
            roleError: ""
        }
    );


    const [apiMsg, setApiMsg] = useState(
        {
            type: "",
            msg: ""
        }
    )
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [statusChangeSuccess, setStatusChangeSuccess] = useState(false);


    const isFormValid = () => {
        let localAliasError = "";
        let localChildrenError = "";
        let localPermissionsError = "";
        let localRoleError = "";
        let valid = true;

        if (!validateMandatoryValue(formData.alias)) {
            localAliasError = t("requiredField");
            valid = false;
        } else if (!validateLength45C(formData.alias)) {
            localAliasError = t("onlyNameLength");
            valid = false;
        }


        if (!validateAtLeastOne(formData.children)) {
            localChildrenError = t("atLeastOneErrorChild");
            valid = false;
        }

        if (!validateMandatoryValue(formData.role && formData.role.roleName ? formData.role.roleName : formData?.role)) {
            localRoleError = t("atLeastOneErrorRole");
            valid = false;
        }


        setFormDataErrors({
            ...formDataErrors,
            aliasError: localAliasError,
            childrenError: localChildrenError,
            permissionsError: localPermissionsError,
            roleError: localRoleError

        });
        return valid;
    }

    const onSubmit = () => {
        setFormSubmitted(true)

        if (isFormValid()) {

            setApiMsg({ type: "", msg: "" })

            const editedExUser = { ...formData, role: formData.role && formData.role.roleName ? formData.role.roleName : formData?.role, permissions: permLocal.current, emailAddress: exUserData.userEmailAddress, recipientId: exUserData.accountId };
            dispatch(editExternalUser(editedExUser, onEditSuccess, onEditFailure))
        }
    }

    const onEditSuccess = (res) => {
        //mixpanel
        const accountId = decodeURIComponent(props.match.params.accountId);
        Mixpanel.track(MIX_PANEL_EVENTS.EXTERNAL_USER_UPDATED, {
            accountId,
        });

        history.replace({
            pathname: "/externalUsers",
            state: { apiMsg: "External User Updated Successfully!" }
        })
    }

    const onEditFailure = (err) => {
        var err1 = err.data && err.data.message ? err.data.message : err.message
        setApiMsg({ type: "error", msg: "Operation Failed! " + err1 })
    }

    useEffect(() => {
        const accountId = decodeURIComponent(props.match.params.accountId);
        if (accountId) {
            dispatch(viewExternalUser(accountId, onSuccess, onFailure))
        }
        return () => {

        }
    }, [])

    const checkDataChanged = () => {
        if (formData.alias !== exUserData.userAlias || formData.role.roleName !== exUserData.userRole || formData.status !== exUserData.associationStatus) {
            return true;
        } else {
            return false;
        }
    }

    const onSuccess = (res) => {
        permLocal.current = parseInt(res.data.userPermission)
        setExUserData(res.data);

        dispatch(getExternalRoles((roleRes) => {
            setFormData({
                ...formData,
                status: res.data.associationStatus,
                children: res.data.childIds,
                alias: res.data.userAlias, permissions: Number(res.data.userPermission), role: roleRes.filter(r => r.roleName === res.data.userRole)[0]
            });
        }));

    }
    const onFailure = (err) => {
        var err1 = err.data && err.data.message ? err.data.message : err.message


    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    }

    const handleCloseDeactivateDialog = () => {
        setDeactivateDialogOpen(false);
    }

    const handleClose = () => {
        setDeleteSuccess(false);
    }

    const handleStatusChangeClose = () => {
        setStatusChangeSuccess(false);
    }

    const onDeleteSuccess = () => {
        //mixpanel
        const accountId = decodeURIComponent(props.match.params.accountId);
        Mixpanel.track(MIX_PANEL_EVENTS.EXTERNAL_USER_DELETED, {
            accountId
        })

        setDeleteSuccess(true);
        setTimeout(() => {
            history.goBack();
        }, 2000);
    }

    const deleteUser = () => {
        const accountId = decodeURIComponent(props.match.params.accountId);
        dispatch(deleteExternalUser(accountId, onDeleteSuccess));
        handleCloseDeleteDialog();
    }

    const onStatusFetchSuccess = (res) => {
        onSuccess(res);
        setStatusChangeSuccess(true);
    }

    const onStatusChangeSuccess = () => {
        const accountId = decodeURIComponent(props.match.params.accountId);
        if (accountId) {
            dispatch(viewExternalUser(accountId, onStatusFetchSuccess, onFailure))
        }
    }

    const changeUserStatus = () => {
        const status = exUserData.associationStatus ? EXTERNAL_USER_STATUS.IN_ACTIVE : EXTERNAL_USER_STATUS.ACTIVE;
        setDeactivateDialogOpen(false);
        dispatch(updateExternalUserStatus(userState.user.id, exUserData.accountId, status, onStatusChangeSuccess, true));
    }

    return (
        <div>

            {externalUserStore.loading && <Loader />}

            <SnackbarAlert show={deleteSuccess} message={t("exUserDeleteSuccessMessage")} duration={3000} onCloseAlert={handleClose} />
            <SnackbarAlert show={statusChangeSuccess} message={exUserData.associationStatus ? t("accountActivatedSuccessMessage") : t("accountDeactivatedSuccessMessage")} duration={3000} onCloseAlert={handleStatusChangeClose} />

            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("updatedInformationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => { history.goBack() }} color="primary">{t("leavePage")}</Button>
                    <Button variant="contained" onClick={handleCloseDialog} color="primary">{t("cancel")}</Button>

                </DialogActions>
            </Dialog>


            <Dialog maxWidth="xs" open={deleteDialogOpen} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title">
                <CloseIcon className="popup-close" onClick={handleCloseDeleteDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseDeleteDialog}>{t("no")}</Button>
                    <Button variant="contained" onClick={deleteUser} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="xs" open={deactivateDialogOpen} onClose={handleCloseDeactivateDialog} aria-labelledby="alert-dialog-title">
                <CloseIcon className="popup-close" onClick={handleCloseDeactivateDialog} />
                <DialogTitle id="alert-dialog-title">{t("changeExternalUserStatusDialogMessage")}</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseDeactivateDialog} >{t("no")}</Button>
                    <Button variant="contained" onClick={changeUserStatus} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>



            <Grid container className="app">
                <Grid xs={12} item className="app-header">
                    <Header />
                </Grid>
                <Grid item xs={12} className="grid-body">
                    <Container maxWidth="xl">
                        <div className="new-external-user">
                            <Container maxWidth="lg">
                                <Grid container alignItems="center">
                                    <Grid item xs={12}>
                                        <div className="d-flex justify-content-between mb-15 align-items-center">
                                            <h1 className="title-has-icon mb-15">
                                                <i
                                                    onClick={() => {
                                                        if (checkDataChanged()) {
                                                            setDialogOpen(true);
                                                        }
                                                        else {
                                                            history.goBack()
                                                        }
                                                    }}
                                                    className="ticon-arrow-right back-arrow"></i>{t("editExternalUsers")}
                                            </h1 >
                                            {
                                                location.allowStatusChange && Object.keys(exUserData).length > 0 &&
                                                <Button className="outlineBtn" onClick={() => setDeactivateDialogOpen(true)}>{exUserData.associationStatus ? t("deactivateAccount") : t("activateAccount")}</Button>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>

                                <ViewExternalUser
                                    exUserDataGot={exUserData}
                                    formDataErrors={formDataErrors}
                                    setFormDataErrors={setFormDataErrors}
                                    formData={formData}
                                    setFormData={setFormData}
                                    formSubmitted={formSubmitted}
                                    allowStatusChange={location.allowStatusChange}
                                    setPerm={setPermission}

                                />


                                {apiMsg.msg !== "" ?
                                    <Alert severity={apiMsg.type}>
                                        {apiMsg.msg}
                                    </Alert>
                                    : null
                                }

                                <div className="buttons-footer">
                                    <div className="btn-delete d-flex align-items-center">
                                        <Link onClick={() => { setDeleteDialogOpen(true) }}>{t("deleteThisProfile")}</Link>
                                    </div>
                                    <div className="btns-right">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="large"
                                            onClick={() => {
                                                if (checkDataChanged()) {
                                                    setDialogOpen(true);
                                                }
                                                else {
                                                    history.goBack()
                                                }
                                            }}
                                        >
                                            Cancel
                                          </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={() => {
                                                onSubmit()
                                            }}
                                        >
                                            Edit User
                                          </Button>
                                    </div>
                                </div>
                            </Container >

                        </div>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="xl">
                        <Grid container spacing={6} className="my-0">
                            <Grid item xs={12}>
                                <Footer />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
}

export default ViewExternalUserScreen;