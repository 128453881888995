import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Grid,
  InputLabel,
  Input,
  FormHelperText,
  Button,
  Link,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  validateEmail,
  validateMandatoryValue,
} from "../../helpers/Validation";
import AssetConstants from "../../constants/AssetConstants";
import {
  LOCAL_STORAGE_KEYS,
  USER_TYPE,
  TEST_CHILD_OBJECT,
} from "../../constants/GenericConstants";
import { login, saveEmailAddress } from "../../store/actions/Authentication";
import {
  getChildrenList,
  setCurrentChildForSingleUser,
} from "../../store/actions/Child";
import { Alert } from "@material-ui/lab";
import RouteConstants from "../../constants/RouteConstants";
import Loader from "../Loader/Loader";
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";
import OnboardingCarousel from "../SignUp/OnboardingCarousel";
import { SocketContext } from "../../context/SocketContext";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import { getTimeDifference, parseValue } from "../../helpers/GenericHelper";
import { Mixpanel } from "../../lib/mixpanleEvents";
import { MIX_PANEL_EVENTS } from "../../constants/MixPanelConstants";

const Login = (props) => {
  const startTime = useRef(new Date().getTime());
  const { chat } = useFeatureFlags();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.authentication);
  const childState = useSelector((state) => state.child);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPaymentError, setShowPaymentError] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const { setToken } = useContext(SocketContext);

  useEffect(() => {
    document.title = "Thrive App - Login";
    if (location.state) {
      if (location.state.resetMsg !== "") {
        setShowAlert(true);
      }
    }
    localStorage.removeItem(LOCAL_STORAGE_KEYS.IS_UNSUBSCRIBED_USER);
  }, []);

  const handleUsernameChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setUsername(input);
    if (formSubmitted) {
      validateUsername(input);
    }
  };

  const handlePasswordChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setPassword(input);
    if (formSubmitted) {
      validatePassword(input);
    }
  };

  const validateUsername = (username) => {
    if (!validateMandatoryValue(username)) {
      setUsernameError(t("usernameRequiredError"));
      return false;
    } else if (!validateEmail(username)) {
      setUsernameError(t("invalidEmailError"));
      return false;
    } else {
      setUsernameError("");
      return true;
    }
  };

  const validatePassword = (password) => {
    if (!validateMandatoryValue(password)) {
      setPasswordError(t("passwordRequiredError"));
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const showInputError = (field) => {
    return field.length > 0;
  };

  const onLoginSuccess = (isSingleUser = false, user) => {
    // if(isSingleUser) {
    //     dispatch(getChildrenList(false, "", onSingleLoginSuccess));
    // } else {
    //     history.replace({ pathname: RouteConstants.DASHBOARD });
    // }

    const ACCESS_TOKEN = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    setToken(ACCESS_TOKEN);

    try {
      // TODO: check this for erorrs
      //mixpanel
      const endTime = new Date().getTime();
      const duration = getTimeDifference(startTime.current, endTime);

      Mixpanel.identify(user.id);
      Mixpanel.track(MIX_PANEL_EVENTS.LOGIN, {
        [MIX_PANEL_EVENTS.LOGIN_TIME_SPENT]: duration,
        [MIX_PANEL_EVENTS.USER_TYPE]: user.userType,
      });
      Mixpanel.user.set({
        $first_name: user.firstName,
        $last_name: user.lastName,
        $email: user.emailAddress,
        $created: new Date().toISOString(),
      });
    } catch (e) {
      console.log(e);
    }

    history.replace({ pathname: RouteConstants.DASHBOARD });
  };

  const onSingleLoginSuccess = (data) => {
    console.log("onSingleLoginSuccess");
    console.log(childState);
    console.log(data);
    let singleUserChildObject = data.parentData[0];
    // dispatch(setCurrentChildForSingleUser(TEST_CHILD_OBJECT));
    dispatch(setCurrentChildForSingleUser(singleUserChildObject));
    history.replace({
      pathname: RouteConstants.CHILD_ACTIVITIES.replace(
        ":childId",
        singleUserChildObject.childId
      ),
    });
  };

  const onLoginFailure = (error) => {
    if (error?.statusCode === 5) {
      dispatch(saveEmailAddress(username.toLowerCase()));
      history.push({
        pathname: RouteConstants.VERIFY_EMAIL,
        alreadyCreatedUser: true,
      });
    } else {
      setPassword("");
      setApiError(error.message);
    }
  };

  const onPaymentFailure = (accessToken) => {
    setShowPaymentError(true);
    setAccessToken(accessToken);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setApiError("");
    const isFormValid = validateUsername(username) & validatePassword(password);
    if (isFormValid) {
      dispatch(
        login(
          username,
          password,
          onLoginSuccess,
          onLoginFailure,
          onPaymentFailure
        )
      );
    }
  };

  const moveToSignUpScreen = () => {
    history.push({ pathname: RouteConstants.SIGN_UP_USER_TYPE });
  };

  const moveToForgotPasswordScreen = () => {
    history.push({ pathname: RouteConstants.FORFOT_PASSWORD });
    // history.push({ pathname: RouteConstants.RESET_FORGOTTEN_PASSWORD });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const moveToPaymentScreen = () => {
    history.push({ pathname: RouteConstants.PAYMENT });
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
  };

  return (
    <div className="is-login">
      {userState.loading && <Loader />}

      <Grid container>
        <Grid item xs={12} sm={6} className="login-form">
          {location.state && location.state.resetMsg ? (
            <SnackbarAlert
              show={showAlert}
              message={location.state.resetMsg}
              duration={3000}
              onCloseAlert={() => setShowAlert(false)}
            />
          ) : null}

          <SnackbarAlert
            show={showPaymentError}
            message={t("paymentNotProcessedErrorMessage")}
            duration={30000}
            onCloseAlert={() => setShowPaymentError(false)}
            onMessageClick={moveToPaymentScreen}
          />

          <div className="login-form-content">
            <img className="login-logo" src={AssetConstants.logo} />
            <form>
              <h1>{t("loginHeading")}</h1>
              {apiError && <Alert severity="error">{apiError}</Alert>}
              <div className="form-field">
                <InputLabel htmlFor="username">{t("email")}</InputLabel>
                <Input
                  id="username"
                  name="username"
                  required
                  type="email"
                  value={username || ""}
                  placeholder={t("emailPlaceholder")}
                  onChange={handleUsernameChange}
                  autoComplete="email"
                  error={showInputError(usernameError)}
                />
                {showInputError(usernameError) && (
                  <FormHelperText error={showInputError(usernameError)}>
                    {usernameError}
                  </FormHelperText>
                )}
              </div>
              <div className="form-field">
                <InputLabel htmlFor="password">{t("password")}</InputLabel>
                <Input
                  id="password"
                  name="password"
                  required
                  type={showPassword ? "text" : "password"}
                  value={password || ""}
                  placeholder={t("passwordPlaceholder")}
                  onChange={handlePasswordChange}
                  error={showInputError(passwordError)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {showInputError(passwordError) && (
                  <FormHelperText error={showInputError(passwordError)}>
                    {passwordError}
                  </FormHelperText>
                )}
              </div>
              <div className="form-field login-button">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={onSubmit}
                >
                  {t("login")}
                </Button>
              </div>
              <div className="link-signup">
                <span>{t("signUpLink")} </span>
                <Link onClick={moveToSignUpScreen}>{t("signUp")}</Link>
              </div>
              <div className="link-forgot-pass">
                <Link onClick={moveToForgotPasswordScreen}>
                  {t("forgotPasswordLink")}
                </Link>
              </div>
            </form>
          </div>
        </Grid>
        <OnboardingCarousel />
      </Grid>
    </div>
  );
};

export default Login;
