import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Container,
  Link,
  Avatar,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Badge,
  makeStyles,
} from "@material-ui/core";
import { Link as RRDLink } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { logout } from "../../store/actions/Authentication";
import RouteConstants from "../../constants/RouteConstants";
import { isEmpty } from "../../helpers/Validation";
import AssetConstants from "../../constants/AssetConstants";
import Notifications from "../Notifications/Notifications";
import { markAllRead } from "../../store/actions/Notifications";
import {
  getConversationUnread,
  getGroupUnread,
  hasValidPayment,
} from "../../helpers/GenericHelper";
import { SocketContext } from "../../context/SocketContext";

import useFeatureFlags from "../../hooks/useFeatureFlags";
import {
  fetchConversations,
  filteredConversations,
} from "../../store/actions/Chat/Conversations";
import { fetchAllGroups, filteredGroups } from "../../store/actions/Chat/Group";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  badgeAnchor: {
    right: 6,
    top: 8,
  },
  dot: {
    height: 10,
    minWidth: 10,
    borderRadius: 5,
  },
  colorSecondary: {
    backgroundColor: "#e54644",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const { chat, show_academic_advisor } = useFeatureFlags();

  const isChatEnabled = chat?.enabled;
  const isAcademicAdvisorEnabled = show_academic_advisor?.enabled;

  const { socket, setSocket, setToken } = useContext(SocketContext);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.authentication);
  const [loaded, setLoaded] = useState(false);
  const notificationState = useSelector((state) => state.notifications);

  const conversations = useSelector(filteredConversations);
  const groups = useSelector(filteredGroups);

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [isExternalUser, setIsExternalUser] = useState(false);
  const [isPaymentValid, setIsPaymentValid] = useState(true);

  useEffect(() => {
    if (userState.isParent) {
      setIsPaymentValid(hasValidPayment(userState.user));
    }
  }, []);

  useEffect(() => {
    if (userState.isParent) {
      dispatch(fetchConversations());
      dispatch(fetchAllGroups());
    }
  }, [dispatch, userState.isParent]);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onLogoutSuccess = () => {
    const OneSignal = window.OneSignal;

    // OneSignal.push(["setSubscription", false]);
    // OneSignal.push(function() {
    //     OneSignal.removeExternalUserId();
    //   });

    history.replace({ pathname: RouteConstants.LOGIN });

    /** Disconnect socket when user logged out */
    if (socket) {
      socket.disconnect();
      setSocket(null);
      setToken(null);
    }
  };

  const onLogoutFailure = () => {};

  const onLogout = () => {
    dispatch(logout(onLogoutSuccess, onLogoutFailure));
  };

  const openDialog = () => {
    setAnchorEl(null);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const goToExternalUsersScreen = () => {
    history.push({ pathname: RouteConstants.EXTERNAL_USERS });
  };

  const goToChatScreen = () => {
    history.push({ pathname: RouteConstants.CHAT });
  };

  const goToResetPasswordScreen = () => {
    history.push({ pathname: RouteConstants.RESET_PASSWORD });
  };
  const goToAccountSettingsScreen = () => {
    history.push({
      pathname: RouteConstants.ACCOUNT_SETTINGS.replace(
        ":userId",
        userState.user.id
      ),
    });
  };

  const goToHome = () => {
    history.push({ pathname: RouteConstants.DASHBOARD });
  };

  const goToChat = () => {
    history.push({ pathname: RouteConstants.CHAT });
  };

  const openNotificationMenu = (event) => {
    setTimeout(() => {
      dispatch(markAllRead());
    }, 2000);
    setNotifAnchorEl(event.currentTarget);
  };

  const closeNotificationMenu = () => {
    dispatch(markAllRead());

    setNotifAnchorEl(null);
  };

  const renderAddExternalMenu = () => {
    if (userState && userState.isParent && isPaymentValid) {
      if (userState.isSingleUser && !isAcademicAdvisorEnabled) {
        return null;
      }
      return (
        <MenuItem onClick={goToExternalUsersScreen}>
          <i className="ticon-user"></i> {t("externalUsers")}
        </MenuItem>
      );
    }
  };

  const unReadChatConversation = conversations.some(
    (conversation) =>
      getConversationUnread(conversation, userState?.user?.id)?.unreadCount > 0
  );
  const unReadGroupConversation = groups.some(
    (group) => getGroupUnread(group, userState?.user?.id)?.unreadCount > 0
  );

  const chatNotification = unReadChatConversation || unReadGroupConversation;

  return (
    <Container maxWidth="xl">
      <Dialog
        className="logout-box"
        maxWidth="xs"
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseIcon className="popup-close ddd" onClick={handleCloseDialog} />
        <DialogTitle id="alert-dialog-title">
          {t("logoutConfirmHeading")}
        </DialogTitle>
        <DialogActions>
          <Button
            className="no-button-text"
            variant="contained"
            onClick={handleCloseDialog}
          >
            {t("no")}
          </Button>
          <Button variant="contained" onClick={onLogout} color="primary">
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2} className="my-0">
        <Grid item xs={4}>
          <Link onClick={goToHome} href={`/#/${RouteConstants.DASHBOARD}`}>
            <img
              className="header-logo"
              src={AssetConstants.logo}
              alt="Thrive Logo"
            />
          </Link>
        </Grid>
        <Grid item xs={8} className="d-flex justify-content-end">
          <div className="header-actions">
            <RRDLink onClick={goToHome}>
              <span className="homeIcon">
                <img src={AssetConstants.headerhomeicon} alt="Home Icon" />
              </span>
            </RRDLink>
            {isChatEnabled ? (
              <RRDLink onClick={goToChat}>
                <span className="homeIcon chatIcon">
                  <Badge
                    color="secondary"
                    variant="dot"
                    invisible={!chatNotification}
                    classes={{
                      anchorOriginTopRightRectangle: classes.badgeAnchor,
                      dot: classes.dot,
                      colorSecondary: classes.colorSecondary,
                    }}
                  >
                    <img src={AssetConstants.chatIcon} alt="Chat Icon" />
                  </Badge>
                </span>
              </RRDLink>
            ) : null}
            <Link className="app-notification" onClick={openNotificationMenu}>
              <span className="bellIcon">
                <i className="ticon-bell">
                  {notificationState &&
                  notificationState.notificationList &&
                  notificationState.notificationList.filter(
                    (item) => item.isRead === false
                  ).length > 0 ? (
                    <em>
                      {
                        notificationState.notificationList.filter(
                          (item) => item.isRead === false
                        ).length
                      }
                    </em>
                  ) : null}
                </i>
              </span>
            </Link>
            <Menu
              id="notifications-menu"
              anchorEl={notifAnchorEl}
              keepMounted
              open={Boolean(notifAnchorEl)}
              onClose={closeNotificationMenu}
            >
              {Boolean(notifAnchorEl) && <Notifications />}
            </Menu>
            <div className="user-profile-header">
              <Avatar
                alt="User Profile Picture"
                src={
                  !isEmpty(userState.user) &&
                  userState.user.photo &&
                  userState.user.photo.includes(".")
                    ? userState.user.photoURL
                    : AssetConstants.profilePlaceholder
                }
                onLoad={() => {
                  setLoaded(true);
                }}
              />
              <span>
                {userState.user?.photoURL !== "" && !loaded ? (
                  <p className="loader"></p>
                ) : null}
              </span>
              <div>
                <h5>{!isEmpty(userState.user) && userState.user.firstName}</h5>
                <div
                  className="profile-menu"
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                  onClick={openMenu}
                >
                  {t("myProfile")} <i className="ticon-chevron-down"></i>
                </div>
              </div>
            </div>

            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenu}
            >
              <MenuItem onClick={goToAccountSettingsScreen}>
                <i className="ticon-settings"></i> {t("accountSetting")}
              </MenuItem>
              {renderAddExternalMenu()}
              <MenuItem onClick={openDialog}>
                <i className="ticon-logout"></i> {t("logout")}
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
