import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import { MessageItemAttachmentContainer } from "../attachments/MessageItemAttachmentContainer";

const useStyles = makeStyles(() => ({
  messageItemContent: {
    whiteSpace: "pre-wrap",
    alignItems: "flex-end",
    display: "flex",
    maxWidth: 600,
    minWidth: 100,
    borderRadius: "12px 0px 12px 12px",
    padding: "10px 16px",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
  },
  wrapper: {
    position: "relative",
    backgroundColor: "#EBEBEB",
    borderRadius: " 0px 12px 12px 12px",
    maxWidth: "60%",
    minWidth: 100,
    padding: "10px 16px",
    marginBottom: 10,
    color: "#333333",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
    fontSize: 14,
  },
  message: {
    // font-family: 'Open Sans';
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "22px",
  },

  authorName: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "22px",
    color: "#333333",
  },
  left: {
    position: "absolute",
    top: 0,
    left: -11,
    borderRight: "20px solid #EBEBEB",
    borderBottom: "20px solid transparent",
  },
}));

export const MessageLeft = ({ message, isAttachmentType, padding }) => {
  const classes = useStyles();

  const userName = message?.user
    ? `${message?.user?.firstName} ${message?.user?.lastName}`
    : "";

  return (
    <Box
      className={classes.wrapper}
      style={
        {
          // maxWidth: isAttachmentType ? "200px" : "60%",
        }
      }
    >
      <Typography variant="subtitle1" className={classes.authorName}>
        {userName}
      </Typography>

      <>
        <p className={classes.message}>
          <MessageItemAttachmentContainer message={message} />
          <span>{message.content || null}</span>
        </p>
      </>

      <span className={classes.left}></span>
    </Box>
  );

  //   return (
  //     <Box
  //       className={classes.messageItemContent}
  //       style={{
  //         backgroundColor: "#EBEBEB",
  //         color: "#333333",
  //         // marginRight: isAuthor ? 10 : 0,
  //       }}
  //     >
  //       <div>
  //         {message.attachments?.length === 0 ? (
  //           <span>{message.content || null}</span>
  //         ) : null}
  //         <MessageItemAttachmentContainer message={message} />
  //       </div>
  //     </Box>
  //   );
};
