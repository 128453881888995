import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

const TermsConditions = () => {

    const { t } = useTranslation();

    const divRref = useRef(null);
    const divRref2 = useRef(null);

    const scrollToPrivacy = (e) => {
        e.preventDefault()
        divRref.current.scrollIntoView({ behavior: 'smooth' });
    }
    const scrollToTerms = (e) => {
        e.preventDefault()
        divRref2.current.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className="c-AddPopupBox">

            <div>

                <Grid container spacing={3}>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div ref={divRref2}>
                            <h3>1. Terms of Use</h3>
                            </div>
                        <p>Last Updated August 12, 2021</p>
                        <p>Thriving on the Spectrum, LLC (<strong>“Company”</strong>, <strong>“we”</strong>, <strong>“us”</strong>, or <strong>“our”</strong>) are proud to
                            offer <a href="https://www.thrivingonthespectrum.org" target="_blank">www.thrivingonthespectrum.org</a> and related websites and subdomains, including the
                            Thrive Parent’s App (our “Website”), as well as the Thrive App (together, our “Services”).
                            Your use of our Website and Services is governed by these Terms of Use (“Terms”).
                            Please read these Terms carefully, as they constitute a legally binding agreement
                            between you and us. By using or accessing our Website, you are agreeing to be bound
                            by these Terms and by our <a href="" onClick={scrollToPrivacy}> Privacy Notice</a>, which is expressly incorporated in
                            these Terms. If you do not agree to these Terms, you are not authorized to use or access
                            our Website or Services.</p>
                        <h2>Permission to Access our Website and Services</h2>
                        <p>Provided you abide by these Terms, we grant you permission to access the publicly
                            available portion of our Website for any authorized purpose.</p>
                        <p>In order to access our Services, including the Parent’s App and the Thrive App, you must
                            create an account and provide a valid form of payment (credit/debit card or online
                            payment mechanism). We explained in our <a href="" onClick={scrollToPrivacy}>Privacy Notice</a>, this transaction is
                            how we obtain verified consent under the Children’s Online Privacy Protection Act.
                            Please not that we do not collect or store your payment information. All payment
                            processing is performed by Stripe, a secure third-party online service.
                        </p>


                        <h2>Our Intellectual Property Rights</h2>
                        <p>Our Website and our Services, including all of their content, features, and functionality
                            are owned and operated by the Company or its licensors. The contents of our Website
                            and Services are protected by the copyright, trademark, and other intellectual property
                            laws of the United States. We reserve all intellectual property rights to our Website and
                            our Services, including rights in any registered or unregistered trademark, service mark,
                            logo, or other proprietary material appearing on our Website.</p>
                        <h2>External Links</h2>
                        <p>Our Website may provide links to other websites that are owned and operated by third
                            parties. We have no control over the content of such websites and we make no warranty
                            or representation as to the accuracy, timeliness and/or completeness of the information
                            contained on them. The availability of links to third-party websites does not imply that we
                            sponsor, endorse, or are affiliated with or associated with such third parties.</p>

                        <h2>Prohibited Activities</h2>
                        <p>By accessing our Website and/or the Services and accepting these Terms, you agree
                            that you will not:</p>
                        <ul>
                            <li><p>Use our Website, our Services, or any of their contents for any business or
                                commercial purpose, except as specifically authorized by us.</p></li>
                            <li><p>Violate, circumvent, or infringe our rights or the rights of any other person or entity,
                                including without limitation any intellectual property, contract, privacy, or publicity
                                rights; </p></li>
                            <li><p>“Frame,” “mirror,” or otherwise replicate or incorporate any portion or aspect of our
                                Website, our name, logo, trademarks, or proprietary information, into any other
                                Website without our express written consent; </p></li>
                            <li><p> Damage, disable, or overburden our servers or network, impair or impede the
                                operation of our Website, or interfere with someone else’s access to our Website
                                or our Services.</p></li>
                            <li><p>Post or transmit any unlawful, threatening, libelous, defamatory, obscene,
                                scandalous, inflammatory, pornographic, or profane material or any material that
                                could constitute or encourage conduct that would be considered a criminal offense,
                                give rise to civil liability, or otherwise violate any applicable law, ordinance,
                                regulation, or court order. </p></li>
                            <li><p>Modify, adapt, decipher, translate, decompile, disassemble, edit or reverse
                                engineer any of the software used by us in connection with our Website or our
                                Services, or attempt to do so;</p></li>
                            <li><p>Solicit, encourage, or assist any person in doing, or attempting to do, any of the
                                foregoing actions.</p></li>
                        </ul>

                        <h2>Availability</h2>
                        <p>We will make reasonable efforts to ensure that our Website and our Services are available
                            at all times. However, from time to time our Website, our Services, or both may be
                            unavailable due to scheduled maintenance, service upgrades, or unscheduled service
                            disruptions.</p>

                        <h2>Disclaimer of Warranties</h2>
                        <p>To the maximum extent permitted by law, our Website and Services are provided “AS IS”
                            without warranty of any kind, whether expressed or implied. All implied warranties,

                            including implied warranties of merchantability, fitness for a particular purpose, and non-
                            infringement, are hereby expressly disclaimed. </p>

                        <p>We cannot and do not guarantee or warrant our Website and Services will be free of
                            viruses or other destructive code. You are responsible for implementing sufficient
                            procedures and safeguards to satisfy your particular requirements for anti-virus protection
                            and accuracy of data input and output, and for maintaining a means external to our

                            Website and Services for reconstruction of any lost data. </p>


                        <h2>Limitation of Liability</h2>
                        <p>Use of our Website and our Services is at your own risk. To the maximum extent permitted
                            by law, we will not be liable for any loss or damage, under any legal theory and in any
                            form, arising out of or in connection with your use of, or inability to use, our Website.</p>
                        <h2>Indemnification</h2>
                        <p>You agree to defend, indemnify and hold harmless us and our affiliates, licensors, and
                            service providers, including our and their respective officers, directors, employees,
                            contractors, agents, licensors, suppliers, successors and assigns, from and against any
                            claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees
                            (including reasonable attorneys’ fees) arising out of or relating to your violation of these
                            Terms or your use of the Website and/or our Services. </p>
                        <h2>Governing Law and Jurisdiction</h2>
                        <p>All matters arising from or relating to our Website, our Services, or these Terms, including
                            any dispute or claim, shall be governed by the law of the state of North Carolina without
                            giving effect to any choice or conflict of law provision or rule.</p>
                        <p>Jurisdiction for any court proceedings arising out of or relating to our Website or these
                            Terms shall be vested exclusively in, and venue shall be laid in, the state or federal courts
                            sitting in Durham, North Carolina. You waive any and all objections to the exercise of
                            jurisdiction over you by such courts and to venue in such courts.</p>
                        <h2>Non-Waiver; Severability</h2>
                        <p>Any failure by us to enforce or assert our rights under any provision in these Terms shall
                            not constitute a waiver or our right to enforce or assert rights under that provision or any
                            other provision of these Terms.</p>
                        <p>If any provision of these Terms is held invalid or unenforceable by a court or other tribunal
                            of competent jurisdiction, such provision shall be eliminated or limited to the minimum
                            extent such that the remaining provisions of these Terms will continue in full force and
                            effect.</p>
                        <h2>Effective date and modifications</h2>
                        <p>These Terms are effective as of the “last updated” date, above. We reserve the right to
                            modify these Terms at our discretion and without notice by posting the changes here. We
                            encourage you to visit this page periodically in order to remain informed of any changes.
                            Changes to these Terms will be effective as of the date they are posted to this page.</p>




                        <br></br>
                        <br></br>



                        {/* Privacy Notice  */}

                        <div ref={divRref}><h3 id="privacy">2. Privacy Notice</h3></div>
                        <p>Last Updated August 12, 2021</p>

                        <p>Thank you for choosing to be part of our community at Thriving on the Spectrum, LLC
                            (<strong>“Company”</strong>, <strong>“we”</strong>, <strong>“us”</strong>, or <strong>“our”</strong>). We are committed to protecting your personal
                            information and your right to privacy. If you have any questions or concerns about our
                            notice, or our practices with regards to your personal information, please contact us at
                            tracey@thrivingonthespectrum.org.</p>
                        <p>
                            When you visit <a href="https://www.thrivingonthespectrum.org" target="_blank">www.thrivingonthespectrum.org</a> and related websites and subdomains,
                            including the Thrive Parent’s App (together, our “Website”), you trust us with your
                            personal information. The purpose of this Privacy Notice is to explain what information
                            we collect, how we use it, and what rights you have. Please read this Privacy Notice
                            carefully as it will help you make informed decisions about sharing your personal
                            information with us. This Privacy Notice is incorporated into
                            our <a href="" onClick={scrollToTerms}>Terms of Use</a>, which govern your access and use of our Website and our Services. By
                            using our Website, you are agreeing to the terms of this Privacy Notice. If there are any
                            terms in this Privacy Notice you disagree with, please discontinue use of our Website and
                            our services.
                        </p>
                        <p>
                            This Privacy Notice applies to all information collected through our Website (such as
                            www.thrivingonthespectrum.org), and/or any related services, including the Parent’s App
                            and the Thrive App (together with our Website, the “Services”). It does not apply to the
                            privacy practices of any third-party whose website may be accessible through our
                            Website, or to any third-party websites on which we advertise.</p>


                        <h2>WHAT INFORMATION DO WE COLLECT?</h2>
                        <ul >
                            <li><p>
                                <h2>Information You Provide to Us</h2>
                                <p>We collect information you provide directly to us, such as when you fill out a form,
                                    contact us via email or online, or create an account on the Parent’s App. We may
                                    use information you provide to us for any of the following purposes:</p>
                            </p></li>
                            <li><p>
                                <h2>Information We Collect Automatically</h2>
                                <p>You can browse our Website without telling us who you are or revealing your
                                    personally identifying information. But like most companies, we automatically
                                    collect certain information from all visitors to our Website. This information does
                                    not reveal your specific identity (like your name or contact information) but may
                                    include your IP address or other unique device identifier; your web browser and
                                    operating system; the date and time of your visit; the pages from which you
                                    navigate to and from our Website or the search terms that lead you to our Website;

                                    and the pages on our Website you visit and how long you stay on each page.</p>
                            </p></li>
                            <li><p>
                                <h2>Information Collected by Third Parties</h2>
                                <p>As you navigate our Website, please be aware that third parties may be able to
                                    gather information from and about you. For example, companies offering services
                                    or products through links on our Website may use “cookies” to collect and use
                                    information about you and may collect information that you submit. In these cases,
                                    those third parties will use such information in accordance with their own privacy
                                    policies.</p>
                            </p></li>
                            <li><p>
                                <h2>Google Analytics</h2>
                                <p>We use Google Analytics to collect information about use of our website. Google  Analytics collects information such as how often users visit this site, what pages  they visit when they do so, and what other sites they used prior to coming to this  site. We use the information we get from Google Analytics only to improve this site.  Google Analytics collects only the IP address assigned to you on the date you visit  this site, rather than your name or other identifying information. We do not combine  the information collected using Google Analytics with personally identifiable  information. Although Google Analytics plants a permanent cookie on your web  browser to identify you as a unique user the next time you visit this site, the cookie  cannot be used by anyone but Google. Google’s ability to use and share  information collected by Google Analytics about your visits to this site
                                    is restricted  by the Google Analytics <a href="https://marketingplatform.google.com/about/analytics/terms/us/" target="_blank">Terms of Service</a> and Google <a href="https://policies.google.com/privacy?hl=en-US" target="_blank">Privacy Policy.</a></p>
                                <p>More information about Google Analytics is available <a href="https://support.google.com/analytics/answer/6004245?hl=en" target="_blank">here</a>. You can opt out of  Google Analytics by downloading an add-on to your browser <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">here</a>. </p>
                            </p></li>
                        </ul>



                        <h2>HOW DO WE USE YOUR INFORMATION?</h2>
                        <p>We use information you provide us or that we collect automatically for various business
                            or commercial purposes, including the following:</p>
                        <ul>
                            <li><p>To administer, operate, and improve our Website and our Services;</p></li>
                            <li><p>To respond to an inquiry or to fulfill a request by you, such as when you sign up
                                for our newsletter;</p></li>
                            <li><p>To process a transaction, such as when you create an account on the Parent’s
                                App;</p></li>
                            <li><p>To send periodic emails providing you with information you request or that we think
                                may be of interest to you, or to seek your feedback. You may opt out of such
                                communications at any time by following the instructions in each communication;</p></li>
                            <li><p>To enable trusted third-party service providers to help us provide, improve, protect,
                                or promote our Website and our Services;</p></li>
                            <li><p>To prevent fraud and to ensure the safety and security of our Website;</p></li>
                            <li><p>To comply with a legal obligation, such as responding to a subpoena or a court
                                order, or to protect our rights.</p></li>
                        </ul>


                        <h2>COOKIES AND TRACKING TECHNOLOGIES</h2>
                        <ul>
                            <li><p>
                                <h2>COOKIES</h2>
                                Like virtually all websites, we use “cookies” and similar technologies (such as web
                                beacons and pixels). A cookie is a small data file that is placed on your computer
                                when you visit a website, enabling the website provider to recognize your browser
                                and capture and remember certain information. We use cookies to improve your
                                access to our Website and your experience of our Services. Cookies also help us
                                identify the interests of visitors to our Website so we can provide more customized
                                content. Cookies can also be used to collect general usage and aggregated
                                statistical information.
                                Most browsers can be set to detect cookies and give you an opportunity to reject
                                them. You should be aware that refusing cookies may inhibit or preclude your use
                                of our Website. You can learn more about cookies <a href="https://www.allaboutcookies.org/verify" target="_blank">here</a>.
                                <br></br>
                            </p></li>
                            <li style={{ marginTop: 30 }}>
                                <h2>“DO NOT TRACK”</h2>
                                <p>
                                    “Do Not Track” (DNT) is a setting available in most web browsers that asks a web
                                    application to disable its tracking of an individual user. There is currently no
                                    consensus on how companies should respond to DNT signals. At this time, we do
                                    not respond to DNT signals. You can learn more about DNT <a href="https://allaboutdnt.com/" target="_blank">here</a>.
                                </p>
                            </li>
                        </ul>

                        <h2>HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                        <p>We have implemented reasonable and appropriate technical and organizational security
                            measures designed to protect personal information you provide through our Website. For
                            example, our Website employs HTTPS (Hypertext Transfer Protocol Secure), an internet
                            communication protocol that protects the integrity and confidentiality of data exchanged
                            between your device and our Website. However, please also remember that we cannot
                            guarantee that our Website or the internet is 100% secure and that transmission of
                            personal information to and from our Website or our Services is at your own risk. You
                            should only access the services within a secure environment.</p>



                        <h2>CHILDREN’S PRIVACY</h2>
                        <p>This part of our Privacy Notice applies only to children under the age of 13 (“children”)
                            and supplements the other provisions of this Privacy Notice. The Children’s Online
                            Privacy Protection Act of 1998 and its rules (collectively, “COPPA”) require us to inform
                            parents and legal guardians about our practices for collecting, using, and disclosing
                            personal information from children.
                        </p>
                        <p>
                            COPPA only applies to information collected from children. COPPA does not apply to
                            information about your child that you provide when you create an account and register
                            your child on the Parent’s App.</p>

                        <h2 style={{ marginLeft: 50 }}>INFORMATION WE COLLECT FROM CHILDREN </h2>
                        <p style={{ marginLeft: 50 }}>
                            Children can access our Website without providing any personal information.  However, in order for your child to use the Thrive App we have to collect and make  use of certain information that is considered “personal information” under COPPA,  including:
                            <ul>
                                <li><p><u>Verified Consent.</u> Under COPPA, we are required to obtain verified consent  from a child’s parent or legal guardian prior to collecting personal information  from a child. We obtain this verification when you register for an account and  use a credit/debit card or online payment mechanism to purchase a  subscription.</p></li>
                                <li><p> <u>Collection Limitation.</u> We only collect as much information about a child as is  reasonably necessary for the child to participate in an activity, and we do not  condition his or her participation on the disclosure of more personal information  than is reasonably necessary.</p></li>
                                <li><p> <u>Device Identifiers/Push Notifications.</u> A child must provide a parent’s email  address that matches the email on your Parent’s App account before we will  use device identifiers to send push notifications to your child.</p></li>
                                <li><p> <u>Geolocation.</u> We use persistent identifiers to identify a device’s location  (geolocation).</p></li>
                            </ul>
                        </p>
                        <h2 style={{ marginLeft: 50 }}>HOW WE USE INFORMATION COLLECTED FROM CHILDREN</h2>
                        <p style={{ marginLeft: 50 }}>

                            We use the personal information we collect from your child to:
                            <ul>
                                <li><p>Connect the Thrive App to the account you create on the Parent’s App;</p></li>
                                <li><p>Provide the features you select, such as a visual schedule, self-regulation,  location tracking/boundaries, and the communications portal;</p></li>
                                <li><p>Track performance, including operation of the Rewards Bank;</p></li>
                                <li><p>To make the Thrive App more useful by storing information about your child’s  preferences and past activity;</p></li>
                            </ul>
                        </p>

                        <h2 style={{ marginLeft: 50 }}>DISCLOSURE OF INFORMATION COLLECTED FROM CHILDREN </h2>
                        <p style={{ marginLeft: 50 }}>
                            We do not share, sell, rent, or transfer children’s personal information other than  as described in this section.
                            <ul>
                                <li><p> We may disclose aggregated information about many of our users as described  in the other provisions of this Privacy Notice. In addition, we may disclose  children’s personal information:</p></li>
                                <li><p> To trusted third parties we use to support the internal operations of our Website,  our Services, and the Thrive App. Such third parties are bound by contractual  or other obligations to use the information only for purposes we authorize, and  they must keep the information confidential</p></li>
                                <li><p> If we are required to do so by law or legal process, such as to comply with any  court order or subpoena or to respond to any government or regulatory request;</p></li>
                                <li><p> If we believe disclosure is necessary or appropriate to protect the rights,  property, or safety of Thriving on the Spectrum, LLC, our customers, or others,  including to:</p></li>
                                <ul>
                                    <li><p> protect the safety of a child;</p></li>
                                    <li><p> protect the safety and security of the Thrive App;</p></li>
                                    <li><p> enable us to take precautions against liability;</p></li>
                                </ul>
                                <li><p> To law enforcement agencies or for an investigation related to public safety.</p></li>
                            </ul>
                        </p>
                        <h2 style={{ marginLeft: 50 }}>ACCESSING AND CORRECTING YOUR CHILD’S INFORMATION </h2>
                        <p style={{ marginLeft: 50 }}>At any time, you may review your child’s personal information maintained by us,  require us to correct or delete it, and/or refuse to permit us from further collecting  or using the child’s information.</p>
                        <p style={{ marginLeft: 50 }}>You can review, change, or delete your child’s personal information by:</p>
                        <ul style={{ marginLeft: 50 }}>
                            <li><p> Logging into your account and visiting his or her profile page; or </p></li>
                            <li><p> Contacting us via email at tracey@thrivingonthespectrum.org.</p></li>
                        </ul>
                        <p style={{ marginLeft: 50 }}>To protect your privacy and security, we may require you to take certain steps or  provide additional information to verify your identity before we provide any  information or make corrections.</p>

                        <h2 style={{ marginLeft: 50 }}>OTHER OPERATORS</h2>
                        <p style={{ marginLeft: 50 }}>
                            No other operators may collect or maintain personal information from children  through our Website, our Services, or the Thrive App.</p>

                        <h2>CALIFORNIA RESIDENTS</h2>
                        <p>
                            California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits  our users who are California residents to request and obtain from us, once a year and  free of charge, information about categories of personal information (if any) we disclosed  to third parties for direct marketing purposes and the names and addresses of all third  parties with which we shared personal information in the immediately preceding calendar  year. If you are a California resident and would like to make such a request, please submit  your request in writing to us using the contact information provided below.</p>

                        <h2>CHANGES TO THIS PRIVACY NOTICE</h2>
                        <p>We may update this Privacy Notice from time to time. When we do, we will also revise the  “last updated” date at the top of this page. We encourage you to periodically check this  page to stay informed about how we collect, use, and disclose your information.</p>


                        <h2>CONTACT US</h2>
                        <p>If you have questions or concerns regarding this Terms and Privacy Notice or our data collection  practices, please contact us as follows:
                            <p>Thriving on the Spectrum, LLC.<br></br>
                                Tracey Hawkins<br></br>
                                323 E. Chapel Hill Street<br></br>
                                #1115<br></br>
                                DURHAM, NC 27702<br></br>
                                United States<br></br>
                                <a href="mailto:Tracey@thrivingonthespectrum.org">Tracey@thrivingonthespectrum.org</a>
                            </p>
                        </p>



                        <div className="textFadeOut"></div>
                    </Grid>

                </Grid>

            </div>

        </div >
    );
}

export default TermsConditions;