const RouteConstants = {
  BASE: "/",
  LOGIN: "/login",
  SIGN_UP: "/signUp",
  SIGN_UP_USER_TYPE: "/signUpUserType",
  RESET_PASSWORD: "/resetPassword",
  FORFOT_PASSWORD: "/forgotPassword",
  RESET_FORGOTTEN_PASSWORD: "/resetforgottenPassword",
  VERIFY_EMAIL: "/verifyEmail",
  CONFIRM_EMAIL: "/confirmEmail",
  CONFIRM_ACCOUNT: "/confirmAccount",
  PAYMENT: "/payment",
  PAYMENT_SUCCESS: "/paymentSuccessful",
  PAYMENT_FAILURE: "/paymentCancelled",
  DASHBOARD: "/dashboard",
  SIDE_BAR: "/sidebar",
  SETUP_CHILD_PROFILE: "/setupChildProfile",

  ACCOUNT_SETTINGS: "/userProfile/:userId",
  EXTERNAL_USERS: "/externalUsers",
  USER_CHAT: "/chat/:childId",
  ADD_EXTERNAL_USERS: "/addExUsers",
  VIEW_EXTERNAL_USERS: "/viewExUsers/:accountId",
  EDIT_CHILD_PROFILE: "/:childId/edit",
  IMAGE_LIBRARY: "/imageLibrary",
  CHILD_ACTIVITIES: "/:childId/activities",
  TEMPLATE_ACTIVITIES: "/:childId/templateActivities",
  ADD_ACTIVITY: "/:childId/addNewActivity",
  EDIT_ACTIVITY: "/:childId/editActivity/:activityId",

  SCHEDULE_ACTIVITY: "/:childId/schedule",
  SELF_REGULATION: "/:childId/selfRegulation",
  ADD_SELF_REGULATION_TOOL: "/:childId/addSelfRegulationTool",
  EDIT_SELF_REGULATION_TOOL: "/:childId/editSelfRegulationTool/:toolId",

  CHAT: "/chat",
  GROUP: "/group",
  SELECTED_CHAT: "/chat/:id",
  SELECTED_GROUP: "/group/:id",
};

export default RouteConstants;

// separate chat routes for feature flags
export const CHAT_ROUTES = [
  RouteConstants.CHAT,
  RouteConstants.GROUP,
  RouteConstants.SELECTED_GROUP,
  RouteConstants.SELECTED_CHAT,
];
