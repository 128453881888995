import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, InputLabel, Input, FormHelperText, Button, Link, FormControlLabel, Checkbox, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { validateEmail, validateMandatoryValue, validatePasswordLength } from "../../helpers/Validation";
import AssetConstants from "../../constants/AssetConstants";
import { login, resetPassword } from "../../store/actions/Authentication";
import { Alert } from "@material-ui/lab";
import RouteConstants from "../../constants/RouteConstants";
import Loader from "../../components/Loader/Loader";
import { APP_CONSTANTS } from "../../constants/GenericConstants";
import Header from "../../components/Header/Header";
import SnackbarAlert from "../../components/SnackbarAlert/SnackbarAlert";

const ResetPasswordScreen = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.authentication);

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [apiMsg, setApiMsg] = useState(false);
    const [apiError, setApiError] = useState("");

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const handlePasswordChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        console.log("event.target", event.target.name);

        switch (event.target.name) {
            case "currentPassword":
                setCurrentPassword(input);
                if (formSubmitted) {
                    var [currentStatus, cpResult] = validatePassword(input);
                    setCurrentPasswordError(cpResult);
                }
                break;

            case "password":
                setPassword(input);
                if (formSubmitted) {
                    checkPasswordMatch(input, confirmPassword)

                    var [passwordStatus, pResult] = validatePassword(input);
                    setPasswordError(pResult);
                }
                break;

            case "confirmPassword":
                setConfirmPassword(input);
                if (formSubmitted) {
                    checkPasswordMatch(password, input)

                    var [confirmStatus, cResult] = validatePassword(input);
                    setConfirmPasswordError(cResult);
                }
                break;

            default:
                break;
        }

    }

    const validatePassword = (password) => {

        var message = "";
        var status = true;
        if (!validateMandatoryValue(password)) {
            message = t("passwordRequiredError");
            status = false;

        } else if (!validatePasswordLength(password)) {
            message = t("passwordLengthError").replace(/{minLength}/, APP_CONSTANTS.MIN_PASSWORD_LENGTH).replace(/{maxLength}/, APP_CONSTANTS.MAX_PASSWORD_LENGTH);
            status = false;
        }

        return [status, message];
    }



    const showInputError = (field) => {
        return field.length > 0;
    }

    const onLoginSuccess = () => {

        setApiMsg(true);
        setTimeout(() => {
            history.replace({ pathname: RouteConstants.DASHBOARD });
        }, 2000);
    }

    const onLoginFailure = (errorMessage) => {
        // setPassword("");
        // setConfirmPassword("");
        // setCurrentPassword("");
        setApiError(errorMessage);
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setFormSubmitted(true);
        setApiError("");



        var [currentStatus, cpResult] = validatePassword(currentPassword);
        setCurrentPasswordError(cpResult);

        var [passwordStatus, pResult] = validatePassword(password);
        setPasswordError(pResult);

        var [confirmStatus, cResult] = validatePassword(confirmPassword);
        setConfirmPasswordError(cResult);

        if (currentStatus && passwordStatus && confirmStatus) {
            if (confirmPassword !== password) {
                setApiError(t("passwordMismatchError"));
            } else {
                dispatch(resetPassword(currentPassword, password, confirmPassword, onLoginSuccess, onLoginFailure));
            }
        }

    }

    const checkPasswordMatch = (password, confirmPassword) => {
        if (confirmPassword !== password) {
            setApiError(t("passwordMismatchError"));
            return false
        }
        else {
            setApiError("");
            return true
        }
    }

    const handleClose = () => {
        setApiMsg(prev => !prev)
    }

    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickConfirmShowPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="is-login">
            <Grid xs={12} item className="app-header">
                <Header />
            </Grid>
            {userState.loading && <Loader />}

            <SnackbarAlert show={apiMsg} message={t("resetSuccess")} duration={3000} onCloseAlert={handleClose} />

            <Grid container>
                <Grid item xs={12} sm={6} className="login-form">
                    <div className="login-form-content forgotpassword">
                        <img className="login-logo" src={AssetConstants.logo} />
                        <form>
                            <h1>
                                <span><i onClick={() => history.goBack()} class="ticon-arrow-right"></i></span>
                                {t("resetPasswordHeading")}
                            </h1>
                            {
                                apiError && <Alert severity="error">{apiError}</Alert>
                            }
                            <div className="form-field">
                                {/* <InputLabel htmlFor="password">{t("currentPassword")}</InputLabel> */}
                                <Input
                                    id="currentPassword"
                                    name="currentPassword"
                                    required
                                    type={showOldPassword ? "text" : "password"}
                                    value={currentPassword || ""}
                                    placeholder={t("currentPassword")}
                                    onChange={handlePasswordChange}
                                    autoComplete="email"
                                    error={showInputError(currentPasswordError)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowOldPassword}>
                                                {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>} />
                                {
                                    showInputError(currentPasswordError) && <FormHelperText error={showInputError(currentPasswordError)}>{currentPasswordError}</FormHelperText>
                                }
                            </div>
                            <div className="form-field">
                                {/* <InputLabel htmlFor="password">{t("newPassword")}</InputLabel> */}
                                <Input
                                    id="password"
                                    name="password"
                                    required
                                    type={showPassword ? "text" : "password"}
                                    value={password || ""}
                                    placeholder={t("newPassword")}
                                    onChange={handlePasswordChange}
                                    error={showInputError(passwordError)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>} />
                                {
                                    showInputError(passwordError) && <FormHelperText error={showInputError(passwordError)}>{passwordError}</FormHelperText>
                                }
                            </div>
                            <div className="form-field">
                                {/* <InputLabel htmlFor="confirmPassword">{t("confirmPassword")}</InputLabel> */}
                                <Input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    required
                                    type={showConfirmPassword ? "text" : "password"}
                                    value={confirmPassword || ""}
                                    placeholder={t("confirmPassword")}
                                    onChange={handlePasswordChange}
                                    error={showInputError(confirmPasswordError)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickConfirmShowPassword}>
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>} />
                                {
                                    showInputError(confirmPasswordError) && <FormHelperText error={showInputError(confirmPasswordError)}>{confirmPasswordError}</FormHelperText>
                                }
                            </div>
                            <div className="form-field login-button">
                                <Button type="submit" color="primary" variant="contained" disableElevation onClick={onSubmit}>{t("resetPasswordButtonTitle")}</Button>
                            </div>

                        </form>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} className="screen-half-splash">

                </Grid>
            </Grid>
        </div>
    );
};

export default ResetPasswordScreen;