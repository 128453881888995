import { request, success, failure } from ".";
import {
  LOGIN_ACTIONS,
  SIGN_UP_ACTIONS,
  CONFIRM_EMAIL_ACTIONS,
  SIGN_OUT_ACTIONS,
  GET_EMAIL_FROM_INVITE_CODE_ACTIONS,
  RESEND_CONFIRMATION_EMAIL_ACTIONS,
  RESET_PASSWORD_ACTIONS,
  FORGOT_RESET_ACTIONS,
  VIEW_USER_ACTIONS,
  EDIT_USER_ACTIONS,
  GET_ALL_COUNTRIES_ACTIONS,
  GET_COUNTRY_STATES_ACTIONS,
  GET_STATE_CITIES_ACTIONS,
  JUST_LOADING_ACTIONS,
  CREATE_PAYMENT_SESSION_ACTIONS,
  FIRST_LOGIN_ACTIONS,
  GET_SUBSCRIPTION_LIST_ACTIONS,
  CANCEL_SUBSCRIPTION_ACTIONS,
  UPDATE_PAYMENT_CARD_ACTIONS,
  RESUBSCRIBE_PAYMENT_ACTIONS,
  UPDATE_USER_ACTIONS,
  GET_HEAR_ABOUT_US_OPTIONS,
  GET_GENDER_LIST_ACTIONS,
} from "../../constants/ActionConstants";
import {
  LOCAL_STORAGE_KEYS,
  ROLE_TYPES,
  APP_CONSTANTS,
  SUBSCRIPTION_STATUS,
  USER_TYPE,
} from "../../constants/GenericConstants";
import AuthenticationService from "../../services/api/AuthenticationService";
import { loadStripe } from "@stripe/stripe-js";
import { resetMessages } from "./Chat/Messages";
import { resetGroupMessages } from "./Chat/GroupMessages";
import { resetConversation } from "./Chat/Conversations";
import { resetGroup } from "./Chat/Group";
import { AppEnvironment } from "../../services/api/ApiClient";

export const signUp = (payload, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(SIGN_UP_ACTIONS.SIGN_UP, {}));
    try {
      const response = await AuthenticationService.signUp(payload);
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER,
        JSON.stringify(response.data)
      );

      // Single user
      const isSingleUser = response.data.userType === USER_TYPE.SINGLE;
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_SINGLE_USER, isSingleUser);

      dispatch(
        success(SIGN_UP_ACTIONS.SIGN_UP_SUCCESS, {
          data: response.data,
          isSingleUser,
        })
      );
      onSuccess(isSingleUser, response.data);
    } catch (error) {
      dispatch(failure(SIGN_UP_ACTIONS.SIGN_UP_FAILURE, {}));
      onFailure(error.data ? error.data.message : "");
    }
  };
};

export const getHearAboutUsOtions = () => {
  return async (dispatch) => {
    dispatch(request(GET_HEAR_ABOUT_US_OPTIONS.GET_HEAR_ABOUT_US_OPTIONS, {}));
    try {
      const hearAboutUsList =
        await AuthenticationService.getHearAboutUSOptions();
      dispatch(
        success(GET_HEAR_ABOUT_US_OPTIONS.GET_HEAR_ABOUT_US_OPTIONS_SUCCESS, {
          data: hearAboutUsList.data,
        })
      );
    } catch (error) {
      dispatch(
        failure(GET_HEAR_ABOUT_US_OPTIONS.GET_HEAR_ABOUT_US_OPTIONS_FAILURE, {})
      );
    }
  };
};

export const getUser = (onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(VIEW_USER_ACTIONS.VIEW_USER, {}));
    try {
      const response = await AuthenticationService.getUser();
      // response.data.subscription = {
      //   price: 9.99,
      //   type: "MONTHLY",
      //   name: "Monthly",
      //   renewalDate: "2021-07-06",
      //   status: 0 - 4,
      //   cardType: "Visa",
      //   cardNumber: "1234",
      //   planId: 2,
      // };
      dispatch(success(VIEW_USER_ACTIONS.VIEW_USER_SUCCESS, {}));
      onSuccess(response.data);
    } catch (error) {
      dispatch(failure(VIEW_USER_ACTIONS.VIEW_USER_FAILURE, {}));
      onFailure(error);
    }
  };
};

export const editUser = (profile, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(EDIT_USER_ACTIONS.EDIT_USER, {}));
    try {
      const response = await AuthenticationService.editUser(profile);
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER,
        JSON.stringify(response.data)
      );
      dispatch(
        success(EDIT_USER_ACTIONS.EDIT_USER_SUCCESS, { data: response.data })
      );
      onSuccess(response.data);
    } catch (error) {
      dispatch(failure(EDIT_USER_ACTIONS.EDIT_USER_FAILURE, {}));
      onFailure(error);
    }
  };
};

export const forgotPassword = (payload, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(FORGOT_RESET_ACTIONS.FORGOT_RESET, {}));
    try {
      const user = await AuthenticationService.forgotPassword(payload);
      dispatch(success(FORGOT_RESET_ACTIONS.FORGOT_RESET_SUCCESS, {}));
      onSuccess(user);
    } catch (error) {
      dispatch(failure(FORGOT_RESET_ACTIONS.FORGOT_RESET_FAILURE, {}));
      onFailure(error.data.message);
    }
  };
};

export const resetForgottenPassword = (data, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(FORGOT_RESET_ACTIONS.FORGOT_RESET, {}));
    try {
      const response = await AuthenticationService.resetForgottenPassword(data);
      dispatch(success(FORGOT_RESET_ACTIONS.FORGOT_RESET_SUCCESS, {}));
      onSuccess(response);
    } catch (error) {
      dispatch(failure(FORGOT_RESET_ACTIONS.FORGOT_RESET_FAILURE, {}));
      onFailure(error.data.message);
    }
  };
};

export const verifyForgotCode = (data, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(FORGOT_RESET_ACTIONS.FORGOT_RESET, {}));
    try {
      const response = await AuthenticationService.verifyForgotCode(data);
      dispatch(success(FORGOT_RESET_ACTIONS.FORGOT_RESET_SUCCESS, {}));
      onSuccess(response);
    } catch (error) {
      dispatch(failure(FORGOT_RESET_ACTIONS.FORGOT_RESET_FAILURE, {}));
      onFailure(error.data.message);
    }
  };
};

export const login = (
  email,
  password,
  onSuccess,
  onFailure,
  onPaymentFailure
) => {
  return async (dispatch) => {
    dispatch(request(LOGIN_ACTIONS.LOGIN, {}));
    try {
      const response = await AuthenticationService.login(email, password);
      // dispatch(registerDeviceForNotifications());
      registerDeviceForPushNotifications();
      const accountsArray = response.data.user.accounts;
      // TODO: add other external user also
      const isEx = accountsArray.filter(
        (account) =>
          account.role === ROLE_TYPES.TEACHER ||
          account.role === ROLE_TYPES.THERAPIST ||
          account.role === ROLE_TYPES.CO_PARENT_GUARDIAN
      );
      const isPrnt = accountsArray.filter(
        (account) => account.role === ROLE_TYPES.PARENT
      );
      let isExternalUser = false;
      let isParent = false;
      if (isEx.length !== 0) {
        isExternalUser = true;
      }
      if (isPrnt.length !== 0) {
        isParent = true;
      }

      if (isParent) {
        const sStatus = isPrnt[0]?.subscriptionStatus;
        if (sStatus === SUBSCRIPTION_STATUS.UN_SUBSCRIBED) {
          dispatch(
            success(LOGIN_ACTIONS.LOGIN_SUCCESS, {
              data: response.data.user,
              isExternalUser: isExternalUser,
              isParent: isParent,
            })
          );

          localStorage.setItem(LOCAL_STORAGE_KEYS.IS_UNSUBSCRIBED_USER, true);
          onPaymentFailure(response.data.token.accessToken);
          return;
        }
      }

      // Single user
      const isSingleUser = response.data.user.userType === USER_TYPE.SINGLE;

      // Storing access token and user details in local storage
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
        response.data.token.accessToken
      );
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER,
        JSON.stringify(response.data.user)
      );
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_EXTERNAL_USER, isExternalUser);
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_PARENT, isParent);
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN, true);
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_SINGLE_USER, isSingleUser);

      dispatch(
        success(LOGIN_ACTIONS.LOGIN_SUCCESS, {
          data: response.data.user,
          isExternalUser: isExternalUser,
          isParent: isParent,
          isSingleUser: isSingleUser,
        })
      );

      onSuccess(isSingleUser, response.data.user);
    } catch (error) {
      console.log(error);
      dispatch(failure(LOGIN_ACTIONS.LOGIN_FAILURE, {}));
      onFailure(error.data);
    }
  };
};

export const firstLogin = (onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(FIRST_LOGIN_ACTIONS.FIRST_LOGIN, {}));
    try {
      await AuthenticationService.firstLogin();
      dispatch(success(FIRST_LOGIN_ACTIONS.FIRST_LOGIN_SUCCESS, {}));
      const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER)
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
        : null;
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER,
        JSON.stringify({ ...user, loggedBefore: true })
      );
      onSuccess();
    } catch (error) {
      dispatch(failure(FIRST_LOGIN_ACTIONS.FIRST_LOGIN_FAILURE, {}));
      onFailure(error);
    }
  };
};

export const registerDeviceForNotifications = () => {
  return async (dispatch) => {
    try {
      const OneSignal = window.OneSignal;
      OneSignal.isPushNotificationsEnabled(function (isEnabled) {
        if (isEnabled) {
          OneSignal.getUserId(async function (userId) {
            // console.log('player_id of the subscribed user is : ' + userId);
            await AuthenticationService.registerDeviceForNotification(userId);
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const registerDeviceForPushNotifications = async () => {
  try {
    const OneSignal = window.OneSignal;
    OneSignal.isPushNotificationsEnabled(function (isEnabled) {
      if (isEnabled) {
        OneSignal.getUserId(async function (userId) {
          // console.log('player_id of the subscribed user is : ' + userId);
          // OneSignal.registerForPushNotifications();
          await AuthenticationService.registerDeviceForNotification(userId);
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getOneSignalUserId = async () => {
  return new Promise((resolve, reject) => {
    const OneSignal = window.OneSignal;
    console.log("userId getOneSignalUserId");
    OneSignal.getUserId((userId) => {
      console.log({ OneSignal: userId });
      if (userId) {
        resolve(userId);
      } else {
        reject(new Error("Failed to get OneSignal user ID"));
      }
    });
  });
};

const pushWithOneSignalLogic = () => {
  return new Promise((resolve, reject) => {
    const OneSignal = window.OneSignal;

    OneSignal.push(() => {
      getOneSignalUserId()
        .then((userId) => {
          // Additional OneSignal push logic here
          resolve(userId); // Resolve the promise if the logic is successful
        })
        .catch((error) => {
          console.error("Error:", error.message);
          reject(error); // Reject the promise if there is an error
        });
    });
  });
};

export const unRegisterDeviceForPushNotifications = async () => {
  try {
    const userId = await pushWithOneSignalLogic();

    await AuthenticationService.unRegisterDeviceForNotification(userId);
    await AuthenticationService.logout();
  } catch (error) {
    console.log(error);
  }
};

export const userLogout = async () => {
  try {
    const data = {};

    const OneSignal = window.OneSignal;
    OneSignal.isPushNotificationsEnabled(async function (isEnabled) {
      if (isEnabled) {
        OneSignal.getUserId(async function (userId) {
          data.notificationIdentifier = userId;
        });
      }
      await AuthenticationService.logout(data);
    });
  } catch (error) {
    console.log(error);
  }
};

export const confirmEmail = (code, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(CONFIRM_EMAIL_ACTIONS.CONFIRM_EMAIL, {}));
    try {
      const response = await AuthenticationService.confirmEmail(code);
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
        response.data.token.accessToken
      );
      dispatch(success(CONFIRM_EMAIL_ACTIONS.CONFIRM_EMAIL_SUCCESS, {}));
      onSuccess();
    } catch (error) {
      dispatch(failure(CONFIRM_EMAIL_ACTIONS.CONFIRM_EMAIL_FAILURE, {}));
      onFailure(error.data.message);
    }
  };
};

export const getEmailFromInviteCode = (code, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(
      request(GET_EMAIL_FROM_INVITE_CODE_ACTIONS.GET_EMAIL_FROM_INVITE_CODE, {})
    );
    try {
      const response = await AuthenticationService.getEmailFromInviteCode(code);
      dispatch(
        success(
          GET_EMAIL_FROM_INVITE_CODE_ACTIONS.GET_EMAIL_FROM_INVITE_CODE_SUCCESS,
          {}
        )
      );
      onSuccess(response.data);
    } catch (error) {
      dispatch(
        failure(
          GET_EMAIL_FROM_INVITE_CODE_ACTIONS.GET_EMAIL_FROM_INVITE_CODE_FAILURE,
          {}
        )
      );
      onFailure(error.response.data.message);
    }
  };
};

export const resendConfirmationEmail = (email, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(
      request(RESEND_CONFIRMATION_EMAIL_ACTIONS.RESEND_CONFIRMATION_EMAIL, {})
    );
    try {
      await AuthenticationService.resendConfirmationEmail(email);
      dispatch(
        success(
          RESEND_CONFIRMATION_EMAIL_ACTIONS.RESEND_CONFIRMATION_EMAIL_SUCCESS,
          {}
        )
      );
      onSuccess();
    } catch (error) {
      dispatch(
        failure(
          RESEND_CONFIRMATION_EMAIL_ACTIONS.RESEND_CONFIRMATION_EMAIL_FAILURE,
          {}
        )
      );
      onFailure(error.data);
    }
  };
};

export const resetPassword = (
  cPassword,
  password,
  confPassword,
  onSuccess,
  onFailure
) => {
  return async (dispatch) => {
    dispatch(request(RESET_PASSWORD_ACTIONS.RESET_PASSWORD, {}));
    try {
      const response = await AuthenticationService.resetPassword(
        cPassword,
        password,
        confPassword
      );
      const accountsArray = response.data.user.accounts;
      const isEx = accountsArray.filter(
        (account) =>
          account.role === ROLE_TYPES.TEACHER ||
          account.role === ROLE_TYPES.THERAPIST
      );
      const isPrnt = accountsArray.filter(
        (account) => account.role === ROLE_TYPES.PARENT
      );
      let isExternalUser = false;
      let isParent = false;
      if (isEx.length !== 0) {
        isExternalUser = true;
      }
      if (isPrnt.length !== 0) {
        isParent = true;
      }

      // Single user
      const isSingleUser = response.data.user.userType === USER_TYPE.SINGLE;

      // Storing access token and user details in local storage
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
        response.data.token.accessToken
      );
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER,
        JSON.stringify(response.data.user)
      );
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_EXTERNAL_USER, isExternalUser);
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_PARENT, isParent);

      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_SINGLE_USER, isSingleUser);

      dispatch(
        success(LOGIN_ACTIONS.LOGIN_SUCCESS, {
          data: response.data.user,
          isExternalUser: isExternalUser,
          isParent: isParent,
          isSingleUser: isSingleUser,
        })
      );

      onSuccess(isSingleUser);
    } catch (error) {
      dispatch(failure(RESET_PASSWORD_ACTIONS.RESET_PASSWORD_FAILURE, {}));
      onFailure(error.data.message);
    }
  };
};

export const getPresignedURL = (fileName, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(VIEW_USER_ACTIONS.VIEW_USER, {}));
    try {
      const response = await AuthenticationService.getPresignedURL(fileName);
      dispatch(request(VIEW_USER_ACTIONS.VIEW_USER_SUCCESS, {}));
      onSuccess(response);
    } catch (error) {
      dispatch(request(VIEW_USER_ACTIONS.VIEW_USER_FAILURE, {}));
      onFailure(error);
    }
  };
};

export const getImageURL = (fileName, onSuccess, onFailure) => {
  return async (dispatch) => {
    // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD, {}));
    try {
      const response = await AuthenticationService.getImageURL(fileName);
      // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_SUCCESS, {}));
      onSuccess(response);
    } catch (error) {
      // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_FAILURE, {}));
      onFailure(error);
    }
  };
};

export const uploadImage = (data, urlAws, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(VIEW_USER_ACTIONS.VIEW_USER, {}));

    try {
      const response = await AuthenticationService.uploadImagePut(data, urlAws);
      dispatch(request(VIEW_USER_ACTIONS.VIEW_USER_SUCCESS, {}));
      onSuccess(response);
    } catch (error) {
      dispatch(request(VIEW_USER_ACTIONS.VIEW_USER_FAILURE, {}));
      onFailure(error);
    }
  };
};

export const logout = (onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(SIGN_OUT_ACTIONS.SIGN_OUT, {}));
    try {
      // NOTE: onesignal needs to configured correctly in order to logout function works properly (below function)
      await unRegisterDeviceForPushNotifications();
      // await AuthenticationService.logout();

      // NOTE: can use this in dev mode (if one signal not configured in dev(local) mood)
      // await userLogout();
      localStorage.clear();
      await dispatch(success(SIGN_OUT_ACTIONS.SIGN_OUT_SUCCESS, {}));

      //==clear the chat redux states
      await Promise.all([
        dispatch(resetConversation()),
        dispatch(resetGroup()),
        dispatch(resetMessages()),
        dispatch(resetGroupMessages()),
      ]);
      //==
      onSuccess();
    } catch (error) {
      dispatch(failure(SIGN_OUT_ACTIONS.SIGN_OUT_FAILURE, {}));
      onFailure();
    }
  };
};

export const getAllCountries = () => {
  return async (dispatch) => {
    dispatch(request(GET_ALL_COUNTRIES_ACTIONS.GET_ALL_COUNTRIES, {}));
    try {
      const countries = await AuthenticationService.getAllCountries();
      dispatch(
        success(GET_ALL_COUNTRIES_ACTIONS.GET_ALL_COUNTRIES_SUCCESS, {
          data: countries.data,
        })
      );
    } catch (error) {
      dispatch(
        failure(GET_ALL_COUNTRIES_ACTIONS.GET_ALL_COUNTRIES_FAILURE, {})
      );
    }
  };
};

export const getGenderList = () => {
  return async (dispatch) => {
    dispatch(request(GET_GENDER_LIST_ACTIONS.GET_GENDER_LIST, {}));
    try {
      const genderList = await AuthenticationService.getGenderList();
      dispatch(
        success(GET_GENDER_LIST_ACTIONS.GET_GENDER_LIST_SUCCESS, {
          data: genderList.data,
        })
      );
    } catch (error) {
      dispatch(failure(GET_GENDER_LIST_ACTIONS.GET_GENDER_LIST_FAILURE, {}));
    }
  };
};

export const getStatesOfCountry = (countryCode) => {
  return async (dispatch) => {
    dispatch(request(GET_COUNTRY_STATES_ACTIONS.GET_COUNTRY_STATES, {}));
    try {
      const states = await AuthenticationService.getStatesOfCountry(
        countryCode
      );
      dispatch(
        success(GET_COUNTRY_STATES_ACTIONS.GET_COUNTRY_STATES_SUCCESS, {
          data: states.data,
        })
      );
    } catch (error) {
      dispatch(
        failure(GET_COUNTRY_STATES_ACTIONS.GET_COUNTRY_STATES_FAILURE, {})
      );
    }
  };
};

export const getCitiesOfState = (countryCode, stateCode, onSuccess) => {
  return async (dispatch) => {
    dispatch(request(GET_STATE_CITIES_ACTIONS.GET_STATE_CITIES, {}));
    try {
      const cities = await AuthenticationService.getCitiesOfState(
        countryCode,
        stateCode
      );
      dispatch(
        success(GET_STATE_CITIES_ACTIONS.GET_STATE_CITIES_SUCCESS, {
          data: cities.data,
        })
      );
      onSuccess(cities.data);
    } catch (error) {
      dispatch(failure(GET_STATE_CITIES_ACTIONS.GET_STATE_CITIES_FAILURE, {}));
    }
  };
};

export const getAllCountriesInitial = (onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(GET_ALL_COUNTRIES_ACTIONS.GET_ALL_COUNTRIES, {}));
    try {
      const countries = await AuthenticationService.getAllCountries();
      dispatch(
        success(GET_ALL_COUNTRIES_ACTIONS.GET_ALL_COUNTRIES_SUCCESS, {
          data: countries.data,
        })
      );
      onSuccess(countries.data);
    } catch (error) {
      dispatch(
        failure(GET_ALL_COUNTRIES_ACTIONS.GET_ALL_COUNTRIES_FAILURE, {})
      );
      onFailure();
    }
  };
};

export const getStatesOfCountryInitial = (
  countryCode,
  onSuccess,
  onFailure
) => {
  return async (dispatch) => {
    dispatch(request(GET_COUNTRY_STATES_ACTIONS.GET_COUNTRY_STATES, {}));
    try {
      const states = await AuthenticationService.getStatesOfCountry(
        countryCode
      );

      dispatch(
        success(GET_COUNTRY_STATES_ACTIONS.GET_COUNTRY_STATES_SUCCESS, {
          data: states.data,
        })
      );
      onSuccess(states.data);
    } catch (error) {
      dispatch(
        failure(GET_COUNTRY_STATES_ACTIONS.GET_COUNTRY_STATES_FAILURE, {})
      );
      onFailure();
    }
  };
};

export const getCitiesOfStateInitial = (
  countryCode,
  stateCode,
  onSuccess,
  onFailure
) => {
  return async (dispatch) => {
    dispatch(request(GET_STATE_CITIES_ACTIONS.GET_STATE_CITIES, {}));
    try {
      const cities = await AuthenticationService.getCitiesOfState(
        countryCode,
        stateCode
      );
      dispatch(
        success(GET_STATE_CITIES_ACTIONS.GET_STATE_CITIES_SUCCESS, {
          data: cities.data,
        })
      );
      onSuccess(cities.data);
    } catch (error) {
      dispatch(failure(GET_STATE_CITIES_ACTIONS.GET_STATE_CITIES_FAILURE, {}));
      onFailure();
    }
  };
};

export const getSubscriptionList = () => {
  return async (dispatch) => {
    dispatch(request(GET_SUBSCRIPTION_LIST_ACTIONS.GET_SUBSCRIPTION_LIST, {}));
    try {
      const response = await AuthenticationService.getSubscriptionList();
      dispatch(
        success(GET_SUBSCRIPTION_LIST_ACTIONS.GET_SUBSCRIPTION_LIST_SUCCESS, {
          data: response.data,
        })
      );
    } catch (error) {
      dispatch(
        failure(GET_SUBSCRIPTION_LIST_ACTIONS.GET_SUBSCRIPTION_LIST_FAILURE, {})
      );
    }
  };
};

export const createPaymentSession = (subscriptionId, accountId) => {
  return async (dispatch) => {
    dispatch(
      request(CREATE_PAYMENT_SESSION_ACTIONS.CREATE_PAYMENT_SESSION, {})
    );
    try {
      const response = await AuthenticationService.createPaymentSession(
        subscriptionId,
        accountId
      );
      dispatch(
        success(
          CREATE_PAYMENT_SESSION_ACTIONS.CREATE_PAYMENT_SESSION_SUCCESS,
          {}
        )
      );

      await redirectToStripe(response.data);
    } catch (error) {
      dispatch(
        failure(
          CREATE_PAYMENT_SESSION_ACTIONS.CREATE_PAYMENT_SESSION_FAILURE,
          {}
        )
      );
    }
  };
};

export const redirectToStripe = async (data) => {
  const stripe = await loadStripe(
    AppEnvironment.config().stripe_publishable_key
  );

  await stripe
    .redirectToCheckout({
      sessionId: data.sessionId,
    })
    .then((res) => {
      console.log("Stripe data:");
      console.log(res);
    })
    .catch((error) => {
      console.log("Stripe error:");
      console.log(error);
    });
};

export const cancelSubscription = (onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(CANCEL_SUBSCRIPTION_ACTIONS.CANCEL_SUBSCRIPTION, {}));
    try {
      const response = await AuthenticationService.cancelSubscription();
      dispatch(
        success(CANCEL_SUBSCRIPTION_ACTIONS.CANCEL_SUBSCRIPTION_SUCCESS, {})
      );
      onSuccess();
    } catch (error) {
      dispatch(
        failure(CANCEL_SUBSCRIPTION_ACTIONS.CANCEL_SUBSCRIPTION_FAILURE, {})
      );
      onFailure(error.message);
    }
  };
};

export const updatePaymentCard = (onFailure) => {
  return async (dispatch) => {
    dispatch(request(UPDATE_PAYMENT_CARD_ACTIONS.UPDATE_PAYMENT_CARD, {}));
    try {
      const response = await AuthenticationService.updatePaymentCard();
      dispatch(
        success(UPDATE_PAYMENT_CARD_ACTIONS.UPDATE_PAYMENT_CARD_SUCCESS, {})
      );
      await redirectToStripe(response.data);
    } catch (error) {
      dispatch(
        failure(UPDATE_PAYMENT_CARD_ACTIONS.UPDATE_PAYMENT_CARD_FAILURE, {})
      );
      onFailure(error.message);
    }
  };
};

export const resubscribePayment = (planId, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(RESUBSCRIBE_PAYMENT_ACTIONS.RESUBSCRIBE_PAYMENT, {}));
    try {
      const response = await AuthenticationService.resubscribePayment(planId);
      dispatch(
        success(RESUBSCRIBE_PAYMENT_ACTIONS.RESUBSCRIBE_PAYMENT_SUCCESS, {})
      );
      const { data } = response;
      if (data.newSubscription && data.sessionId) {
        await redirectToStripe(data);
      } else {
        onSuccess();
      }
    } catch (error) {
      dispatch(
        failure(RESUBSCRIBE_PAYMENT_ACTIONS.RESUBSCRIBE_PAYMENT_FAILURE, {})
      );
      onFailure(error.message);
    }
  };
};

export const updateUserInStorage = (userData) => {
  return async (dispatch) => {
    delete userData.createdAt;
    delete userData.updatedAt;
    delete userData.subscription;
    let len = userData.accounts.length;
    for (let i = 0; i < len; i++) {
      delete userData.accounts[i].createdAt;
      delete userData.accounts[i].updatedAt;
      delete userData.accounts[i].confirmationCode;
      delete userData.accounts[i].createdAt;
    }
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(userData));
    dispatch(success(UPDATE_USER_ACTIONS.UPDATE_USER, { data: userData }));
  };
};

export const updateUserStatus = (status) => {
  return async (dispatch) => {
    dispatch(request(VIEW_USER_ACTIONS.VIEW_USER, {}));
    try {
      const response = await AuthenticationService.getUser();

      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER,
        JSON.stringify(response.data)
      );
      dispatch(
        success(UPDATE_USER_ACTIONS.UPDATE_USER, { data: response.data })
      );
    } catch (err) {
      dispatch(failure(VIEW_USER_ACTIONS.VIEW_USER_FAILURE, {}));
    }
    // dispatch(success(UPDATE_USER_ACTIONS.UPDATE_STATUS, { }));
  };
};

export const saveEmailAddress = (email) => {
  return async (dispatch) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER,
      JSON.stringify({ emailAddress: email })
    );
    dispatch(request(UPDATE_USER_ACTIONS.SAVE_EMAIL, { data: email }));
  };
};
