const INITIAL_STATE = {
  type: "private",
};

const selectedType = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_CHAT_TYPE":
      return {
        ...state,
        type: action.payload,
      };

    default:
      return { ...state };
  }
};

export default selectedType;
