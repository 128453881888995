import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  InputLabel,
  Input,
  FormHelperText,
  Select,
  MenuItem,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
} from "@material-ui/core";
import {
  validateEmail,
  validateMandatoryValue,
  validateMaximumLength,
  validateZipLength,
} from "../../helpers/Validation";
import { formatTimerNumber } from "../../helpers/GenericHelper";
import { APP_CONSTANTS } from "../../constants/GenericConstants";
import CropperComponent from "../CropperComponent/CropperComponent";
import {
  getAllCountries,
  getStatesOfCountry,
  getCitiesOfState,
} from "../../store/actions/Authentication";

import PhoneInput, {
  getCountryCallingCode,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import RouteConstants from "../../constants/RouteConstants";
import Loader from "../Loader/Loader";
import AccountPaymentInfo from "./AccountPaymentInfo";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import AssetConstants from "../../constants/AssetConstants";

const useStyles = makeStyles((theme) => ({
  select: {
    "& ul": {
      height: 30,
      paddingTop: 0,
    },
  },
  billedDur: {
    color: "#a4a4a4",
    fontSize: "14px",
    letterSpacing: "-0.39px",
  },
  savetag: {
    width: "75px",
    height: "25px",
    background: "#f9be02",
    fontSize: "12px",
    letterSpacing: "-0.33px",
    color: "#333333",
    position: "absolute",
    left: "13px",
    top: "8px",
    borderRadius: "12.5px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
  },
  paymentsCard: {
    marginTop: "14px",
    // padding: "20px",
    // height: "1.5rem",
  },
  container: {
    marginTop: "6px",
    paddingLeft: "15px",
  },
  noPaymentSelected: {
    display: "block",
    width: "100%",
  },
  paymentHeader: {
    width: "100%",
  },
  SubscriptionText: {
    marginTop: "8px",
  },
}));

const PersonalInformationSection = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.authentication);
  const history = useHistory();

  const { show_profile_payment } = useFeatureFlags();

  const {
    data,
    setData,
    errors,
    setErrors,
    formSubmitted,
    countryStateError,
    setCountryStateError,
  } = props;

  const {
    firstName,
    lastName,
    country,
    city,
    email,
    zipCode,
    countryState,
    phoneNumber,
    childImage,
    photoURL,
    userSubscription,
  } = data;
  const {
    firstNameError,
    lastNameError,
    countryError,
    cityError,
    zipCodeError,
    emailError,
    phoneNumberError,
    childImageError,
  } = errors;

  const [countryStates, setCountryStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [compressing, setCompressing] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const [showAddImageDialog, setShowAddImageDialog] = useState(false);

  var phoneCode = "";

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  const handleCloseDialog = () => {
    setData({ ...data, childImage: {} });
    setShowAddImageDialog(false);
  };

  const onImageSave = (img) => {
    const fileURL = URL.createObjectURL(img);
    let imageObject = new Image();
    imageObject.src = fileURL;

    setData({ ...data, childImage: { file: img, image: imageObject } });
    setErrors({ ...errors, childImageError: "" });

    setShowAddImageDialog(false);
  };

  const today = new Date();
  const [maxDate, setMaxDate] = useState(
    today.getFullYear() -
      APP_CONSTANTS.MIN_CHILD_AGE +
      "-" +
      formatTimerNumber(today.getMonth() + 1) +
      "-" +
      formatTimerNumber(today.getDate())
  );
  const [minDate, setMinDate] = useState(
    today.getFullYear() -
      APP_CONSTANTS.MAX_CHILD_AGE +
      "-" +
      formatTimerNumber(today.getMonth() + 1) +
      "-" +
      formatTimerNumber(today.getDate())
  );

  const goToResetPasswordScreen = () => {
    history.push({ pathname: RouteConstants.RESET_PASSWORD });
  };

  const handleFirstNameChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setData({ ...data, firstName: input });
    validateFirstName(input);
  };

  const handleLastNameChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setData({ ...data, lastName: input });
    validateLastName(input);
  };

  const handleZipCodeChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setData({ ...data, zipCode: input });

    // if (formSubmitted) {
    validateZipCode(input);
    // }
  };

  const handleEmailChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setData({ ...data, email: input });
    validateEmailAddress(input);
  };

  const handlePhoneChange = (value) => {
    if (value === undefined) {
      // setPhone(phoneCode)
    } else {
      setData({ ...data, phoneNumber: value });
    }
    // if (formSubmitted) {
    validatePhone(value);
    // }
  };
  const getCountryNameOptions = () => {
    return userState.countriesList
      .sort(function (x, y) {
        return x.name == "United States"
          ? -1
          : y.name == "United States"
          ? 1
          : 0;
      })
      .map((countryData, index) => {
        return (
          <MenuItem key={index} value={countryData.isoCode}>
            {countryData.name}
          </MenuItem>
        );
      });
  };

  const getStateNameOptions = () => {
    return userState.statesList.map((stateData, index) => {
      return (
        <MenuItem key={index} value={stateData.isoCode}>
          {stateData.name}
        </MenuItem>
      );
    });
  };

  const getCityNameOptions = () => {
    return userState.citiesList.map((cityData, index) => {
      return (
        <MenuItem key={index} value={cityData.name}>
          {cityData.name}
        </MenuItem>
      );
    });
  };

  const handleCountryChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setData({ ...data, country: input, countryState: "", city: "" });
    dispatch(getStatesOfCountry(input));

    // if (formSubmitted) {
    validateCountry(input);
    validateCountryState("");
    validateCity("");
    // }
  };

  const handleCountryStateChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setData({ ...data, countryState: input, city: "" });

    dispatch(
      getCitiesOfState(country, input, (res) => {
        // alert("Received " + res.length)
        if (res.length === 0) {
          // setData({ ...data, city: "No City Found" });
        }
      })
    );

    // if (formSubmitted) {
    validateCountryState(input);
    validateCity("");
    // }
  };

  const handleCityChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setData({ ...data, city: input });

    // if (formSubmitted) {
    validateCity(input);
    // }
  };

  const handleImageUpload = (event) => {
    event.preventDefault();
    let imageObject = null;
    let fileObject = null;

    if (event.target.files.length > 0) {
      fileObject = event.target.files[0];
      // let imgSize = Math.round((fileObject.size / 1024 / 1024) * 10) / 10;
      // if (imgSize <= 5) {

      let fileName = fileObject.name;
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        setCompressing(true);

        const fileURL = URL.createObjectURL(fileObject);
        imageObject = new Image();
        imageObject.src = fileURL;
        imageObject.onload = () => {
          setCompressing(false);
        };
        setData({
          ...data,
          childImage: { file: fileObject, image: imageObject },
          photoURL: "",
        });
        setErrors({ ...errors, childImageError: "" });
        setShowAddImageDialog(true);
      } else {
        setData({ ...data, childImage: {} });
        setErrors({ ...errors, childImageError: t("imageTypeError") });
      }

      // }
      // else {
      //     setErrors({ ...errors, childImageError: t("imageSizeError") });
      // }
    }

    event.target.value = null;
  };

  const validateFirstName = (firstName) => {
    if (!validateMandatoryValue(firstName)) {
      setErrors({ ...errors, firstNameError: t("firstNameRequired") });
    } else if (
      !validateMaximumLength(firstName, APP_CONSTANTS.MAX_NAMES_LENGTH)
    ) {
      setErrors({
        ...errors,
        firstNameError: t("nameLengthErrorMessage").replace(
          /{maxLength}/,
          APP_CONSTANTS.MAX_NAMES_LENGTH
        ),
      });
    } else {
      setErrors({ ...errors, firstNameError: "" });
    }
  };

  const validateLastName = (lastName) => {
    if (!validateMandatoryValue(lastName)) {
      setErrors({ ...errors, lastNameError: t("lastNameRequired") });
    } else if (
      !validateMaximumLength(lastName, APP_CONSTANTS.MAX_NAMES_LENGTH)
    ) {
      setErrors({
        ...errors,
        lastNameError: t("nameLengthErrorMessage").replace(
          /{maxLength}/,
          APP_CONSTANTS.MAX_NAMES_LENGTH
        ),
      });
    } else {
      setErrors({ ...errors, lastNameError: "" });
    }
  };
  const validateZipCode = (zipCode) => {
    if (!validateMandatoryValue(zipCode)) {
      setErrors({ ...errors, zipCodeError: t("zipCodeRequiredError") });

      return false;
    } else if (!validateZipLength(zipCode)) {
      setErrors({ ...errors, zipCodeError: t("zipCodeLengthError") });
      return false;
    } else {
      setErrors({ ...errors, zipCodeError: "" });

      return true;
    }
    return true;
  };

  const validatePhone = (phone) => {
    if (!validateMandatoryValue(phone)) {
      setErrors({ ...errors, phoneNumberError: t("phoneRequired") });
      return false;
    } else if (!isPossiblePhoneNumber(phone)) {
      setErrors({ ...errors, phoneNumberError: t("invalidPhone") });
      return false;
    } else {
      setErrors({ ...errors, phoneNumberError: "" });
      return true;
    }
  };
  const [emailFieldDisabled, disableEmailField] = useState(false);

  const validateCountry = (country) => {
    if (!validateMandatoryValue(country)) {
      setErrors({ ...errors, countryError: t("countryRequired") });
      return false;
    } else {
      setErrors({ ...errors, countryError: "" });
      return true;
    }
  };

  const validateCountryState = (countryState) => {
    if (!validateMandatoryValue(countryState)) {
      setCountryStateError(t("stateRequiredError"));
      return false;
    } else {
      setCountryStateError("");
      return true;
    }
  };

  const validateCity = (city) => {
    if (!validateMandatoryValue(city)) {
      setErrors({ ...errors, cityError: t("cityRequired") });
      return false;
    } else {
      setErrors({ ...errors, cityError: "" });
      return true;
    }
  };

  const validateEmailAddress = (email) => {
    if (validateMandatoryValue(email) && !validateEmail(email)) {
      setErrors({ ...errors, emailError: t("invalidEmailError") });
    } else {
      setErrors({ ...errors, emailError: "" });
    }
  };

  const showInputError = (field) => {
    return field.length > 0;
  };
  return (
    <div className="view-edit-section view-edit-profile pb-0 mb-0">
      <h4>{t("personalinformation")}</h4>

      {compressing && !loaded && <Loader />}

      <Dialog
        className="AddPopup-Box"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        open={showAddImageDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{t("addNewImage")}</DialogTitle>
        <DialogContent>
          <CropperComponent
            addedImage={childImage}
            closeDialog={handleCloseDialog}
            onSave={onImageSave}
            compressing={compressing}
            setCompressing={setCompressing}
          />
        </DialogContent>
      </Dialog>

      <form>
        <Grid container spacing={3}>
          <Grid item md={8} sm={6} xs={12} className="grid-fields">
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <div className="form-field">
                  <InputLabel htmlFor="firstName" required>
                    {t("firstName")}
                  </InputLabel>
                  <Input
                    id="firstName"
                    name="firstName"
                    required
                    type="text"
                    value={firstName || ""}
                    onChange={handleFirstNameChange}
                    error={showInputError(firstNameError)}
                  />
                  {showInputError(firstNameError) && (
                    <FormHelperText error={showInputError(firstNameError)}>
                      {firstNameError}
                    </FormHelperText>
                  )}
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="form-field">
                  <InputLabel htmlFor="lastName" required>
                    {t("lastName")}
                  </InputLabel>
                  <Input
                    id="lastName"
                    name="lastName"
                    required
                    type="text"
                    value={lastName || ""}
                    onChange={handleLastNameChange}
                    error={showInputError(lastNameError)}
                  />
                  {showInputError(lastNameError) && (
                    <FormHelperText error={showInputError(lastNameError)}>
                      {lastNameError}
                    </FormHelperText>
                  )}
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="form-field">
                  <InputLabel id="country" required>
                    {t("country")}
                  </InputLabel>
                  {/* {country && */}
                  <Select
                    MenuProps={{ classes: { paper: classes.select } }}
                    labelId="country"
                    id="countrySelect"
                    name="countrySelect"
                    required
                    value={country}
                    onChange={handleCountryChange}
                    error={showInputError(countryError)}
                  >
                    {userState.countriesList.length === 0 ? (
                      <MenuItem value="" disabled>
                        {t("loading")}
                      </MenuItem>
                    ) : (
                      getCountryNameOptions()
                    )}
                  </Select>
                  {/* } */}
                  {showInputError(countryError) && (
                    <FormHelperText error={showInputError(countryError)}>
                      {countryError}
                    </FormHelperText>
                  )}
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="form-field">
                  <InputLabel id="countryState" required>
                    {t("state")}
                  </InputLabel>
                  <Select
                    labelId="countryState"
                    id="countryStateSelect"
                    name="countryStateSelect"
                    required
                    value={countryState ? countryState : ""}
                    onChange={handleCountryStateChange}
                    error={showInputError(countryStateError)}
                  >
                    {userState.statesLoading ? (
                      <MenuItem value="" disabled>
                        {t("loading")}
                      </MenuItem>
                    ) : userState.countriesList.length !== 0 &&
                      userState.statesList.length === 0 ? (
                      <MenuItem value="" disabled>
                        {t("noStateF")}
                      </MenuItem>
                    ) : userState.statesList.length === 0 ? (
                      <MenuItem value="" disabled>
                        {t("pleaseSelectCountry")}
                      </MenuItem>
                    ) : (
                      getStateNameOptions()
                    )}
                  </Select>
                  {showInputError(countryStateError) && (
                    <FormHelperText error={showInputError(countryStateError)}>
                      {countryStateError}
                    </FormHelperText>
                  )}
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="form-field">
                  <InputLabel id="city" required>
                    {t("city")}
                  </InputLabel>
                  <Select
                    labelId="city"
                    id="citySelect"
                    name="citySelect"
                    required
                    value={city}
                    onChange={handleCityChange}
                    error={showInputError(cityError)}
                  >
                    {userState.citiesLoading ? (
                      <MenuItem value="" disabled>
                        {t("loading")}
                      </MenuItem>
                    ) : userState.statesList.length !== 0 &&
                      userState.citiesList.length === 0 ? (
                      <MenuItem value="" disabled>
                        {t("noCityF")}
                      </MenuItem>
                    ) : userState.citiesList.length === 0 ? (
                      <MenuItem value="" disabled>
                        {t("pleaseSelectState")}
                      </MenuItem>
                    ) : (
                      getCityNameOptions()
                    )}
                  </Select>
                  {showInputError(cityError) &&
                    !(
                      countryState !== "" && userState.citiesList.length === 0
                    ) && (
                      <FormHelperText error={showInputError(cityError)}>
                        {cityError}
                      </FormHelperText>
                    )}
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="form-field">
                  <InputLabel htmlFor="zipCode" required>
                    {t("zipCode")}
                  </InputLabel>
                  <Input
                    id="zipCode"
                    name="zipCode"
                    type="text"
                    value={zipCode || ""}
                    placeholder="e.g. 8896"
                    onChange={handleZipCodeChange}
                    error={showInputError(zipCodeError)}
                  />
                  {showInputError(zipCodeError) && (
                    <FormHelperText error={showInputError(zipCodeError)}>
                      {zipCodeError}
                    </FormHelperText>
                  )}
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="form-field">
                  <InputLabel htmlFor="email" required>
                    {t("email")}
                  </InputLabel>
                  <Input
                    disabled={true}
                    id="email"
                    name="email"
                    required
                    type="email"
                    value={email || ""}
                    placeholder={t("emailPlaceholder")}
                    onChange={handleEmailChange}
                    error={showInputError(emailError)}
                  />
                  {showInputError(emailError) && (
                    <FormHelperText error={showInputError(emailError)}>
                      {emailError}
                    </FormHelperText>
                  )}
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="form-field">
                  <InputLabel required>{t("phone")}</InputLabel>
                  {phoneNumber ? (
                    <PhoneInput
                      value={phoneNumber}
                      defaultCountry={!phoneNumber ? "" : "US"}
                      placeholder={t("phonePlaceholder")}
                      countryCallingCodeEditable={false}
                      withCountryCallingCode
                      international
                      onCountryChange={(e) => {
                        try {
                          phoneCode = "+" + getCountryCallingCode(e);
                        } catch (err) {}
                      }}
                      countryOptionsOrder={["US", "..."]}
                      countries={["US"]}
                      onChange={handlePhoneChange}
                    />
                  ) : (
                    <PhoneInput
                      value={phoneNumber}
                      defaultCountry={!phoneNumber ? "" : "US"}
                      placeholder={t("phonePlaceholder")}
                      countryCallingCodeEditable={false}
                      withCountryCallingCode
                      international
                      onCountryChange={(e) => {
                        try {
                          phoneCode = "+" + getCountryCallingCode(e);
                        } catch (err) {}
                      }}
                      countryOptionsOrder={["US", "..."]}
                      countries={["US"]}
                      onChange={handlePhoneChange}
                    />
                  )}

                  {showInputError(phoneNumberError) && (
                    <FormHelperText error={showInputError(phoneNumberError)}>
                      {phoneNumberError}
                    </FormHelperText>
                  )}
                </div>
              </Grid>

              <Grid
                item
                md={4}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                }}
              >
                <Button
                  color="primary"
                  onClick={goToResetPasswordScreen}
                  variant="contained"
                >
                  {t("resetPassword")}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={4}
            sm={6}
            xs={12}
            style={{ height: "50%" }}
            className="grid-photo"
          >
            <div className="view-edit-profile-photo">
              <figure>
                <Input
                  id="childImage"
                  name="childImage"
                  type="file"
                  inputProps={{ accept: "image/png, image/jpeg" }}
                  onChange={handleImageUpload}
                />

                <i className="ticon-camera">
                  {photoURL !== "" && !loaded ? (
                    <p className="loader"></p>
                  ) : null}
                </i>
                {photoURL !== "" ? (
                  <img
                    alt="Child Profile Picture"
                    src={photoURL}
                    onLoad={() => {
                      setLoaded(true);
                    }}
                    style={{ maxWidth: 200, maxHeight: 200 }}
                  />
                ) : childImage.image ? (
                  <img
                    alt="Child Profile Picture1"
                    src={childImage.image.src}
                    onLoad={() => {
                      setLoaded(true);
                    }}
                    style={{ maxWidth: 200, maxHeight: 200 }}
                  />
                ) : (
                  <img
                    alt="Child Profile Picture2"
                    onLoad={() => {
                      setLoaded(true);
                    }}
                    src={AssetConstants.profilePlaceholder}
                    style={{ maxWidth: 200, maxHeight: 200 }}
                  />
                )}
              </figure>
              {showInputError(childImageError) && (
                <FormHelperText error={showInputError(childImageError)}>
                  {childImageError}
                </FormHelperText>
              )}
            </div>
          </Grid>
        </Grid>

        {show_profile_payment?.enabled ? (
          <AccountPaymentInfo {...props} />
        ) : null}
      </form>
    </div>
  );
};

export default PersonalInformationSection;
