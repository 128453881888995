import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";
import { AppEnvironment, BuildMode } from "../../services/api/ApiClient";

export const appVersion = {
    [BuildMode.BUILD_MODE_LOCAL]: `dev_${process.env.REACT_APP_VERSION}`,
    [BuildMode.BUILD_MODE_QA]: `qa_${process.env.REACT_APP_VERSION_QA}`,
    [BuildMode.BUILD_MODE_STAGING]: `staging_${process.env.REACT_APP_VERSION_STAGING}`,
    [BuildMode.BUILD_MODE_PROD]: process.env.REACT_APP_VERSION_PROD
};

const Footer = (props) => {

    const { t } = useTranslation();

    return (
        <div className="app-footer">
            <span>© {new Date().getFullYear()} Thrive.</span>
            <Link href="mailto: support@thrivingonthespectrum.org">{t("contact")}</Link>
            <Link href="https://www.facebook.com/thrivingonthespectrum">{t("Feedback")}</Link>
            <span>v.{appVersion[AppEnvironment.buildMode] || process.env.REACT_APP_VERSION}</span>
        </div>
    );
};

export default Footer;