import { Box, makeStyles } from "@material-ui/core";
import React, { forwardRef, useState } from "react";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";

import { MessageAttachmentActionIcon } from "./MessageAttachmentActionIcon";
import { MessageTextField } from "./MessageTextField";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  messageInputContainer: {
    boxSizing: "border-box",
    // backgroundColor: ${({ theme }) =>
    //   theme.messagePanel.inputContainer.backgroundColor},
    backgroundColor: "#F9FAFC",
    // borderRadius: "5px",
    width: "100%",
    padding: "0 16px",
    display: "flex",
    gap: "10px",
    position: "relative",
    height: "80px",
  },
  buttonBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    width: "48px",
    height: "40px",
    cursor: "pointer",
  },
  emojiIcon: {
    height: "20px",
    width: "20px",
    color: "#333333",
  },
  form: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
  },
  sendMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  emojiPicker: {
    position: "absolute",
    bottom: "50px",
    right: 0,
    zIndex: 1,
    backgroundColor: "white",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
    borderRadius: "10px",
  },
}));

export const MessageInputField = forwardRef(
  (
    {
      content,
      placeholderName,
      setContent,
      sendMessage,
      setShowEmojiPicker,
      showEmojiPicker,
      onUserClickInput,
      onUserClickOutsideInput,
      // sendTypingStatus,
    },
    ref
  ) => {
    const ICON_SIZE = 36;
    const MAX_LENGTH = 2048;

    const classes = useStyles();

    const [isMultiLine, setIsMultiLine] = useState(false);
    const atMaxLength = content.length === MAX_LENGTH;

    const { attachments } = useSelector((state) => state.messagePanel);

    const isSendButtonDisabled = !attachments.length && !content.length;

    return (
      <>
        <Box
          className={classes.messageInputContainer}
          style={{ alignItems: isMultiLine ? "top" : "center" }}
        >
          <div className={classes.buttonBackground}>
            <MessageAttachmentActionIcon />
          </div>

          <div
            className={classes.buttonBackground}
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          >
            <SentimentSatisfiedOutlinedIcon
              className={classes.emojiIcon}
              cursor="pointer"
            />
          </div>

          <form onSubmit={sendMessage} className={classes.form}>
            <MessageTextField
              ref={ref}
              message={content}
              setMessage={setContent}
              maxLength={MAX_LENGTH}
              setIsMultiLine={setIsMultiLine}
              // sendTypingStatus={sendTypingStatus}
              sendMessage={sendMessage}
              onUserClickInput={onUserClickInput}
              onUserClickOutsideInput={onUserClickOutsideInput}
            />
            <button
              className={classes.sendMessage}
              style={{
                backgroundColor: isSendButtonDisabled ? "#ccc" : "#003471",
              }}
              type="submit"
              disabled={isSendButtonDisabled}
            >
              <img
                style={{ top: "2px", left: "2px", minWidth: 20, height: 20 }}
                src={require("../../../../assets/images/chat-send.png")}
                alt="Send Message"
              />
            </button>
          </form>
        </Box>
      </>
    );
  }
);
