import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { FormHelperText } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getRecipientFromConversation } from "../../helpers/GenericHelper";

const useStyles = makeStyles((theme) => ({
  labels: {
    color: "#103170",
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    /* identical to box height */

    letterSpacing: "-0.5px",
  },
  input: {
    border: "none",
    outline: "none",
    // width: "100%",
    // backgroundColor: theme.palette.background.paper,
    // "&:focus": {
    //   outline: "none",
    // },
  },
}));

export default function NewChat({
  getChatUsersOptions,
  setSelectedNewChatUser,
  selectedNewChatUserError,
  setSelectedNewChatUserError,
  newChatMessageError,
  setNewChatMessageError,
  setUserMessage,
  userMessage,
}) {
  const classes = useStyles();
  //   const [open, setOpen] = useState(showNewMessageModal);

  const conversations = useSelector(
    (state) => state.conversation.conversations
  );

  const user = useSelector((state) => state.authentication.user);
  let recipients = useSelector((state) => state.recipients.recipients);

  recipients = recipients
    .filter((rec) => {
      const found = conversations.find((conversation) => {
        const recipientTo = getRecipientFromConversation(conversation, user);
        return recipientTo?.id === rec.id;
        // return conversation.recipient_id === rec.id;
      });

      if (found) {
        return false;
      }
      return true;
    })
    .map((recipient) => {
      return {
        label: `${recipient.firstName} ${recipient.lastName}`,
        user: recipient,
      };
    });

  const handleAutocompleteChange = (event, value) => {
    setSelectedNewChatUser(value);
    setSelectedNewChatUserError(null);
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: "4px",
            borderRadius: "8px",
            border: "1px solid #E0E0E0",
            marginBottom: "30px",

          }}
        >
          <img
            alt="search"
            style={{
              width: "25px",
              height: "25px",
              alignSelf: "center",
              marginLeft: "6px",
            }}
            src={require("../../assets/images/search.svg")}
          />
          <Autocomplete
            id="combo-box-demo"
            options={recipients}
            getOptionLabel={(option) => option.label}
            style={{ width: "100%", outline: "none" }}
            onChange={handleAutocompleteChange}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  style={{ width: "100%", border: "none", outline: "none" }}
                  type="text"
                  placeholder="Chat with...."
                  {...params.inputProps}
                />
              </div>
            )}
          />
        </div>
        {selectedNewChatUserError && (
          <FormHelperText error={selectedNewChatUserError}>
            {selectedNewChatUserError}
          </FormHelperText>
        )}
      </div>
    </>
  );
}
