import EXIF from "exif-js";
const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

function isiOSPlatform() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, fileType, rotation = 0, afterCrop) {

    const image = await createImage(imageSrc)
    EXIF.getData(image, function () {
        var allMetaData = EXIF.getAllTags(this);
        // console.log("All tags:");
        // console.log(JSON.stringify(allMetaData));
        let rotation = 0;
        if (Object.keys(allMetaData).length > 0 && allMetaData.Orientation) {
            if (allMetaData.Orientation === 8) {
                rotation = -90;
            } else if (allMetaData.Orientation === 6) {
                rotation = 90;
            } else if (allMetaData.Orientation === 3) {
                rotation = 180;
            }
        }
        if (!isiOSPlatform()) {
            rotation = 0;
        }
        // console.log("Rotation:");
        // console.log(rotation);
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        image.width = image.width / 2;
        image.height = image.height / 2;

        const maxSize = Math.max(image.width, image.height)
        // const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
        const safeArea = maxSize * 2;

        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea
        canvas.height = safeArea
        //
        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2)
        ctx.rotate(getRadianAngle(rotation))
        if (rotation === 0 || rotation === 180) {
            ctx.translate(-safeArea / 2, -safeArea / 2)
        } else {
            ctx.translate(-safeArea / 2, -safeArea / 2.667)
        }


        // draw rotated image and store data.
        // ctx.drawImage(
        //     image,
        //     safeArea / 2 - image.width * 0.5,
        //     safeArea / 2 - image.height * 0.5
        // )
        ctx.drawImage(
            image,
            safeArea / 2 - image.width,
            safeArea / 2 - image.height
        )
        const data = ctx.getImageData(0, 0, safeArea, safeArea)

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        // paste generated rotate image with correct offsets for x,y crop values.
        if (rotation === 0 || rotation === 180) {
            ctx.putImageData(
                data,
                Math.round(0 - safeArea / 2 + image.width - pixelCrop.x),
                Math.round(0 - safeArea / 2 + image.height - pixelCrop.y)
            )
        } else {
            ctx.putImageData(
                data,
                Math.round(0 - safeArea / 2 + image.width - pixelCrop.x),
                Math.round(0 - safeArea / 2.667 + image.height - pixelCrop.y)
            )
        }

        // As Base64 string
        //return canvas.toDataURL('image/jpeg');

        // As a blob
        // return new Promise(resolve => {
        //     canvas.toBlob(file => {
        //         resolve(URL.createObjectURL(file))
        //     }, 'image/jpeg')
        // })
        new Promise(resolve => {
            canvas.toBlob(file => {
                afterCrop(file)
            }, fileType ? fileType : "image/jpeg")
        });
    });
}
