import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import DescriptionIcon from "@material-ui/icons/Description";
import { Typography } from "@material-ui/core";

import { getChatImageURL } from "../../../../../store/actions/Chat/Messages";
import styles from "./index.module.scss";

const MessageItemAttachment = ({ attachment, onClick }) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (attachment && attachment?.url?.includes(".")) {
      dispatch(getChatImageURL(attachment.url, onGetUrl, onFailedUrl));
    } else {
      setLoaded(true);
    }
  }, []);

  const onGetUrl = (res) => {
    setFile(res.data.url);
  };

  const onFailedUrl = (err) => {
    console.log(err);
  };

  if (attachment.type.startsWith("application")) {
    return (
      <div
        onClick={() => window.open(file)}
        className={styles.attachment_document}
      >
        <DescriptionIcon />
        <Typography>{attachment?.url}</Typography>
      </div>
    );
  }

  if (attachment.type.startsWith("video")) {
    return (
      <video key={file} width={200} controls>
        <source src={file} alt="Video Attachment" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }

  if (attachment.type.startsWith("audio")) {
    return (
      <audio key={file} width={200} controls>
        <source src={file} alt="Audio Attachment" type={attachment.type} />
        Your browser does not support the audio tag.
      </audio>
    );
  }

  return (
    <img
      loading="lazy"
      src={file}
      width={150}
      alt={file}
      onClick={() => onClick(file)}
      style={{ cursor: "pointer" }}
    />
  );
};

export default MessageItemAttachment;
