import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

export default function DeleteGroupModal({ id, showModal, setShowModal, onDelete }) {
  const { t } = useTranslation();

  return (
    <Dialog
      className="logout-box"
      maxWidth="xs"
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseIcon className="popup-close" onClick={() => setShowModal(false)} />
      <DialogTitle id="alert-dialog-title">
        {t("deleteGroupPopupMessage")}
      </DialogTitle>
      <DialogActions>
        <Button
          className="no-button-text"
          variant="contained"
          onClick={() => setShowModal(false)}
        >
          {t("no")}
        </Button>
        <Button
          variant="contained"
          onClick={() => onDelete(id)}
          color="primary"
        >
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
