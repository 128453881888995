import React from "react";
import {
  getTimeLabelByValue,
  extractColorFromSchdule,
  getActivityBackgroundColor,
} from "../../helpers/GenericHelper";
import { CALENDAR_VIEW } from "../../constants/GenericConstants";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

/**
 * Scheduled Activity component
 */
function formatTime(timeString) {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}
const ScheduledActivityItem = ({
  event,
  removeActivity,
  displayPreviewMode,
  editAcc,
}) => {
  const { activity, view } = event;
  let actvityItem = <></>;

  const getTimeLabelForMonth = (timeString) => {
    // timeString : "01:30:00"
    return formatTime(timeString);
  };

  switch (view) {
    case CALENDAR_VIEW.DAY:
      actvityItem = (
        <div
          className={!editAcc.current ? "Mui-disabled" : "rbc-off-range-bg"}
          style={{
            backgroundColor: getActivityBackgroundColor(
              extractColorFromSchdule(activity.activityColor)
            ),
            borderLeftStyle: "solid",
            borderLeftWidth: 6,
            borderLeftColor: extractColorFromSchdule(activity.activityColor),
          }}
          onClick={() => displayPreviewMode(event)}
        >
          <span>
            <img
              alt={activity.activityName}
              src={activity.activityImage}
              style={{ height: 40, width: 40 }}
            />
          </span>
          <span
            style={{ color: extractColorFromSchdule(activity.activityColor) }}
          >
            {activity.activityName}
          </span>
          <CancelRoundedIcon
            onClick={() => editAcc.current && removeActivity(event)}
          />
        </div>
      );
      break;

    case CALENDAR_VIEW.WEEK:
      actvityItem = (
        <div
          className={
            !editAcc.current ? "Mui-disabled" : "rbc-off-range-bg week-layout"
          }
          style={{
            backgroundColor: getActivityBackgroundColor(
              extractColorFromSchdule(activity.activityColor)
            ),
          }}
          onClick={() => displayPreviewMode(event)}
        >
          <span
            style={{
              color: extractColorFromSchdule(activity.activityColor),
              backgroundColor: "transparent",
            }}
          >
            {activity.activityName}
          </span>
          {/* <CancelRoundedIcon onClick={() => editAcc.current && removeActivity(event)} /> */}
        </div>
      );
      break;

    case CALENDAR_VIEW.MONTH:
      actvityItem = (
        <div
          className={
            !editAcc.current ? "Mui-disabled" : "rbc-off-range-bg month-layout"
          }
          style={{
            color: getActivityBackgroundColor(
              extractColorFromSchdule(activity.activityColor)
            ),
          }}
          onClick={() => displayPreviewMode(event)}
        >
          <span
            className="time"
            style={{ color: extractColorFromSchdule(activity.activityColor) }}
          >
            {getTimeLabelForMonth(activity.startTime)}
          </span>
          <span
            style={{ color: extractColorFromSchdule(activity.activityColor) }}
          >
            {" " + activity.activityName}
          </span>
        </div>
      );
      break;

    case CALENDAR_VIEW.AGENDA:
      actvityItem = (
        <div
          className={
            !editAcc.current
              ? "Mui-disabled"
              : "rbc-off-range-bg schedule-layout"
          }
          style={{
            color: getActivityBackgroundColor(
              extractColorFromSchdule(activity.activityColor)
            ),
            display: "flex",
          }}
          onClick={() => displayPreviewMode(event)}
        >
          <span style={{ color: "gray" }}>
            {getTimeLabelByValue(activity.startTime) +
              " - " +
              getTimeLabelByValue(activity.endTime + " ")}
          </span>
          <div
            style={{
              color: extractColorFromSchdule(activity.activityColor),
              marginLeft: "18px",
            }}
          >
            {" " + activity.activityName}
          </div>
        </div>
      );
      break;

  default:
    actvityItem = <></>;
    break;
  }
  return actvityItem;
};

export default ScheduledActivityItem;
