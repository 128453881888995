import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import showUsersIcons from "../../assets/images/Path.png";
import hideUsersIcons from "../../assets/images/play-button.png";
import { GroupSidebarItem } from "../../screens/ChatScreen/components/groups/GroupSidebarItem";
import { ConversationSidebarItem } from "../../screens/ChatScreen/components/conversation/ConversationSidebarItem";
import { ConversationSidebar } from "../../screens/ChatScreen/ConversationSidebar";

const useStyles = makeStyles((theme) => ({
  chatListContainer: {
    overflowY: "auto",
    maxHeight: "280px",
    height: "auto",
    // height: "282px",
    // maxHeight: "30vh",
    // maxHeight: "90%",
  },
  chatGroupsContainer: {
    overflowY: "auto",
    // height: "228px",
    height: "28vh",
    // maxHeight: "90%",
  },
  createGroupPlaceholder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
}));

const ChatUsersList = ({
  setShowNewMessageModal,
  chatList = [],
  setModalType,
  groupList = [],
  search,
  handleSearch = () => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showChat, setShowChat] = useState(true);
  const [showGroup, setShowGroup] = useState(true);

  const getTransformedTitle = (group) => {
    const MAX_TITLE_LENGTH = 20;

    if (!group.title) {
      const usersToString = group?.users
        ?.map((user) => user.firstName)
        .join(", ");
      return usersToString.length > MAX_TITLE_LENGTH
        ? usersToString.slice(0, MAX_TITLE_LENGTH).concat("...")
        : usersToString;
    }
    return group.title.length > MAX_TITLE_LENGTH
      ? group.title.slice(0, MAX_TITLE_LENGTH).concat("...")
      : group.title;
  };

  const convertTimestamp = (timestamp) => {
    const diff = (new Date().getTime() - new Date(timestamp).getTime()) / 1000;
    const units = [
      { label: "years", amount: 365 * 24 * 60 * 60 },
      { label: "months", amount: 30 * 24 * 60 * 60 },
      { label: "days", amount: 24 * 60 * 60 },
      { label: "hours", amount: 60 * 60 },
      { label: "minutes", amount: 60 },
    ];

    for (const { label, amount } of units) {
      if (diff >= amount) {
        return `${Math.floor(diff / amount)} ${label} ago`;
      }
    }
    return "seconds ago";
  };

  const showNewMessageModal = (modalType) => {
    setModalType(modalType);
    setShowNewMessageModal(true);
  };

  const getConversationsData = () => {
    return chatList?.map((data) => {
      const recipient = data.recipient;
      return {
        ...data,
        name: `${recipient?.firstName || ""} ${recipient?.lastName || ""}`,
        image: recipient?.photo,
        lastChatted: convertTimestamp(data.lastMessageSentAt),
        id: data.id,
      };
    });
  };

  return (
    <>
      {/* TODO: can render new conversation/group modal here */}

      <div
        style={{
          width: "100%",
          backgroundColor: "#F9FAFC",
          padding: "5px",
          height: "65px",
          flex: "none",
          display: "flex",
          alignItems: "center",
          borderRadius: "6px 0 0 0",
          borderBottom: "1px solid #0000001a",
          borderRight: "1px solid #0000001a",
        }}
      >
        <p
          style={{
            fontWeight: "400",
            fontSize: "24px",
            fontStyle: "normal",
            color: "#003471",
            lineHeight: "24px",
            marginLeft: "15px",
          }}
        >
          Messages
        </p>
      </div>

      <div className="chatWindowList">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F8F8F8",
              width: "90%",
              padding: "4px",
              borderRadius: "8px",
              marginTop: "18px",
              marginBottom: "6px",
            }}
          >
            <img
              style={{
                width: "25px",
                height: "25px",
                alignSelf: "center",
                marginLeft: "6px",
              }}
              src={require("../../assets/images/search.svg")}
              alt="search"
            />
            <input
              type="search"
              placeholder="Find people, groups"
              value={search}
              onChange={handleSearch}
              style={{
                width: "100%",
                // height: "60%",
                marginLeft: "2px",
                backgroundColor: "#F8F8F8",
                border: "0px",
                alignSelf: "center",
                fontSize: "14px",
                fontFamily: "OpenSans",
                padding: "9px 6px",
                outline: "none",
              }}
            />
          </div>
        </div>

        <div className="chatListWrapper">
          <ul className="chat" id="chatList">
            <div
              style={{
                display: "flex",
                // marginTop: "20px",
                marginBottom: "10px",
                paddingLeft: "15px",
                paddingRight: "15px",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              <button
                style={{ border: "none", backgroundColor: "white" }}
                onClick={() => setShowChat(!showChat)}
              >
                <img
                  alt="collapse"
                  src={showChat ? showUsersIcons : hideUsersIcons}
                  style={{ marginTop: "5px", width: 8, height: 8 }}
                />
              </button>
              <p
                style={{
                  marginLeft: "8px",
                  fontWeight: "600",
                  fontSize: "18px",
                  fontFamily: "OpenSans",
                }}
              >
                Chat
              </p>
              <img
                style={{
                  marginTop: "2px",
                  width: 20,
                  height: 20,
                  marginLeft: "242px",
                  cursor: "pointer",
                }}
                src={require("../../assets/images/chat-new.png")}
                onClick={() => showNewMessageModal("one-to-one")}
                alt="create-new-chat"
              />
            </div>
            <div
              className={classes.chatListContainer}
              style={{
                display: showChat ? "" : "none",
                // height: showGroup ? "" : "60vh",
              }}
            >
              {showChat &&
                getConversationsData().map((conversation) => {
                  return (
                    <ConversationSidebarItem
                      key={conversation.id}
                      conversation={conversation}
                    />
                  );
                })}
              {showChat && getConversationsData().length === 0 && (
                <div className={classes.createGroupPlaceholder}>
                  <p>Start a conversation</p>
                </div>
              )}
            </div>
          </ul>

          <ul className="chat" id="chatList">
            <div
              style={{
                display: "flex",
                // marginTop: "25px",
                marginBottom: "10px",
                paddingLeft: "15px",
                // paddingRight: "15px",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              <button
                style={{ border: "none", backgroundColor: "white" }}
                onClick={() => setShowGroup(!showGroup)}
              >
                <img
                  alt="collapse"
                  src={showGroup ? showUsersIcons : hideUsersIcons}
                  style={{ marginTop: "5px", width: 8, height: 8 }}
                />
              </button>
              <p
                style={{
                  marginLeft: "8px",
                  fontWeight: "600",
                  fontSize: "18px",
                  fontFamily: "OpenSans",
                }}
              >
                Groups
              </p>
              <img
                style={{
                  marginTop: "2px",
                  width: 20,
                  height: 20,
                  marginLeft: "218px",
                  float: "right",
                  cursor: "pointer",
                }}
                src={require("../../assets/images/chat-add.png")}
                alt="add chat"
                onClick={() => showNewMessageModal("group")}
              />
            </div>
            <div
              className={classes.chatGroupsContainer}
              style={{
                display: showGroup ? "" : "none",
                height: "auto",
                overflowY: "none",
                // height: showChat ? "" : "60vh",
                // maxHeight: "33vh",
                // minHeight: "20vh",
              }}
            >
              {showGroup &&
                groupList.map((group) => {
                  return <GroupSidebarItem key={group.id} group={group} />;
                })}

              {showGroup && groupList.length === 0 && (
                <div className={classes.createGroupPlaceholder}>
                  <p>Create a group</p>
                </div>
              )}
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ChatUsersList;
