import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { getRecipientFromConversation } from "../../../../helpers/GenericHelper";
import { StyledBadge } from "../groups/style";
import MessageUnreadIcon from "./MessageUnreadIcon";
import { conversationMarkedAsRead } from "../../../../store/actions/Chat/Conversations";

const useStyles = makeStyles((theme) => ({
  listItem: {
    "&:hover $icon": {
      display: "inline-flex",
    },
  },
  icon: {
    display: "none",
  },
  text: {
    fontFamily: "OpenSans",
    fontStyle: "normal",
    color: "#333",
  },
  primaryText: {
    fontSize: "14px",
    fontWeight: 600,
  },
  secondaryText: {
    fontSize: "12px",
    fontWeight: 400,
  },
  lastMessageTime: {
    fontSize: "10px",
  },
}));

export const ConversationSidebarItem = ({ conversation }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();

  const MESSAGE_LENGTH_MAX = 50;

  const user = useSelector((state) => state.authentication.user);

  const recipient = getRecipientFromConversation(conversation, user);

  // const lastMessageContent = () => {
  //   const { lastMessageSent } = conversation;
  //   if (lastMessageSent && lastMessageSent.content)
  //     return lastMessageSent.content?.length >= MESSAGE_LENGTH_MAX
  //       ? lastMessageSent.content?.slice(0, MESSAGE_LENGTH_MAX).concat("...")
  //       : lastMessageSent.content;
  //   return null;
  // };

  const handleClick = () => {
    history.push({ pathname: `/chat/${conversation.id}` });

    dispatch(conversationMarkedAsRead(conversation.id));
  };

  const hasProfilePicture = () => recipient?.photo;

  const recipientName = () => {
    if (!recipient && conversation?.isRecipientDeleted) {
      return t("deletedUser");
    }

    return `${recipient?.firstName} ${recipient?.lastName}`;
  };

  const unReadCount = conversation?.userConversations?.find(
    (con) => con?.user_id === user?.id
  );

  return (
    <List component="div" disablePadding>
      <ListItem
        className={classes.listItem}
        button
        // selected={conversationId === conversation.id}
        selected={
          history.location.pathname.split("/").pop() === conversation.id
        }
        onClick={handleClick}
      >
        <ListItemAvatar>
          {/* <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            variant="dot"
          > */}
          <Avatar
            alt={recipientName()}
            src={hasProfilePicture() || recipient?.photo}
          />
          {/* </StyledBadge> */}
        </ListItemAvatar>
        <ListItemText
          id={conversation.id}
          disableTypography={true}
          primary={
            <Typography className={`${classes.text} ${classes.primaryText}`}>
              {recipientName()}
            </Typography>
          }
          secondary={
            <Typography
              variant="subtitle1"
              className={`${classes.text} ${classes.secondaryText}`}
            >
              {`Chatted ${moment(conversation?.lastMessageSentAt).fromNow()}`}
            </Typography>
          }
        />

        <ListItemSecondaryAction>
          <MessageUnreadIcon label={unReadCount?.unreadCount} />

          <Typography
            variant="h6"
            className={`${classes.text}, ${classes.lastMessageTime}`}
          >
            {moment(conversation?.lastMessageSentAt).format("HH:mm")}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};
