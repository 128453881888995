import { request } from "..";
import { MESSAGE_PANEL } from "../../../constants/ActionConstants";

export const addAttachment = (payload) => {
  return request(MESSAGE_PANEL.ADD_ATTACHMENT, payload);
};

export const removeAttachment = (payload) => {
  return request(MESSAGE_PANEL.REMOVE_ATTACHMENT, payload);
};

export const incrementAttachmentCounter = () => {
  return request(MESSAGE_PANEL.INCREMENT_ATTACHMENT_COUNTER, {});
};

export const removeAllAttachments = () => {
  return request(MESSAGE_PANEL.REMOVE_ALL_ATTACHMENTS, {});
};
