import React, { useState, useEffect } from "react";
import { Box, ListItemIcon, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import { useTranslation } from "react-i18next";

import GroupRecipientsModal from "../../modals/groupRecipients/GroupRecipientModal";
import AddGroupRecipientModal from "../../modals/groupRecipients/AddGroupRecipientModal";

import {
  isGroupOwner,
  selectGroupById,
} from "../../../../../helpers/GenericHelper";

import styles from "./index.module.scss";
import ChatIcon from "../../icons/ChatIcon";
import DeleteGroupModal from "../../modals/DeleteGroupModal";
import useFeatureFlags from "../../../../../hooks/useFeatureFlags";
import SearchInput from "./SearchInput";
import Settings from "./Settings";
import {
  deleteGroup,
  fetchGroupMessages,
} from "../../../../../store/actions/Chat/Group";
import Loader from "../../../../../components/Loader/Loader";
import useDebounce from "../../../../../hooks/useDebounce";
import { setGroupMessageSearchTerm } from "../../../../../store/actions/Chat/GroupMessages";


const useStyles = makeStyles(() => ({
  iconRoot: {
    minWidth: "30px",
    color: "rgba(255, 0, 0, 0.7)",
  },
}));

export const MessagePanelGroupHeader = () => {
  const classes=useStyles();
  const { add_group_recipient_modal_icon } = useFeatureFlags();

  const { id: groupId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const {t}=useTranslation();

  const [showGroupRecipientsModal, setShowGroupRecipientsModal] =
    useState(false);
  const [showAddRecipientModal, setShowAddRecipientModal] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { loading } = useSelector((state) => state.groups);
  const group = useSelector((state) => selectGroupById(state, groupId));
  const user = useSelector((state) => state.authentication.user);

  const debounceSearch = useDebounce(searchValue);

  const isOwner = isGroupOwner(user, group);

  useEffect(() => {
    dispatch(setGroupMessageSearchTerm(debounceSearch));
    // dispatch(fetchGroupMessages(groupId, 1, debounceSearch));
  }, [dispatch, groupId, debounceSearch]);

  const handleDelete = async (id) => {
    setShowDeletePopup(false);
    await dispatch(deleteGroup(id, history));
  };

  return (
    <>
      {loading ? <Loader /> : null}

      {showAddRecipientModal && (
        <AddGroupRecipientModal
          showModal={showAddRecipientModal}
          setShowModal={setShowAddRecipientModal}
          recipients={group?.users || []}
        />
      )}

      {showGroupRecipientsModal && (
        <GroupRecipientsModal
          showModal={showGroupRecipientsModal}
          setShowModal={setShowGroupRecipientsModal}
          recipients={group?.users || []}
        />
      )}

      {showDeletePopup && groupId && (
        <DeleteGroupModal
          id={groupId}
          showModal={showDeletePopup}
          setShowModal={setShowDeletePopup}
          onDelete={handleDelete}
        />
      )}

      <header className={styles.messagePanelHeaderStyle}>
        <Box
          component={"div"}
          style={{ cursor: "pointer" }}
          onClick={() => setShowGroupRecipientsModal(true)}
        >
          <Typography className={styles.username}>
            {group?.title || "Group"}
          </Typography>
        </Box>

        <Box component={"div"} className={styles.messagePanelHeaderIcons}>
          <SearchInput
            placeholder="Search messages"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />

          {add_group_recipient_modal_icon?.enabled ? (
            <>
              <ChatIcon onClick={() => setShowAddRecipientModal(true)}>
                <img
                  alt="Add Recipient"
                  src={require("../../../../../assets/images/chat-space-new.png")}
                />
              </ChatIcon>
            </>
          ) : null}

          {isOwner ? (
             <Settings>
             <Settings.Menu onClick={() => setShowDeletePopup(true)}>
               <ListItemIcon
                 classes={{
                   root: classes.iconRoot,
                 }}
               >
                 <DeleteSweepIcon fontSize="medium" />
               </ListItemIcon>
               <Typography variant="inherit" className={styles.iconText}>
                 {t("deleteGroup")}
               </Typography>
             </Settings.Menu>
           </Settings>
          ) : null}
        </Box>
      </header>
    </>
  );
  // return (
  //   <>
  //     {showModal && (
  //       <AddGroupRecipientModal
  //         showModal={showModal}
  //         setShowModal={setShowModal}
  //       />
  //     )}
  //     <MessagePanelHeaderStyle>
  //       <div>
  //         <span>{group?.title || 'Group'}</span>
  //       </div>
  //       <MessagePanelHeaderIcons>
  //         {user?.id === group?.owner?.id && (
  //           <PersonAdd
  //             cursor="pointer"
  //             size={30}
  //             onClick={() => setShowModal(true)}
  //           />
  //         )}
  //         <PeopleGroup
  //           cursor="pointer"
  //           size={30}
  //           onClick={() => dispatch(toggleSidebar())}
  //         />
  //       </MessagePanelHeaderIcons>
  //     </MessagePanelHeaderStyle>
  //   </>
  // );
};

MessagePanelGroupHeader.propTypes = {
  isOwner: PropTypes.bool,
};
