import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import RouteConstants from "../../constants/RouteConstants";
import AssetConstants from "../../constants/AssetConstants";
import { confirmEmail } from '../../store/actions/Authentication';
import Loader from '../../components/Loader/Loader';
import Footer from "../../components/Footer/Footer";
import PageNotFound from "../../components/PageNotFound/PageNotFound";
import { AppEnvironment } from "../../services/api/ApiClient";

const ConfirmEmailScreen = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.authentication);

    const [accountActivated, setAccountActivated] = useState(false);
    const [invalidURL, setInvalidURL] = useState(false);
    const [apiError, setApiError] = useState("");

    const onActivationSuccess = () => {
        setAccountActivated(true);
        // alert("success")
        // history.push({ pathname: RouteConstants.PAYMENT });
    }

    const onActivationFailure = (errorMessage) => {
        setAccountActivated(false);
        setApiError(errorMessage);
    }

    useEffect(() => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        var code = params.get('confirmationCode');

        if (code) {
            dispatch(confirmEmail(code, onActivationSuccess, onActivationFailure));
        } else {
            setInvalidURL(true);
        }
    }, []);

    const moveToLoginScreen = () => {
        localStorage.clear();
        
        const REDIRECT_URL = `https://thriveonthespectrum.page.link/?link=${AppEnvironment.config().webURL}`;
        const redirect_to_login = `${REDIRECT_URL}/%23/login`;
        
        window.location.assign(redirect_to_login)
    }

    return (
        <div className="is-login">
            <Grid container>
                <Grid item sm={6} className="login-form">
                    <div className="congrats-msg-block">
                        <div className="congrats-msg-container">
                            <img className="app-logo" src={AssetConstants.logo} />
                            {userState.loading && <Loader />}
                            {
                                invalidURL ?
                                    <PageNotFound /> :
                                    (
                                        !userState.loading && accountActivated ?
                                            <div className="congrats-msg">
                                                <h1>{t("congratulations")}</h1>
                                                <p>{t("accountActivated")}</p>
                                                <img src={AssetConstants.congratscheck} />
                                            </div> :
                                            <div>
                                                {apiError && <PageNotFound />}
                                            </div>
                                    )
                            }
                            <Button color="primary" variant="contained" disableElevation onClick={moveToLoginScreen}>{t("goToLogin")}</Button>
                        </div>
                        <div className="conrats-msg-footer">
                            <Footer />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={6} className="screen-half-splash">

                </Grid>
            </Grid>
        </div>
    );
};

export default ConfirmEmailScreen;