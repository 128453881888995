import React, { useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";
import {
  GENERIC_STATUS,
  LOCAL_STORAGE_KEYS,
  ROLE_TYPES,
} from "../../constants/GenericConstants";
import { useSelector, useDispatch } from "react-redux";
import { getNotifications } from "../../store/actions/Notifications";
import { hasValidPayment, parseValue } from "../../helpers/GenericHelper";
import { SocketContext } from "../../context/SocketContext";

export const PrivatePaymentValidRoute = ({ component: Component, ...rest }) => {
  const { setToken } = useContext(SocketContext);

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.authentication);
  const childState = useSelector((state) => state.child);

  const ACCESS_TOKEN = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

  useEffect(() => {
    if (ACCESS_TOKEN) {
      setToken(ACCESS_TOKEN);
    }
  }, [ACCESS_TOKEN, setToken]);

  useEffect(() => {
    dispatch(getNotifications(1, () => {}));
  }, [Component]);

  const isParentChild = (props) => {
    const path = props.match.path;
    if (path === RouteConstants.IMAGE_LIBRARY) return false;
    const params = props.match.params;
    if (Object.entries(params).length > 0) {
      if (params.childId) {
        const parentChildren = childState.childrenList.filter(
          (child) => child.childId === params.childId
        );
        if (parentChildren.length > 0) return true;
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const getParentStatus = () => {
    if (userState.user && Object.entries(userState.user).length > 0) {
      const parentArray = userState.user.accounts.filter(
        (account) => account.role === ROLE_TYPES.PARENT
      );
      if (parentArray.length > 0) {
        return parentArray[0].status;
      }
      return GENERIC_STATUS.ACTIVE;
    }
    return GENERIC_STATUS.ACTIVE;
  };

  const isExUserChild = (props) => {
    const params = props.match.params;
    if (Object.entries(params).length > 0) {
      if (params.childId) {
        for (let i = 0; i < childState.otherChildrenList.length; i++) {
          for (
            let j = 0;
            j < childState.otherChildrenList[i].childProfiles.length;
            j++
          ) {
            const currentChild =
              childState.otherChildrenList[i].childProfiles[j];
            if (currentChild.childId === params.childId) {
              return true;
            }
          }
        }
        return false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isExUserActiveChild = (props) => {
    const params = props.match.params;
    if (Object.entries(params).length > 0) {
      if (params.childId) {
        for (let i = 0; i < childState.otherChildrenList.length; i++) {
          for (
            let j = 0;
            j < childState.otherChildrenList[i].childProfiles.length;
            j++
          ) {
            const currentChild =
              childState.otherChildrenList[i].childProfiles[j];
            if (currentChild.childId === params.childId) {
              if (childState.otherChildrenList[i].isActive) {
                return true;
              } else {
                return false;
              }
            }
          }
        }
        return false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getRoute = (props) => {
    if (
      userState.isParent &&
      getParentStatus() === GENERIC_STATUS.DISABLE &&
      isParentChild(props)
    ) {
      return (
        <Redirect
          to={{
            pathname: RouteConstants.DASHBOARD,
            state: { from: props.location },
          }}
        />
      );
    } else if (
      userState.isParent &&
      hasValidPayment(userState.user) &&
      isParentChild(props)
    ) {
      return <Component {...props} {...rest} />;
    } else if (
      userState.isParent &&
      !hasValidPayment(userState.user) &&
      isParentChild(props)
    ) {
      return (
        <Redirect
          to={{
            pathname: RouteConstants.PAYMENT,
            state: { from: props.location },
          }}
        />
      );
    } else if (
      userState.isExternalUser &&
      isExUserChild(props) &&
      isExUserActiveChild(props)
    ) {
      return <Component {...props} {...rest} />;
    } else if (
      userState.isExternalUser &&
      isExUserChild(props) &&
      !isExUserActiveChild(props)
    ) {
      return (
        <Redirect
          to={{
            pathname: RouteConstants.DASHBOARD,
            state: { from: props.location },
          }}
        />
      );
    } else {
      return <Component {...props} {...rest} />;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN) &&
          localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
          localStorage.getItem(LOCAL_STORAGE_KEYS.USER) ? (
          getRoute(props)
        ) : (
          <Redirect
            to={{
              pathname: RouteConstants.LOGIN,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
