import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Box, ListItemIcon, makeStyles, Typography } from "@material-ui/core";

import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import { useTranslation } from "react-i18next";
import {
  getRecipientFromConversation,
  setFormattedDate,
} from "../../../../../helpers/GenericHelper";
import {
  deleteConversation,
  selectConversationById,
} from "../../../../../store/actions/Chat/Conversations";
import SearchInput from "./SearchInput";
import DeleteConversationModal from "../../modals/DeleteConversationModal";
import Settings from "./Settings";

import styles from "./index.module.scss";
import Loader from "../../../../../components/Loader/Loader";
import { fetchMessages } from "../../../../../store/actions/Chat";
import useDebounce from "../../../../../hooks/useDebounce";
import { setSearchTerm } from "../../../../../store/actions/Chat/Messages";

const useStyles = makeStyles(() => ({
  iconRoot: {
    minWidth: "30px",
    color: "rgba(255, 0, 0, 0.7)",
  },
}));

export const MessagePanelConversationHeader = () => {
  const classes = useStyles();

  const { id: conversationId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const debounceSearch = useDebounce(searchValue);

  useEffect(() => {
    dispatch(setSearchTerm(debounceSearch));
    // dispatch(fetchMessages(conversationId, 1, debounceSearch));
  }, [dispatch, conversationId, debounceSearch]);

  //TODO:
  // const conversation = useSelector((state) =>
  //   state.chat.conversationList.find((con) => con.id === id)
  // );
  const { loading } = useSelector((state) => state.conversation);
  const conversation = useSelector((state) =>
    selectConversationById(state, conversationId)
  );
  const user = useSelector((state) => state.authentication.user);

  const handleDelete = async (id) => {
    setShowDeletePopup(false);
    await dispatch(deleteConversation(id, history));
  };

  const recipient = getRecipientFromConversation(conversation, user);

  const username = `${recipient?.firstName || ""} ${recipient?.lastName || ""}`;

  return (
    <>
      {loading ? <Loader /> : null}

      {showDeletePopup && conversationId && (
        <DeleteConversationModal
          id={conversationId}
          showModal={showDeletePopup}
          setShowModal={setShowDeletePopup}
          onDelete={handleDelete}
        />
      )}
      <header className={styles.messagePanelHeaderStyle}>
        <Box component={"div"}>
          <p className={styles.username}>{username || "User"}</p>
          <p>
            {conversation?.lastMessageSentAt
              ? "Last time, " +
                setFormattedDate(conversation?.lastMessageSentAt)
              : ""}
          </p>
        </Box>

        <Box component={"div"} className={styles.messagePanelHeaderIcons}>
          <SearchInput
            placeholder="Search messages"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />

          <Settings>
            <Settings.Menu onClick={() => setShowDeletePopup(true)}>
              <ListItemIcon
                classes={{
                  root: classes.iconRoot,
                }}
              >
                <DeleteSweepIcon fontSize="medium" />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.iconText}>
                {t("deleteConversation")}
              </Typography>
            </Settings.Menu>
          </Settings>
        </Box>
      </header>
    </>
  );
};
