import React from "react";
import Carousel from "flat-carousel";
import { Grid } from "@material-ui/core";

const images = [
    {
      src:
        "Onboarding1.png"
    },
    {
      src:
        "Onboarding2.png"
    },
    {
      src:
        "Onboarding3.gif"
    },
    {
      src:
        "Onboarding5.png"
    }
  ];

  const imageText = [
    {
      header: "Plan with confidence",
      text: "Visually plan your schedule to give you the confidence to successfully tackle your day."},
    {
      header: "Improve accountability", 
      text: "Notifications and Reminders will keep you on track by alerting you of time sensitive tasks or activities."},
    {
      header: "Manage tasks efficiently", 
      text: "Structure your routines so you can complete your tasks more efficiently."},
    { 
      header: "Create strategies to get back on track",
      text: "We often cannot control the things that life throws our way, but we can learn to control our response.  Upload a Coping Strategy or Relaxation Technique to help you throughout your day."},
  ]

const OnboardingCarousel = (props) => {
    return <Grid item sm={6} className="imageSlider">
        <Carousel>
            {images.map((image, index) => (
                <div
                key={index}
                style={{ backgroundImage: "url(" + image.src + ")" }}
                >
                  <h1>{imageText[index].header}</h1>
                  <p>{imageText[index].text}</p>
                </div>
            ))}
        </Carousel>
    </Grid>
}

export default OnboardingCarousel;