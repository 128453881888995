import { Box, makeStyles } from "@material-ui/core";
import EmojiPicker from "emoji-picker-react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import imageCompression from "browser-image-compression";

import Loader from "../../../../components/Loader/Loader";
import {
  getRecipientFromConversation,
  getTimeInMS,
  selectGroupById,
} from "../../../../helpers/GenericHelper";
import useToast from "../../../../hooks/useToast";
import ChatService from "../../../../services/api/ChatService";
import { createNewMessage } from "../../../../store/actions/Chat";
import {
  conversationMarkedAsRead,
  selectConversationById,
} from "../../../../store/actions/Chat/Conversations";
import { groupMarkedAsRead } from "../../../../store/actions/Chat/Group";
import { removeAllAttachments } from "../../../../store/actions/Chat/MessagePanel";
import {
  getChatPresignedURL,
  uploadChatImage,
} from "../../../../store/actions/Chat/Messages";
import { MessageAttachmentContainer } from "./attachments/MessageAttachmentContainer";
import MessageContainer from "./MessageContainer";
import { MessageInputField } from "./MessageInputField";
import { MessagePanelHeader } from "./MessagePanelHeader";

const useStyles = makeStyles(() => ({
  messagePanelStyle: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
  },

  messagePanelBody: {
    // padding: "32px 32px 0 32px",
    paddingTop: 0,
    boxSizing: "border-box",
    flex: "1 1 auto",
    overflowY: "auto",
    minHeight: 0,
    height: "1px",
    // height: "calc(100% - 600px)",
  },

  messagePanelFooter: {
    // padding: "0 32px 10px 32px",
    marginTop: 0,
  },
}));

export const MessagePanel = ({ sendTypingStatus, isRecipientTyping }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id: conversationId } = useParams();
  const dispatch = useDispatch();
  const { error } = useToast();

  const bottomRef = useRef();
  const inputRef = useRef();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [content, setContent] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const user = useSelector((state) => state.authentication.user);

  const { attachments } = useSelector((state) => state.messagePanel);

  //TODO:
  // const conversation = useSelector((state) =>
  //   state.chat.conversationList.find((con) => con.id === conversationId)
  // );
  const conversation = useSelector((state) =>
    selectConversationById(state, conversationId)
  );
  const group = useSelector((state) => selectGroupById(state, conversationId));

  const selectedType = useSelector(
    (state) => state.selectedConversationType.type
  );

  const recipient = getRecipientFromConversation(conversation, user);

  const isRecipientDeletedOrDisabled =
    !recipient && conversation?.isRecipientDeleted;

  useEffect(() => {
    return () => {
      //   dispatch(clearAllMessages());
      dispatch(removeAllAttachments());
    };
  }, [dispatch]);

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 5,
      // maxWidthOrHeight: 200,
      useWebWorker: true,
      initialQuality: 0.5,
      alwaysKeepResolution: true, // reduce quality
    };

    if (imageFile) {
      imageCompression(imageFile, options)
        .then(async function (compressedFile) {
          // let compressedFileTemp = compressedFile;
          // compressedFileTemp.name = getTimeInMS(compressedFileTemp.name);

          const convertBlobToFile = new File(
            [compressedFile],
            compressedFile.name,
            {
              type: compressedFile.type,
            }
          );

          await dispatch(
            getChatPresignedURL(
              convertBlobToFile.name,
              (response) => onGetSuccess(response, convertBlobToFile),
              onFailedSendMessage
            )
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleSendMessage = async (e) => {
    // eslint-disable-next-line no-unused-expressions
    e?.preventDefault();
    // setIsSending(true);

    try {
      if (attachments.length) {
        setIsSending(true);

        const attachment = attachments[0];

        const newAttachment = new File(
          [attachment.file],
          getTimeInMS(attachment.file.name),
          { type: attachment.file.type }
        );

        if (newAttachment.type.startsWith("image")) {
          await compressImage(newAttachment);
        } else {
          await dispatch(
            getChatPresignedURL(
              newAttachment.name,
              (response) => onGetSuccess(response, newAttachment),
              onFailedSendMessage
            )
          );
        }
      } else {
        await sendMessage();
      }
    } catch (err) {
      console.log(err)
      error("something went wrong");
      setIsSending(false);
    }
  };

  const onFailedSendMessage = (err) => {
    console.log("unable to upload", err);
    error("something went wrong in uploading");
  };

  const onGetSuccess = (response, attachment) => {
    dispatch(
      uploadChatImage(
        attachment,
        response.data.url,
        sendMessage,
        onFailedSendMessage
      )
    );
  };

  const sendMessage = async (attachment) => {
    const trimmedContent = content.trim();

    if (!conversationId) {
      setIsSending(false);
      return;
    }

    if (!trimmedContent && !attachments.length) {
      setIsSending(false);
      return;
    }

    const formData = new FormData();
    formData.append("id", conversationId);

    if (recipient) {
      formData.append("recipientId", recipient.id);
    }

    trimmedContent && formData.append("content", trimmedContent);
    // attachments.forEach((attachment) =>
    //   formData.append("attachments", attachment.file)
    // );

    formData.append("attachments", attachment);

    try {
      await ChatService.createMessage(conversationId, selectedType, formData);
      setContent("");
      setShowEmojiPicker(false);
      dispatch(removeAllAttachments());
      // dispatch(clearAllMessages());

      if (bottomRef?.current) {
        bottomRef.current.scrollTop = 0;
      }
    } catch (err) {
      const axiosError = err;
      console.log({ axiosError });
      if (axiosError.response?.status === 413) {
        error(t("exceed_fileSize") + "5MB");
        return;
      }
      if (axiosError.response?.status === 404) {
        if (selectedType === "private") {
          error(t("conversation_not_found"));
          return;
        }

        if (selectedType === "group") {
          error(t("group_not_found"));
          return;
        }
      }
      error("Something went sending the message. Try again or reload the page");
    } finally {
      setIsSending(false);
    }
  };

  const handleUserClickInput = useCallback(() => {
    setInputIsFocused(true);

    if (selectedType === "private") {
      dispatch(conversationMarkedAsRead(conversationId));
    }

    if (selectedType === "group") {
      dispatch(groupMarkedAsRead(conversationId));
    }
  }, [dispatch, selectedType, conversationId]);

  const handleUserClickOutsideInput = () => {
    setInputIsFocused(false);
  };

  return (
    <>
      {isSending ? <Loader /> : null}

      <Box className={classes.messagePanelStyle}>
        <MessagePanelHeader />

        <Box className={classes.messagePanelBody}>
          <MessageContainer ref={bottomRef} />
        </Box>

        {showEmojiPicker ? (
          <EmojiPicker
            className={classes.emojiPicker}
            lazyLoadEmojis={true}
            autoFocusSearch={false}
            onEmojiClick={(emoji) => {
              setContent(content + emoji.emoji);
              if (inputRef?.current && !inputIsFocused) {
                inputRef.current.focus();
              }
            }}
          /> //TODO: check the performance
        ) : null}

        {!isRecipientDeletedOrDisabled ? (
          <Box component={"footer"} className={classes.messagePanelFooter}>
            {attachments.length > 0 && <MessageAttachmentContainer />}
            <MessageInputField
              ref={inputRef}
              content={content}
              setContent={setContent}
              sendMessage={handleSendMessage}
              onUserClickInput={handleUserClickInput}
              onUserClickOutsideInput={handleUserClickOutsideInput}
              // sendTypingStatus={sendTypingStatus}
              showEmojiPicker={showEmojiPicker}
              setShowEmojiPicker={setShowEmojiPicker}
              placeholderName={
                selectedType === "group"
                  ? group?.title || "Group"
                  : recipient?.firstName || "user"
              }
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
};
