import { GET_ALL_GROUPS_ACTIONS } from "../../../constants/ActionConstants";

const INITIAL_STATE = {
  loading: false,
  messages: [],
  meta: {},
  searchTerm: "",
  error: null,
};

const groupMessages = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_GROUPS_ACTIONS.FETCH_GROUP_MESSAGES:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_GROUPS_ACTIONS.FETCH_GROUP_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, ...action.payload.data.data],
        meta: action.payload.data.meta,
        loading: false,
      };
    case GET_ALL_GROUPS_ACTIONS.FETCH_GROUP_MESSAGES_FAILURE:
      return {
        ...state,
        messages: [],
        loading: false,
        error: action.payload,
      };

    case GET_ALL_GROUPS_ACTIONS.ADD_GROUP_MESSAGES:
      console.log(`addGroup reducer: Adding ${action.payload} to state`);
      console.log(action.payload);
      const { group, message } = action.payload;

      // const groupMessage = state.messages.find((gm) => gm.id === group.id);

      // if (groupMessage) {
      //   return {
      //     ...state,
      //     messages: [...message, ...state.messages],
      //   };
      // }

      return { ...state, messages: [message, ...state.messages] };

    case GET_ALL_GROUPS_ACTIONS.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
        messages: [], // reset message state when search input change
      };

    case GET_ALL_GROUPS_ACTIONS.RESET_GROUP_MESSAGE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default groupMessages;
