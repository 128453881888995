import React from "react";
import "./styles.scss";
import SignUp from "../../components/SignUp/SignUp";

const SignupScreen = () => {
    return (
        <div>
            <SignUp />
        </div>
    );
};

export default SignupScreen;