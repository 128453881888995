import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Switch, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import ArchiveIcon from '@material-ui/icons/Archive';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import CloseIcon from '@material-ui/icons/Close';
import AssetConstants from "../../constants/AssetConstants";
import RouteConstants from "../../constants/RouteConstants";
import { GENERIC_STATUS, ACTIVITY_TOOL_TYPE, TOOL_TYPE } from "../../constants/GenericConstants";
import { getAllTools, clearAddedTool, archiveTool, saveCurrentPage, clearCurrentPage } from "../../store/actions/SelfRegulation";
import Loader from '../../components/Loader/Loader';
import Pagination from "../Pagination/Pagination";
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";
import { allTrue, arrayOfGivenElement, atleastOneTrue, formatDate, parentAddedEntity, formatTimerNumber } from "../../helpers/GenericHelper";
import UserCell from "../UserCellListing/UserCell";

const SelfRegulation = ({ editAct }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const childState = useSelector((state) => state.child);
    const selfRegulationState = useSelector((state) => state.selfRegulation);

    const [selectedCategory, setSelectedCategory] = useState(ACTIVITY_TOOL_TYPE.ACTIVE);
    const [allChecked, setAllChecked] = useState(false);
    const [checkboxesStatus, setCheckboxesStatus] = useState([]);
    const [toolsData, setToolsData] = useState([]);

    const [toolAddedSuccess, setToolAddedSuccess] = useState(false);

    const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
    const [toolArchiveIndex, setToolArchiveIndex] = useState(-1);
    const [toolArchiveSuccess, setToolArchiveSuccess] = useState(false);
    const [multiArchiveDialogOpen, setMultiArchiveDialogOpen] = useState(false);
    const [toolArchiveMultiIndex, setToolArchiveMultiIndex] = useState([]);
    const [toolMultiArchiveSuccess, setToolMultiArchiveSuccess] = useState(false);
    const [areMultiple, setAreMultiple] = useState(false);
    const [showArchiveMessage, setShowArchiveMessage] = useState(false);


    const onSuccessFetch = (data) => {
        setCheckboxesStatus(arrayOfGivenElement(data.length, false));
        setAllChecked(false);
        setToolsData(data);
    }

    const onSuccessFetchFirst = (data) => {
        onSuccessFetch(data);
        if (selfRegulationState.toolAdded) {
            setToolAddedSuccess(true);
            dispatch(clearAddedTool());
        }
    }

    const getToolType = () => {
        return selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? TOOL_TYPE.ACTIVE : TOOL_TYPE.ARCHIVED;
    }

    useEffect(() => {
        if (Object.keys(selfRegulationState.listingCurrentPageData).length > 0) {
            const { category, page } = selfRegulationState.listingCurrentPageData;
            setSelectedCategory(category);
            const apiCat = category === ACTIVITY_TOOL_TYPE.ACTIVE ? TOOL_TYPE.ACTIVE : TOOL_TYPE.ARCHIVED;
            dispatch(getAllTools(childState.selectedChild.childId, page, apiCat, onSuccessFetchFirst));
            dispatch(clearCurrentPage());
        } else {
            dispatch(getAllTools(childState.selectedChild.childId, 1, getToolType(), onSuccessFetchFirst));
        }

    }, []);

    const fetchData = (page) => {
        dispatch(getAllTools(childState.selectedChild.childId, page, getToolType(), onSuccessFetch));
    }

    const handleCategorySelection = (type) => {
        if (type === selectedCategory) return;
        setSelectedCategory(type);
        let actType = type === ACTIVITY_TOOL_TYPE.ACTIVE ? TOOL_TYPE.ACTIVE : TOOL_TYPE.ARCHIVED;
        dispatch(getAllTools(childState.selectedChild.childId, 1, actType, onSuccessFetch));
    }

    const handleAllCheckedChange = (event) => {
        const input = event.target && event.target.checked ? event.target.checked : false;
        setAllChecked(input);
        setCheckboxesStatus(arrayOfGivenElement(checkboxesStatus.length, input));
    }

    const handleCheckboxChange = (event, index) => {
        const input = event.target && event.target.checked ? event.target.checked : false;
        const checkedStatus = [...checkboxesStatus];
        checkedStatus[index] = input;
        setCheckboxesStatus(checkedStatus);
        if (allTrue(checkedStatus)) {
            setAllChecked(true);
        } else {
            setAllChecked(false);
        }
    }

    const openArchiveDialog = (index) => {
        if (toolsData[index].isActivated === 1) {
            setShowArchiveMessage(true);
        } else {
            setShowArchiveMessage(false);
        }
        setToolArchiveIndex(index);
        setArchiveDialogOpen(true);
    }

    const savePageAndCategory = () => {
        const data = {};
        if (selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE) {
            data.category = ACTIVITY_TOOL_TYPE.ACTIVE;
        } else {
            data.category = ACTIVITY_TOOL_TYPE.ARCHIVED;
        }
        data.page = selfRegulationState.toolsListMeta.page;
        dispatch(saveCurrentPage(data));
    }


    const moveToEditToolScreen = (id) => {
        console.log("Hello Parent");
        savePageAndCategory();
        history.push({ pathname: RouteConstants.EDIT_SELF_REGULATION_TOOL.replace(':childId', childState.selectedChild.childId).replace(':toolId', id) });
    }

    const getToolsRows = (tools) => {
        return tools.map((tool, index) => (
            <TableRow key={index} className={checkboxesStatus[index] && 'row-highlighted'}>
                <TableCell><Checkbox checked={checkboxesStatus[index]} onChange={(event) => handleCheckboxChange(event, index)} /></TableCell>
                <TableCell onClick={() => moveToEditToolScreen(tool.id)}>
                    <div className="table-profile">
                        <figure>
                            <img alt="Tool Image" src={tool.imageURL} />
                        </figure>
                        {tool.name}
                    </div>
                </TableCell>
                <TableCell align="center" onClick={() => moveToEditToolScreen(tool.id)}>{formatTimerNumber(tool.subTasksCount)}</TableCell>
                <TableCell align="center" onClick={() => moveToEditToolScreen(tool.id)}>
                    {tool.isActivated && tool.isActivated===1 ?
                    <img src={AssetConstants.bulbactive} alt=" " className="bulbImage" /> :
                    <img src={AssetConstants.bulbdeactive} alt=" " className="bulbImage" />}
                </TableCell>
                <TableCell align="center" onClick={() => moveToEditToolScreen(tool.id)}>{formatDate(tool.createdAt)}</TableCell>
                <TableCell onClick={() => moveToEditToolScreen(tool.id)}>
                    <UserCell entity={tool} />
                </TableCell>
                <TableCell align="center">
                    {!(allChecked || atleastOneTrue(checkboxesStatus)) && (
                        selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ?
                            <span className={editAct ? "archiveIcon" : "disabled-icon archiveIcon"}><ArchiveOutlinedIcon onClick={() => editAct && openArchiveDialog(index)} /></span> :
                            <span className={editAct ? "archiveIcon" : "disabled-icon archiveIcon"}><UnarchiveOutlinedIcon onClick={() => editAct && openArchiveDialog(index)} /></span>
                    )}
                </TableCell>
            </TableRow>
        ));
    }

    const moveToAddToolScreen = () => {
        history.push({ pathname: RouteConstants.ADD_SELF_REGULATION_TOOL.replace(':childId', childState.selectedChild.childId) });
    }

    const handleArchiveDialogClose = () => {
        setArchiveDialogOpen(false);
    }

    const onSuccessFetchAfterArchive = (data) => {
        onSuccessFetch(data);
        setToolArchiveSuccess(true);
    }

    const onSuccessArchiveOne = () => {
        dispatch(getAllTools(childState.selectedChild.childId, selfRegulationState.toolsListMeta.page, getToolType(), (data) => onSuccessFetchAfterArchive(data)));
    }

    const onFailureArchiveOne = (error) => {
        console.log(error);
    }

    const archiveOne = () => {
        let array = [];
        array = [
            {
                selfRegId: toolsData[toolArchiveIndex].id,
                childId: childState.selectedChild.childId,
                isArchive: selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? true : false,
            }
        ];

        const payload = {
            archiveSelfRegulation: array,
        };
        setArchiveDialogOpen(false);
        dispatch(archiveTool(payload, onSuccessArchiveOne, onFailureArchiveOne));
    }

    const handleToolArchiveSuccessClose = () => {
        setToolArchiveSuccess(false);
    }

    const handleToolMultiArchiveSuccessClose = () => {
        setToolMultiArchiveSuccess(false);
    }

    const openMultiArchiveDialog = () => {
        const indices = [];
        let anyActive = false;
        for (let i = 0; i < checkboxesStatus.length; i++) {
            if (checkboxesStatus[i]) {
                indices.push(i);
                if (toolsData[i].isActivated === 1) {
                    anyActive = true;
                }
            }
        }
        if (anyActive) {
            setShowArchiveMessage(true);
        } else {
            setShowArchiveMessage(false);
        }
        setToolArchiveMultiIndex(indices);
        setMultiArchiveDialogOpen(true);
    }

    const handleMultiArchiveDialogClose = () => {
        setMultiArchiveDialogOpen(false);
    }

    const onSuccessFetchAfterMultiArchive = (data) => {
        onSuccessFetch(data);
        setToolMultiArchiveSuccess(true);
    }

    const onSuccessArchiveMultiple = () => {
        dispatch(getAllTools(childState.selectedChild.childId, selfRegulationState.toolsListMeta.page, getToolType(), (data) => onSuccessFetchAfterMultiArchive(data)));
    }

    const onFailureArchiveMultiple = (error) => {
        console.log(error);
    }

    const archiveMultiple = () => {
        let array = [];
        for (let i = 0; i < toolArchiveMultiIndex.length; i++) {
            const index = toolArchiveMultiIndex[i];
            const obj = {
                selfRegId: toolsData[index].id,
                childId: childState.selectedChild.childId,
                isArchive: selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? true : false,
            };
            array.push(obj);
        }
        if (array.length > 1) {
            setAreMultiple(true);
        } else {
            setAreMultiple(false);
        }

        const payload = {
            archiveSelfRegulation: array,
        };
        setMultiArchiveDialogOpen(false);
        dispatch(archiveTool(payload, onSuccessArchiveMultiple, onFailureArchiveMultiple));
    }

    return (
        <div className="generic-card">
            {selfRegulationState.loading && <Loader />}
            <SnackbarAlert show={toolAddedSuccess} message={t("toolAddedSuccessMessage")} duration={4000} onCloseAlert={() => { setToolAddedSuccess(false) }} />

            <SnackbarAlert
                show={toolArchiveSuccess}
                message={selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? t("toolArchived") : t("toolUnArchived")}
                duration={5000}
                onCloseAlert={handleToolArchiveSuccessClose} />

            <SnackbarAlert
                show={toolMultiArchiveSuccess}
                message={selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? (areMultiple ? t("toolsArchived") : t("toolArchived")) : (areMultiple ? t("toolsUnArchived") : t("toolUnArchived"))}
                duration={5000}
                onCloseAlert={handleToolMultiArchiveSuccessClose} />

            <Dialog maxWidth="xs" open={archiveDialogOpen} onClose={handleArchiveDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleArchiveDialogClose} />
                <DialogTitle id="alert-dialog-title">{selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? t("archiveToolConfirmation") : t("unarchiveToolConfirmation")}</DialogTitle>
                {
                    showArchiveMessage &&
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{t("singleArchiveMessage")}</DialogContentText>
                    </DialogContent>
                }
                <DialogActions>
                    <Button variant="contained" onClick={handleArchiveDialogClose} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={() => archiveOne()} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="xs" open={multiArchiveDialogOpen} onClose={handleMultiArchiveDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleMultiArchiveDialogClose} />
                <DialogTitle id="alert-dialog-title">{selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? t("multiArchiveToolConfirmation") : t("multiUnArchiveToolConfirmation")}</DialogTitle>
                {
                    showArchiveMessage &&
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{t("singleArchiveMessage")}</DialogContentText>
                    </DialogContent>
                }
                <DialogActions>
                    <Button variant="contained" onClick={handleMultiArchiveDialogClose} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={() => archiveMultiple()} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>

            <Card className="no-style">
                <div className="jc-space-between-v-middle mb-15">
                    <h1 className="m-0">{t("selfRegulationToolsList")}</h1>
                    <Button variant="contained" className={!editAct ? 'Mui-disabled btn-rounded' : "btn-rounded"} disabled={!editAct} color="primary" size="large" onClick={moveToAddToolScreen}>
                        <i className="ticon-plus"></i>
                        {t("createNewTool")}
                    </Button>
                </div>
                {
                    (allChecked || atleastOneTrue(checkboxesStatus)) ?
                        <div className="archive-Selection">
                            <Button variant="contained" className={!editAct ? 'Mui-disabled btn-rounded' : "btn-rounded"} disabled={!editAct} onClick={openMultiArchiveDialog}>
                                {selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ?
                                    <div>
                                        <ArchiveIcon />
                                        {t("archiveSelected")}
                                    </div> :
                                    <div>
                                        <UnarchiveIcon />
                                        {t("unarchiveSelected")}
                                    </div>
                                }
                            </Button>
                        </div> :
                        <div className="buttons-head">
                            <span className={selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? "active" : "inactive"} onClick={() => handleCategorySelection(ACTIVITY_TOOL_TYPE.ACTIVE)}>{t("active")}</span>
                            <span className={selectedCategory === ACTIVITY_TOOL_TYPE.ARCHIVED ? "active" : "inactive"} onClick={() => handleCategorySelection(ACTIVITY_TOOL_TYPE.ARCHIVED)}>{t("archived")}</span>
                        </div>
                }
                <div className="selfRegulationListing">
                    {
                        toolsData.length > 0 ?
                            <div>
                                <TableContainer className="table-container">
                                    <Table aria-label="simple table" className="has-profile-photo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><Checkbox checked={allChecked} onChange={handleAllCheckedChange} /></TableCell>
                                                <TableCell>{t("toolName")}</TableCell>
                                                <TableCell align="center">{t("instructions")}</TableCell>
                                                <TableCell align="center">{t("status")}</TableCell>
                                                <TableCell align="center">{t("dateCreated")}</TableCell>
                                                <TableCell>{t("createdBy")}</TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {getToolsRows(toolsData)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="pagination">
                                    <Pagination pageCount={selfRegulationState.toolsListMeta.pageCount} current={selfRegulationState.toolsListMeta.page} fetch={fetchData} />
                                </div>
                            </div> :
                            !selfRegulationState.loading &&
                            <div className="noActivityListing">
                                <img src={AssetConstants.emptylist} />
                                <span>{selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? t("emptySelfRegulationListMessage") : t("emptyArchivedSelfRegulationListMessage")}</span>
                            </div>
                    }
                </div>
            </Card>
        </div>
    );
};

export default SelfRegulation;