import { SCHEDULE_GET_ACTIVITIES_ACTIONS, SCHEDULE_ACTIVITY_ACTIONS, UPDATE_SCHEDULE_ACTIVITY_ACTIONS, GET_SCHEDULE_LIST_ACTIONS, DELETE_SCHEDULE_ACTIVITY_ACTIONS, SEARCH_ACTIVITY_ACTIONS } from "../../constants/ActionConstants";

let initialState = {
    activityLoading: false,
    activityList: [],
    activityListMeta: {},
    loading: false,
    scheduleList: [],
};

const schedule = (state = initialState, action) => {
    switch (action.type) {
        case SCHEDULE_GET_ACTIVITIES_ACTIONS.SCHEDULE_GET_ACTIVITIES:
            return {
                ...state,
                activityLoading: true,
            };


        case SCHEDULE_GET_ACTIVITIES_ACTIONS.SCHEDULE_GET_ACTIVITIES_CLEAR_OLD_DATA:
            return {
                ...state,
                activityLoading: true,
                activityList: [],
            };

        case SCHEDULE_GET_ACTIVITIES_ACTIONS.SCHEDULE_GET_ACTIVITIES_SUCCESS:
            return {
                ...state,
                activityLoading: false,
                activityList: [...state.activityList, ...action.payload.data.data],
                activityListMeta: action.payload.data.meta,
            };

        case SCHEDULE_GET_ACTIVITIES_ACTIONS.SCHEDULE_GET_ACTIVITIES_FAILURE:
            return {
                ...state,
                activityLoading: false,
            };

        case SCHEDULE_ACTIVITY_ACTIONS.SCHEDULE_ACTIVITY:
            return {
                ...state,
                loading: true,
            };

        case SCHEDULE_ACTIVITY_ACTIONS.SCHEDULE_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case SCHEDULE_ACTIVITY_ACTIONS.SCHEDULE_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_SCHEDULE_ACTIVITY_ACTIONS.UPDATE_SCHEDULE_ACTIVITY:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_SCHEDULE_ACTIVITY_ACTIONS.UPDATE_SCHEDULE_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_SCHEDULE_ACTIVITY_ACTIONS.UPDATE_SCHEDULE_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_SCHEDULE_LIST_ACTIONS.GET_SCHEDULE_LIST:
            return {
                ...state,
                loading: true,
            };

        case GET_SCHEDULE_LIST_ACTIONS.GET_SCHEDULE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                scheduleList: action.payload.data,
            };

        case GET_SCHEDULE_LIST_ACTIONS.GET_SCHEDULE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case DELETE_SCHEDULE_ACTIVITY_ACTIONS.DELETE_SCHEDULE_ACTIVITY:
            return {
                ...state,
                loading: true,
            };

        case DELETE_SCHEDULE_ACTIVITY_ACTIONS.DELETE_SCHEDULE_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case DELETE_SCHEDULE_ACTIVITY_ACTIONS.DELETE_SCHEDULE_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case SEARCH_ACTIVITY_ACTIONS.SEARCH_ACTIVITY_LIST:
            return {
                ...state,
                loading: true,
                
            };
            
        case SEARCH_ACTIVITY_ACTIONS.SEARCH_ACTIVITY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                
            };

        case SEARCH_ACTIVITY_ACTIONS.SEARCH_ACTIVITY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};

export default schedule;
