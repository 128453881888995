import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { ACTIVITY_TYPE } from "../../constants/GenericConstants";

class ScheduleService {
  getActivities(childId, page, searchTerm) {
    let url = UrlConstants.GET_ACTIVITY_LIST.replace(/{childId}/, childId);
    url = url + "&page=" + page;
    url = url + "&isArchive=" + ACTIVITY_TYPE.ACTIVE;
    if (searchTerm.length > 0) {
      url = url + "&q=" + searchTerm;
    }

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  scheduleActivity(payload) {
    let url = UrlConstants.SCHEDULE_ACTIVITY;

    return apiClient()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  updateScheduleActivity(payload) {
    let url = UrlConstants.SCHEDULE_ACTIVITY;

    return apiClient()
      .put(url, payload)
      .then(handleResponse)
      .catch(handleError);
  }

  getScheduleList(childId, startDate, type) {
    let url = UrlConstants.GET_SCHEDULE_LIST.replace(/{childId}/, childId)
      .replace(/{startDate}/, startDate)
      .replace(/{type}/, type);

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }

  deleteScheduleActivity(scheduleId, childId) {
    let url = UrlConstants.DELETE_SCHEDULE_ACTIVITY.replace(
      /{scheduleId}/,
      scheduleId
    );
    url = url.replace(/{childId}/, childId);
    return apiClient().delete(url).then(handleResponse).catch(handleError);
  }

  searchScheduledActivities(childId, startDate, type, searchTerm) {
    let url = UrlConstants.SEARCH_SCHEDULED_ACTIVITIES.replace(
      /{childId}/,
      childId
    )
      .replace(/{startDate}/, startDate)
      .replace(/{type}/, type)
      .replace(/{search}/, searchTerm);

    return apiClient().get(url).then(handleResponse).catch(handleError);
  }
}

export default new ScheduleService();
