import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import child from "../../store/reducers/Child";

class SelfRegulationService {

    addTool(payload) {
        let url = UrlConstants.ADD_SELF_REGULATION_TOOL;

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    getAllTools(childId, page, isArchive) {
        let url = UrlConstants.SELF_REGULATION_LISTING.replace(/{childId}/, childId).replace(/{isArchive}/, isArchive);
        url = url + '&page=' + page;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getTool(id, childId) {
        let url = UrlConstants.GET_SELF_REGULATION_TOOL.replace(/{toolId}/, id).replace(/{childId}/, childId);

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    updateTool(payload) {
        let url = UrlConstants.ADD_SELF_REGULATION_TOOL;

        return apiClient()
            .put(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    archiveTool(payload) {
        let url = UrlConstants.ARCHIVE_TOOL;

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

}

export default new SelfRegulationService();