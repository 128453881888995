import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";

class ExternalUserService {

    addExternalUser(payload) {
        let url = UrlConstants.ADD_EX_USER;

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    editExternalUser(payload) {
        let url = UrlConstants.EDIT_EX_USER;

        return apiClient()
            .put(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    getExternalUsers() {
        let url = UrlConstants.GET_EX_USER_LIST;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }
   
    getExternalRoles() {
        let url = UrlConstants.GET_ROLES_LIST;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    viewExternalUser(accountID) {
        let url = UrlConstants.VIEW_EX_USER + accountID;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    confirmExternalUserInvite(confirmationCode) {
        let url = UrlConstants.CONFIRM_EXTERNAL_USER;

        const payload = {
            confirmationCode: confirmationCode,
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    updateExternalUserStatus(userId, accountId, status) {
        let url = UrlConstants.UPDATE_EXTERNAL_USER_STATUS;

        const payload = {
            userId: userId,
            accountId: accountId,
            status: status,
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    deleteExternalUser(userId) {
        let url = UrlConstants.DELETE_EX_USER.replace(/{userId}/, userId);

        return apiClient()
            .delete(url)
            .then(handleResponse)
            .catch(handleError);
    }

}

export default new ExternalUserService();