import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import RouteConstants from "../../constants/RouteConstants";
import AssetConstants from "../../constants/AssetConstants";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";

const PaymentFailureScreen = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [backToAccount, setBackToAccount] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN) &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.USER)
    ) {
      setBackToAccount(true);
    } else {
      setBackToAccount(false);
    }
  }, []);

  const moveToLoginScreen = () => {
    if (backToAccount) {
      history.replace({
        pathname: RouteConstants.ACCOUNT_SETTINGS,
      });
    } else {
      history.push({ pathname: RouteConstants.LOGIN });
    }
  };

  return (
    <div className="is-login">
      {backToAccount ? (
        <Grid xs={12} item className="app-header">
          <Header />
        </Grid>
      ) : null}
      <Grid container>
        <Grid item sm={6} className="login-form">
          <div className="congrats-msg-block">
            <div className="congrats-msg-container">
              <img className="app-logo" src={AssetConstants.logo} />
              <div>
                <h1>{t("paymentCancelledMessage")}</h1>
                <img src={AssetConstants.pagenotfound} />
              </div>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={moveToLoginScreen}
              >
                {backToAccount ? t("backToAccountSetting") : t("goToLogin")}
              </Button>
            </div>
            <div className="conrats-msg-footer">
              <Footer />
            </div>
          </div>
        </Grid>
        <Grid item sm={6} className="screen-half-splash"></Grid>
      </Grid>
    </div>
  );
};

export default PaymentFailureScreen;
