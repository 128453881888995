
export const USER_PRIVILEGES = [

    // { id: 1, name: 'User Management', view: true, edit: true, disabled: false },
    { id: 2, name: 'Activity Management', view: true, edit: true, disabled: false },
    { id: 3, name: 'Schedule', view: true, edit: true, disabled: false },
    { id: 4, name: 'Emotion Regulation', view: true, edit: true, disabled: false },
    { id: 5, name: 'User Profiles', view: true, edit: true, disabled: false }

];

export const PRIV_CONSTS ={
    // USER_PRIV : 0,
    ACTIVITY_PRIV: 0,
    SCHEDULE_PRIV: 1,
    EMOT_REG_PRIV: 2,
    CHILDREN_PRIV: 3,
}