import { useFlags } from "flagsmith/react";

import { FEATURE_FLAGS } from "../constants/GenericConstants";

const useFeatureFlags = () => {
  return useFlags([
    FEATURE_FLAGS.CHAT,
    FEATURE_FLAGS.ADD_RECIPIENT_ICON,
    FEATURE_FLAGS.ACTIVITY_TEMPLATE,
    FEATURE_FLAGS.PROFILE_PAYMENT,
    FEATURE_FLAGS.ACADEMIC_ADVISOR,
  ]);
};

export default useFeatureFlags;
