import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import rootReducer from "./reducers";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...rootReducer,
  });

export const history = createBrowserHistory();

function configureStoreProd(initialState) {
  const middlewares = [thunk];

  return createStore(
    createRootReducer(history),
    initialState,
    compose(applyMiddleware(routerMiddleware(history), ...middlewares))
  );
}

function configureStoreDev(initialState) {
  const middlewares = [thunk];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), ...middlewares))
  );

  return store;
}

const configureStore =
  process.env.NODE_ENV === "production"
    ? configureStoreProd
    : configureStoreDev;

export default configureStore;
