import { request, success, failure } from ".";
import {
  CREATE_CONVERSATION_ACTIONS,
  GET_ALL_CONVERSATIONS_ACTIONS,
  GET_CONVERSATION_MESSAGES_ACTIONS,
  CREATE_NEW_MESSAGE_ACTIONS,
  CREATE_NEW_GROUP_ACTIONS,
  GET_ALL_GROUPS_ACTIONS,
  CONVERSATION_MESSAGES,
} from "../../constants/ActionConstants";

import ChatService from "../../services/api/ChatService";

/** CHAT TYPE */
export const updateType = (payload) => {
  return request("UPDATE_CHAT_TYPE", payload);
};

/** CONVERSATIONS */

export const createConversation = (payload) => {
  return async (dispatch) => {
    dispatch(request(CREATE_CONVERSATION_ACTIONS.CREATE_CONVERSATION, {}));
    try {
      const response = await ChatService.createConversation(payload);
      dispatch(
        success(CREATE_CONVERSATION_ACTIONS.CREATE_CONVERSATION_SUCCESS, {
          data: response.data,
        })
      );
      // if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error.response);
      dispatch(
        failure(CREATE_CONVERSATION_ACTIONS.CREATE_CONVERSATION_FAILURE, {})
      );
    }
  };
};

export const getAllConversations = (onSuccess) => {
  return async (dispatch) => {
    dispatch(request(GET_ALL_CONVERSATIONS_ACTIONS.GET_ALL_CONVERSATIONS, {}));
    console.log("get all coversations abcd");
    try {
      const response = await ChatService.getAllConversations();
      console.log("get all coversations", response.data);
      dispatch(
        success(GET_ALL_CONVERSATIONS_ACTIONS.GET_ALL_CONVERSATIONS_SUCCESS, {
          data: response.data,
        })
      );
      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      console.log(error.response);
      dispatch(
        failure(GET_ALL_CONVERSATIONS_ACTIONS.GET_ALL_CONVERSATIONS_FAILURE, {})
      );
    }
  };
};

export const getConversationMessages = (conversationId, page) => {
  let searchTerm = ""; //TODO
  return async (dispatch) => {
    if (page === 1) {
      dispatch(
        request(
          GET_CONVERSATION_MESSAGES_ACTIONS.GET_CONVERSATION_MESSAGES_CLEAR_OLD_DATA,
          {}
        )
      );
    } else {
      dispatch(
        request(GET_CONVERSATION_MESSAGES_ACTIONS.GET_CONVERSATION_MESSAGES, {})
      );
    }
    try {
      const response = await ChatService.getConversationMessages(
        conversationId,
        page,
        searchTerm
      );
      dispatch(
        success(
          GET_CONVERSATION_MESSAGES_ACTIONS.GET_CONVERSATION_MESSAGES_SUCCESS,
          { data: response.data }
        )
      );
      // if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error.response);
      dispatch(
        failure(
          GET_CONVERSATION_MESSAGES_ACTIONS.GET_CONVERSATION_MESSAGES_FAILURE,
          {}
        )
      );
    }
  };
};

export const createNewMessage = (conversationId, type, payload) => {
  return async (dispatch) => {
    dispatch(request(CREATE_NEW_MESSAGE_ACTIONS.CREATE_NEW_MESSAGE, {}));
    try {
      const response = await ChatService.createNewMessage(
        conversationId,
        payload
      );

      dispatch(
        success(CREATE_NEW_MESSAGE_ACTIONS.CREATE_NEW_MESSAGE_SUCCESS, {
          data: response.data,
        })
      );
      // if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error.response);
      dispatch(
        failure(CREATE_NEW_MESSAGE_ACTIONS.CREATE_NEW_MESSAGE_FAILURE, {})
      );
    }
  };
};

/** CONVERSATION MESSAGES */

export const fetchMessages = (conversationId, page = 1, searchTerm) => {
  return async (dispatch) => {
    dispatch(request(CONVERSATION_MESSAGES.FETCH_MESSAGES, {}));

    try {
      const response = await ChatService.getConversationMessages(
        conversationId,
        page,
        searchTerm
      );

      dispatch(
        success(CONVERSATION_MESSAGES.FETCH_MESSAGES_SUCCESS, {
          data: response.data,
        })
      );
    } catch (error) {
      console.log("error=======>", error);
      dispatch(failure(CONVERSATION_MESSAGES.FETCH_MESSAGES_FAILURE, error));
    }
  };
};

/** GROUPS */
