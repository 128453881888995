import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {
  Backdrop,
  Box,
  Fade,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import GroupRecipientAddForm from "../../groups/GroupRecipientAddForm";
import { useDispatch } from "react-redux";
import { fetchRecipients } from "../../../../../store/actions/Chat/Recipients";
import { CloseOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minWidth: "30%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 0),
  },
  header: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  form: {
    padding: theme.spacing(2),
  },
  heading: {
    flex: 1,
    fontWeight: 600,
  },
  message: {
    padding: theme.spacing(2),
  },
}));

const AddGroupRecipientModal = ({ showModal, setShowModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRecipients());
  }, [dispatch]);

  return (
    <Modal
      className={classes.modal}
      open={showModal}
      onClose={() => setShowModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <Box className={classes.paper}>
          <Box className={classes.header}>
            <Typography variant="h5" className={classes.heading}>
              Add Recipient
            </Typography>
            <CloseOutlined
              style={{ cursor: "pointer" }}
              onClick={() => setShowModal(false)}
            />
          </Box>

          <Box className={classes.form}>
            <GroupRecipientAddForm setShowModal={setShowModal} />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );

  //   return (
  //     <OverlayStyle ref={ref} onClick={handleOverlayClick}>
  //       <ModalContainer showModal={showModal}>
  //         <ModalHeader>
  //           <h2>Add Recipient</h2>
  //           <MdClose
  //             cursor="pointer"
  //             size={32}
  //             onClick={() => setShowModal(false)}
  //           />
  //         </ModalHeader>
  //         <ModalContentBody>
  //           <GroupRecipientAddForm />
  //         </ModalContentBody>
  //       </ModalContainer>
  //     </OverlayStyle>
  //   );
};

export default AddGroupRecipientModal;

AddGroupRecipientModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
