import { CONVERSATION_MESSAGES } from "../../../constants/ActionConstants";

const INITIAL_STATE = {
  loading: false,
  messages: [],
  meta: {},
  searchTerm: "",
  error: null,
};

const messages = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONVERSATION_MESSAGES.FETCH_MESSAGES:
      return {
        ...state,
        loading: true,
      };
    case CONVERSATION_MESSAGES.FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, ...action.payload.data.data],
        meta: action.payload.data.meta,
        loading: false,
      };
    case CONVERSATION_MESSAGES.FETCH_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONVERSATION_MESSAGES.ADD_MESSAGE:
      const { conversation, message } = action.payload;
      const conversationMessage = state.messages.find(
        (cm) => cm.id === conversation.id
      );

      return {
        ...state,
        messages: [message, ...state.messages],
        // messages: state.messages.unshift(action.payload),
      };

    case CONVERSATION_MESSAGES.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
        messages: [], // reset message state when search input change
      };

    case CONVERSATION_MESSAGES.RESET_MESSAGE:
      return { ...INITIAL_STATE };

    default:
      return { ...state };
  }
};

export default messages;
