export default {
  logo: require("../assets/images/thrive-logo.svg"),
  splash: require("../assets/images/splashes.jpg"),
  createprofilescucess: require("../assets/images/create-child-profile-success.svg"),
  congratscheck: require("../assets/images/congrats-check.svg"),
  emptylist: require("../assets/images/empty-list-icon.png"),
  boyonbook: require("../assets/images/boy-book.png"),
  oval: require("../assets/images/oval.png"),
  applewatch: require("../assets/images/apple-watch.png"),
  applewatchgray: require("../assets/images/applewatchgray.png"),
  // headerhomeicon: require("../assets/images/home-icon.svg"),
  headerhomeicon: require("../assets/images/Home.svg"),
  pagenotfound: require("../assets/images/page-not-found.svg"),
  thrivewatch: require("../assets/images/thrive-watch.jpg"),
  stripe: require("../assets/images/stripe.png"),
  bulbactive: require("../assets/images/bulb-active.svg"),
  bulbdeactive: require("../assets/images/bulb-disabled.svg"),
  visalogo: require("../assets/images/visa-logo.jpg"),
  mastercardlogo: require("../assets/images/master-card-logo.jpg"),
  unionpaylogo: require("../assets/images/unionpay-logo.jpg"),
  stripelogo: require("../assets/images/stripe-logo.jpg"),
  warninglogo: require("../assets/images/alert-triangle.png"),
  gmtlogo: require("../assets/images/icon-globe.png"),
  cclogo: require("../assets/images/cc-icon.png"),
  chatIcon: require("../assets/images/Chat.svg"),
  chatPlaceholder: require("../assets/images/chat-placeholder.svg"),
  mobile: require("../assets/images/mobile.png"),
  profilePlaceholder: require("../assets/images/placeholder-profile.png"),
};
