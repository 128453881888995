import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import RouteConstants from "../../constants/RouteConstants";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/Authentication";
import { useIdleTimer } from 'react-idle-timer';
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";
import { SocketContext } from "../../context/SocketContext";

const InactivityLogout = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { socket , setSocket, setToken } = useContext(SocketContext)

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {

    if(localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_EXPIRED_TOKEN_ERROR)) {
      setShowAlert(true);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SHOW_EXPIRED_TOKEN_ERROR);
  }
}, [])

  const handleOnIdle = (event) => {
    // console.log('user is idle', event);
    // console.log('last active', getLastActiveTime());
  }

  const onLogoutSuccess = () => {
    history.push({ pathname: RouteConstants.LOGIN });

     /** Disconnect socket when user logged out */
     if(socket){
      socket.disconnect();
      setSocket(null);
      setToken(null);
    }

    setShowAlert(true);
  }

  const handleOnActive = (event) => {
    // console.log('user is active', event);
    // console.log('time remaining', getRemainingTime());
    if (getRemainingTime() <= 0 && localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN)) {
      // console.log('Clearing local storage');
      dispatch(logout(onLogoutSuccess, () => { }));
    }
  }

  const handleOnAction = (event) => {
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  return (
    <SnackbarAlert show={showAlert} message={t("expiredSessionMessage")} duration={4000} onCloseAlert={() => setShowAlert(false)} />
  );
}

export default InactivityLogout;