import { request, success, failure } from ".";
import { ADD_EX_USER_ACTIONS, GET_EX_USER_LIST_ACTIONS, CONFIRM_EXTERNAL_USER_ACTIONS, EXTERNAL_USER_INVITE_CODE_ACTIONS, VIEW_EX_USER_ACTIONS, EDIT_EX_USER_ACTIONS, UPDATE_EXTERNAL_USER_STATUS_ACTIONS, DELETE_EXTERNAL_USER_ACTIONS, DELETE_LIST_EXTERNAL_USER_ACTIONS, GET_ROLE_LIST_ACTIONS } from "../../constants/ActionConstants";

import ExternalUserService from "../../services/api/ExternalUserService";


export const addExternalUser = (externalUser, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(VIEW_EX_USER_ACTIONS.VIEW_EX_USER, {}));

        try {
            const response = await ExternalUserService.addExternalUser(externalUser);
            onSuccess(response);
            dispatch(getExternalUsers());

        } catch (error) {
            dispatch(failure(ADD_EX_USER_ACTIONS.ADD_EX_USER_FAILURE, {}));
            onFailure(error);
        }
    };
}

export const editExternalUser = (externalUser, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(EDIT_EX_USER_ACTIONS.EDIT_EX_USER, externalUser));
        try {
            const response = await ExternalUserService.editExternalUser(externalUser);
            onSuccess(response);
            dispatch(getExternalUsers());

        } catch (error) {
            dispatch(failure(EDIT_EX_USER_ACTIONS.EDIT_EX_USER_FAILURE, {}));

            onFailure(error);
        }
    };
}

export const getExternalUsers = (onSuccess) => {
    return async (dispatch) => {
        dispatch(request(GET_EX_USER_LIST_ACTIONS.GET_EX_USER_LIST, {}));
        try {
            const response = await ExternalUserService.getExternalUsers();
            dispatch(success(GET_EX_USER_LIST_ACTIONS.GET_EX_USER_LIST_SUCCESS, { data: response.data }));
            if (onSuccess) onSuccess();
        } catch (error) {
            console.log(error.response);
            dispatch(failure(GET_EX_USER_LIST_ACTIONS.GET_EX_USER_LIST_FAILURE, {}));
        }
    };
}

export const getExternalRoles = (onSuccess) => {
    return async (dispatch) => {
        dispatch(request(GET_ROLE_LIST_ACTIONS.GET_ROLE_LIST, {}));
        try {
            const response = await ExternalUserService.getExternalRoles();
            dispatch(success(GET_ROLE_LIST_ACTIONS.GET_ROLE_LIST_SUCCESS, { data: response.data }));
            if (onSuccess) onSuccess(response.data);
        } catch (error) {
            console.log(error.response);
            dispatch(failure(GET_ROLE_LIST_ACTIONS.GET_ROLE_LIST_FAILURE, {}));
        }
    };
}

export const viewExternalUser = (accountID, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(VIEW_EX_USER_ACTIONS.VIEW_EX_USER, {}));
        try {
            const response = await ExternalUserService.viewExternalUser(accountID);
            dispatch(success(VIEW_EX_USER_ACTIONS.VIEW_EX_USER_SUCCESS, {}));
            onSuccess(response)
        } catch (error) {
            console.log(error.response);
            dispatch(failure(VIEW_EX_USER_ACTIONS.VIEW_EX_USER_FAILURE, {}));
            onFailure(error)
        }
    };
}

export const confirmExternalUserInvite = (confirmationCode, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(CONFIRM_EXTERNAL_USER_ACTIONS.CONFIRM_EXTERNAL_USER, {}));
        try {
            const response = await ExternalUserService.confirmExternalUserInvite(confirmationCode);
            dispatch(success(CONFIRM_EXTERNAL_USER_ACTIONS.CONFIRM_EXTERNAL_USER_SUCCESS, {}));
            onSuccess(response);
        } catch (error) {
            dispatch(failure(CONFIRM_EXTERNAL_USER_ACTIONS.CONFIRM_EXTERNAL_USER_FAILURE, {}));
            onFailure(error.data? error.data.message: "");
        }
    };
}

export const saveInviteCode = (inviteCode) => {
    return (dispatch) => {
        dispatch(request(EXTERNAL_USER_INVITE_CODE_ACTIONS.SAVE_INVITE_CODE, { inviteCode: inviteCode }));
    };
}

export const removeInviteCode = () => {
    return (dispatch) => {
        dispatch(request(EXTERNAL_USER_INVITE_CODE_ACTIONS.REMOVE_INVITE_CODE, {}));
    };
}

export const updateExternalUserStatus = (userId, accountId, status, onSuccess, detail = false) => {
    return async (dispatch) => {
        if (detail) {
            dispatch(request(UPDATE_EXTERNAL_USER_STATUS_ACTIONS.UPDATE_EXTERNAL_USER_STATUS_DETAIL, {}));

        } else {
            dispatch(request(UPDATE_EXTERNAL_USER_STATUS_ACTIONS.UPDATE_EXTERNAL_USER_STATUS, {}));
        }
        try {
            const response = await ExternalUserService.updateExternalUserStatus(userId, accountId, status);
            console.log(response);
            dispatch(success(UPDATE_EXTERNAL_USER_STATUS_ACTIONS.UPDATE_EXTERNAL_USER_STATUS_SUCCESS, {}));
            onSuccess();
        } catch (error) {
            dispatch(failure(UPDATE_EXTERNAL_USER_STATUS_ACTIONS.UPDATE_EXTERNAL_USER_STATUS_FAILURE, {}));
        }
    };
}

export const deleteExternalUser = (userId, onSuccess) => {
    return async (dispatch) => {
        dispatch(request(DELETE_EXTERNAL_USER_ACTIONS.DELETE_EXTERNAL_USER, {}));
        try {
            const response = await ExternalUserService.deleteExternalUser(userId);
            dispatch(success(DELETE_EXTERNAL_USER_ACTIONS.DELETE_EXTERNAL_USER_SUCCESS, {}));
            onSuccess(response);
        } catch (error) {
            dispatch(failure(DELETE_EXTERNAL_USER_ACTIONS.DELETE_EXTERNAL_USER_FAILURE, {}));
        }
    };
}

export const deleteListExternalUser = (userId, onSuccess) => {
    return async (dispatch) => {
        dispatch(request(DELETE_LIST_EXTERNAL_USER_ACTIONS.DELETE_LIST_EXTERNAL_USER, {}));
        try {
            const response = await ExternalUserService.deleteExternalUser(userId);
            dispatch(success(DELETE_LIST_EXTERNAL_USER_ACTIONS.DELETE_LIST_EXTERNAL_USER_SUCCESS, {}));
            onSuccess();
        } catch (error) {
            dispatch(failure(DELETE_LIST_EXTERNAL_USER_ACTIONS.DELETE_LIST_EXTERNAL_USER_FAILURE, {}));
        }
    };
}