import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";
import CloseIcon from "@material-ui/icons/Close";
import {
  updateExternalUserStatus,
  getExternalUsers,
} from "../../store/actions/ExternalUser";
import {
  EXTERNAL_USER_STATUS,
  ROLE_TYPES,
} from "../../constants/GenericConstants";
import Loader from "../Loader/Loader";
import AssetConstants from "../../constants/AssetConstants";
import { toTitleCase, formatDate } from "../../helpers/GenericHelper";

export default function ExternalUsersTable(props) {
  document.title = "Thrive App - External User";
  const { t } = useTranslation();
  const userState = useSelector((state) => state.authentication);
  const externalUserState = useSelector((state) => state.externalUser);
  const dispatch = useDispatch();
  const { usersData } = props;
  const history = useHistory();
  const [index, setIndex] = useState();
  const [sInput, setInput] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const onGetExternalUsersSuccess = () => {
    props.setStatusUpdateAlert(true);
  };

  const onStatusChangeSuccess = () => {
    dispatch(getExternalUsers(onGetExternalUsersSuccess));
  };

  const changeUserStatus = () => {
    const currentUser = usersData[index];
    const status = sInput
      ? EXTERNAL_USER_STATUS.ACTIVE
      : EXTERNAL_USER_STATUS.IN_ACTIVE;
    setDialogOpen(false);
    dispatch(
      updateExternalUserStatus(
        userState.user.id,
        currentUser.accountId,
        status,
        onStatusChangeSuccess
      )
    );
  };

  const openDialog = (event, index) => {
    setIndex(index);
    setInput(
      event.target && event.target.checked ? event.target.checked : false
    );
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const moveToEditUserScreen = (row) => {
    history.push({
      pathname: RouteConstants.VIEW_EXTERNAL_USERS.replace(
        ":accountId",
        row.accountId
      ),
      allowStatusChange: row.allowStatusChange, //Patch for demo
    });
  };

  const displayRole = (role) => {
    switch (role) {
      case ROLE_TYPES.TEACHER:
        return toTitleCase(role);

      case ROLE_TYPES.THERAPIST:
        return toTitleCase(role);

      case ROLE_TYPES.OTHER:
        return toTitleCase(role);

      case ROLE_TYPES.CO_PARENT_GUARDIAN:
        return t("coParentGuardian");

      case ROLE_TYPES.ACADEMIC_ADVISOR:
        return t("academicAdvisor");

      case ROLE_TYPES.PARTNER_SPOUSE:
        return t("partnerSpouse");

      default:
        return "";
    }
  };

  return (
    <div>
      {externalUserState.statusLoading && <Loader />}
      <Dialog
        maxWidth="xs"
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseIcon className="popup-close" onClick={handleCloseDialog} />
        <DialogTitle id="alert-dialog-title">
          {t("changeExternalUserStatusDialogMessage")}
        </DialogTitle>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDialog}>
            {t("no")}
          </Button>
          <Button
            variant="contained"
            onClick={changeUserStatus}
            color="primary"
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
      {usersData.length === 0 ? (
        <Card className="generic-card empty-list-card">
          <img src={AssetConstants.emptylist} />

          <span>{t("emptyExternalUsersListMessage")}</span>
        </Card>
      ) : (
        <TableContainer className="table-container">
          <Table aria-label="simple table" className="has-profile-photo">
            <TableHead>
              <TableRow>
                <TableCell>{t("names")}</TableCell>
                <TableCell align="center">{t("role")}</TableCell>
                <TableCell align="center">{t("dateCreated")}</TableCell>
                <TableCell align="center">{t("childProfiles")}</TableCell>
                <TableCell align="center">{t("status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    onClick={() => moveToEditUserScreen(row)}
                    className="handCursor"
                  >
                    <div className="table-profile">
                      <figure>
                        {!loaded ? <p className="loader"></p> : null}

                        <img
                          onLoad={() => {
                            setLoaded(true);
                          }}
                          src={
                            row.photoURL
                              ? row.photoURL
                              : AssetConstants.profilePlaceholder
                          }
                          alt="External User Profile"
                        />
                      </figure>
                      {row.userAlias}
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => moveToEditUserScreen(row)}
                    className="handCursor"
                  >
                    {displayRole(row.userRole)}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => moveToEditUserScreen(row)}
                    className="handCursor"
                  >
                    {formatDate(row.createdDate)}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => moveToEditUserScreen(row)}
                    className="handCursor"
                  >
                    {row.childCount}
                  </TableCell>
                  <TableCell align="center">
                    <div
                      className={
                        row.allowStatusChange && row.associationStatus
                          ? t("has-switch switch-active")
                          : t("has-switch switch-inactive")
                      }
                    >
                      <strong>
                        {row.allowStatusChange && row.associationStatus
                          ? t("active")
                          : t("disable")}
                      </strong>
                      <Switch
                        checked={row.associationStatus}
                        color="primary"
                        disabled={!row.allowStatusChange}
                        onChange={(event) => openDialog(event, index)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
