import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  circle: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#02C8A7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: 12,
    textTransform: "uppercase",
  },
}));

const CircleWithText = ({ label = 0 }) => {
  const classes = useStyles();

  if (label === 0) return null;

  const updatedLabel = label > 99 ? "99+" : label;

  return (
    <Box className={classes.circle}>
      <Typography variant="caption">{updatedLabel}</Typography>
    </Box>
  );
};

export default CircleWithText;
