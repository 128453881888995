import React, { useEffect, useRef, useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Checkbox, FormControlLabel, FormHelperText, Grid, Input, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import ChildSelectItem from "../ChildSelectItem/ChildSelectItem";
import ChildViewItem from "../ChildSelectItem/ChildViewItem";
import UserRoles from "../UserRoles/UserRoles";
import AccessPriv from "../AccessPriv/AccessPriv";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getChildrenList, getChildrenListForEx } from "../../store/actions/Child";
import Loader from '../Loader/Loader';
import { USER_PRIVILEGES } from '../../constants/StaticData'
import { calculatePermissionOfView, calculatePermissionOfEdit } from "../../helpers/PermissionCalculator";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { getExternalRoles } from '../../store/actions/ExternalUser';

export default function ViewExternalUser({ exUserDataGot, formData, setFormData, setFormDataErrors, formDataErrors, formSubmitted, allowStatusChange, setPerm }) {
    // const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const childState = useSelector((state) => state.child);
    const externalUserState = useSelector((state) => state.externalUser);

    const [exUserData, setExUserData] = useState({})
    const [childrenData, setChildrenData] = useState([])

    var permissionsLocal = useRef(parseInt(exUserDataGot.userPermission));


    const {
        alias,
        children,
        permissions,
        role,
        status,
    } = formData;

    const {
        aliasError,
        childrenError,
        permissionsError,
        roleError
    } = formDataErrors

    const handleAliasChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setFormData({ ...formData, alias: input });
    }

    const handleToggleChange = (e, value) => {
        setFormData({ ...formData, status: value === "Active" ? true : false });
    }

    const viewSwitchChanged = (value, object) => {
        if (value) {
            permissionsLocal.current = permissionsLocal.current + calculatePermissionOfView(value, object);
            setPerm(permissionsLocal.current)
        }
        else {
            permissionsLocal.current = permissionsLocal.current - calculatePermissionOfView(value, object);
            setPerm(permissionsLocal.current)
        }



    }

    const editSwitchChanged = (value, object) => {
        if (value) {
            permissionsLocal.current = permissionsLocal.current + calculatePermissionOfEdit(value, object);
            setPerm(permissionsLocal.current)
        }
        else {
            permissionsLocal.current = permissionsLocal.current - calculatePermissionOfEdit(value, object);
            setPerm(permissionsLocal.current)
        }


    }


    useEffect(() => {
        console.log("Status Value", exUserDataGot);
        setExUserData(exUserDataGot)
        permissionsLocal.current = parseInt(exUserDataGot.userPermission)
        if (Object.entries(exUserDataGot).length !== 0) {

            setFormData({
                ...formData,
                alias: exUserDataGot.userAlias,
                children: exUserDataGot.childIds,
                permissions: exUserDataGot.userPermission,
                status: exUserDataGot.associationStatus,
                role: exUserDataGot.userRole
            });

            permissionsLocal.current = parseInt(exUserDataGot.userPermission)
        }

    }, [exUserDataGot]);

    useEffect(() => {
        dispatch(getChildrenList());
       
    }, [])


    const showInputError = (field) => {
        return field.length > 0
    }

    const makePermZero= ()=>{
        permissionsLocal.current=0
        setPerm(0)
    } 


    return (
        <Card className="generic-card">
            <div>
                {/* {childState.loading && <Loader />} */}

                <form>
                    <h4 className="pt-0">{t("personalInformation")}</h4>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel htmlFor="alias" required>{t("name")}</InputLabel>
                            <Input id="alias" name="alias" value={alias} onChange={handleAliasChange} required type="text" />

                            {
                                showInputError(aliasError) && <FormHelperText error={showInputError(aliasError)}>{aliasError}</FormHelperText>
                            }
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel htmlFor="email" required>{t("emailAddress")}</InputLabel>
                            <Input id="email" name="email" value={exUserData ? exUserData.userEmailAddress : "--"} disabled required type="text" />
                        </Grid>

                    </Grid>

                    <hr className="section-hr" />

                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h4 className="pb-0">{t("accessToChild")}</h4>
                                </Grid>
                                {
                                    // childrenData.length > 0 ?
                                    childState.childrenList.length > 0 ?

                                        <Grid item xs={12} className="child-access">
                                            {
                                                // childrenData.map((child, index) => {
                                                childState.childrenList.map((child, index) => {
                                                    return <ChildViewItem child={child} formData={formData} setFormData={setFormData} key={index} />

                                                })
                                            }
                                        </Grid> : <p>{t("noChildrenFound")}</p>

                                }
                                {
                                    showInputError(childrenError) && <FormHelperText error={showInputError(childrenError)}>{childrenError}</FormHelperText>
                                }

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h4 className="pb-0">{t("userRoles")}</h4>
                                </Grid>
                                <Grid item xs={12}>
                                    <UserRoles rolesList={externalUserState.roles} makePermZero={makePermZero} formData={formData} setFormData={setFormData} name={role.roleName? role.roleName:role } image="https://cdn2.stylecraze.com/wp-content/uploads/2013/10/2361-Top-10-Beauty-Tips-For-Kids-ss.jpg" />
                                    {
                                        showInputError(roleError) && <FormHelperText error={showInputError(roleError)}>{roleError}</FormHelperText>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <hr className="section-hr access-child-hr" />

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h4>{t("accessPrivileges")}</h4>
                            <TableContainer className="shadow-none">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t("roles")}</TableCell>
                                            <TableCell align="center">{t("canViewOnly")}</TableCell>
                                            <TableCell align="center">{t("canViewAndEdit")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {exUserData && exUserData.userPermission > -1 ?
                                            USER_PRIVILEGES.map(privileges => {
                                                return (<AccessPriv role={formData.role} permissions={permissionsLocal.current} privileges={privileges} viewSwitchChanged={viewSwitchChanged} editSwitchChanged={editSwitchChanged} />)
                                            })
                                            : null}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {
                                showInputError(permissionsError) && <FormHelperText error={showInputError(permissionsError)}>{permissionsError}</FormHelperText>
                            }
                        </Grid>
                    </Grid>

                </form>
            </div>

        </Card>
    );
}