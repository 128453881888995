import { FILTER } from "../../../constants/ActionConstants";

const INITIAL_STATE = {
  search: "",
};

const filters = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FILTER.SEARCH_FILTER:
      return {
        ...state,
        search: action.payload,
      };

    default:
      return { ...state };
  }
};

export default filters;
