import { request, success } from "..";

import { RECIPIENT } from "../../../constants/ActionConstants";
import ChatService from "../../../services/api/ChatService";

export function fetchRecipients() {
  return async (dispatch) => {
    dispatch(request(RECIPIENT.FETCH_RECIPIENT_REQUEST, {}));
    try {
      const recipients = await ChatService.fetchRecipients();

      dispatch(
        success(RECIPIENT.FETCH_RECIPIENT_SUCCESS, {
          data: recipients.data,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        success(RECIPIENT.FETCH_RECIPIENT_FAILURE, {
          data: error,
        })
      );
    }
  };
}
