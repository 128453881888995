import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Grid, Container, Link, Button } from "@material-ui/core";
import RouteConstants from "../../constants/RouteConstants";
import { useSelector, useDispatch } from "react-redux";
import child from "../../store/reducers/Child";
import { getEditPermValueIndividual, getViewPermValueIndividual } from "../../helpers/PermissionCalculator";
import { PRIV_CONSTS } from "../../constants/StaticData";
import ChildActivities from "../../components/ChildActivities/ChildActivities";
import MenuIcon from '@material-ui/icons/Menu';
import { collapseSideBar } from "../../store/actions/SideBar";

const ChildActivitiesScreen = (props) => {

    document.title = "Thrive App - Activities"

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    
    const childState = useSelector((state) => state.child);
    const sideBarState = useSelector((state) => state.sideBar);
    
    const [childIdGot, setChildIdGot] = useState(false);
    const [viewAct, setViewAct] = useState(false);
    const [editAct, setEditAct] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(sideBarState.collapsed);


    const sideBarClose = () => {
        setSideBarOpen(true)
    }

    const moveToImageLibraryScreen = () => {
        history.push({ pathname: RouteConstants.IMAGE_LIBRARY });
    }

    useEffect(() => {
        const childId = decodeURIComponent(props.match.params.childId);
        if (childId) {
            let perm = checkParentAndPermView(childId, PRIV_CONSTS.ACTIVITY_PRIV)
            let permEdit = checkParentAndPermEdit(childId, PRIV_CONSTS.ACTIVITY_PRIV)
            setViewAct(perm)
            setEditAct(permEdit)
        }

        setChildIdGot(childId)
    }, [])


    const checkParentAndPermView = (childId, priv_const) => {

        let permGot = checkOwnChild(childId)
        if (!permGot && childState.otherChildrenList) {
            childState.otherChildrenList.map(clientData => {
                if (clientData.childProfiles) {
                    clientData.childProfiles.map(c => {
                        if (c.childId == childId) {
                            let perm = getViewPermValueIndividual(c.userPermissions, priv_const);
                            permGot = perm
                        }
                    })
                }
            })
        }
        return permGot;
    }
    const checkParentAndPermEdit = (childId, priv_const) => {

        let permGot = checkOwnChild(childId)
        if (!permGot && childState.otherChildrenList) {
            childState.otherChildrenList.map(clientData => {
                if (clientData.childProfiles) {
                    clientData.childProfiles.map(c => {
                        if (c.childId == childId) {
                            let perm = getEditPermValueIndividual(c.userPermissions, priv_const);
                            permGot = perm
                        }
                    })
                }
            })
        }
        return permGot;
    }

    const checkOwnChild = (childId) => {
        let ownChild = false
        if (childId) { //TO CHECK IF OWN CHILD
            if (childState.childrenList) {
                childState.childrenList.map(c => {
                    if (c.childId == childId) {
                        ownChild = true
                    }
                })

            }
        }
        return ownChild;
    }

    const handleSidebarCollapse = () => {
        setSidebarCollapsed(!sidebarCollapsed);
        dispatch(collapseSideBar(!sidebarCollapsed));
    }

    return (
        <Grid container className="app">
            <Grid xs={12} item className="app-header">
                <Header />
            </Grid>
            <Grid item xs={12} className="grid-body">
                <Container maxWidth="xl">
                    <Button className="navBtn" variant="contained"
                        // style={{ height: '20px' }}
                        onClick={() => {
                            setSideBarOpen(prev => !prev)
                        }}
                        color="primary">
                        <MenuIcon />
                    </Button>
                    <Grid container spacing={3}>
                        <Grid item xs={sidebarCollapsed? 1: 2} 
                            onClick={() => {
                                sideBarClose()
                            }} 
                            className={!sideBarOpen ? 
                                "generic-card-full-height boxopen" : 
                                "generic-card-full-height boxclose"} >
                            <Sidebar 
                                selectedItem={t("activities")} 
                                childIdGot={childIdGot} 
                                sideBarClose={sideBarClose} 
                                onCollapse={handleSidebarCollapse}
                                collapseOnLaunch={sidebarCollapsed}
                            />
                        </Grid>
                        <Grid item xs={sidebarCollapsed? 11: 10} className="generic-card-full-height box-full-width">
                            {viewAct ? <ChildActivities editAct={editAct} /> : null}
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12}>
                <Container maxWidth="xl">
                    <Grid container spacing={6} className="my-0">
                        <Grid item xs={12}>
                            <Footer />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
};

export default ChildActivitiesScreen;
