import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";
import RouteConstants from "../../constants/RouteConstants";

export const PaymentRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
          localStorage.getItem(LOCAL_STORAGE_KEYS.USER) &&
          localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN)) ||
          localStorage.getItem(LOCAL_STORAGE_KEYS.IS_UNSUBSCRIBED_USER) ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: RouteConstants.LOGIN,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
