import { ADD_CHILD_ACTIONS, GET_CHILD_ACTIONS, GET_CHILD_LIST_ACTIONS, EDIT_CHILD_ACTIONS, SIGN_OUT_ACTIONS, IMAGE_UPLOAD_ACTIONS, SET_CURRENT_CHILD_ACTIONS, DELETE_CHILD_ACTIONS, LOGOUT_WATCH_CHILD_ACTIONS, CHILD_EXISTS_ACTIONS, GET_CHILD_ACT_TRACKING_ACTIONS, REGENERATE_WATCH_CODE_ACTIONS } from "../../constants/ActionConstants";
import { LOCAL_STORAGE_KEYS } from '../../constants/GenericConstants';

const childrenDetails = localStorage.getItem(LOCAL_STORAGE_KEYS.CHILDREN_LIST) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.CHILDREN_LIST)) : [];
const otherChildrenDetails = localStorage.getItem(LOCAL_STORAGE_KEYS.OTHER_CHILDREN_LIST) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.OTHER_CHILDREN_LIST)) : [];
const selectedChildDetails = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_CHILD) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_CHILD)) : {};

let initialState = {
    loading: false,
    addedChildData: {},
    childrenList: childrenDetails,
    otherChildrenList: otherChildrenDetails,
    currentChild: {},
    selectedChild: selectedChildDetails,
    activityProgress: [],
};

const child = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CHILD_ACTIONS.ADD_CHILD:
            return {
                ...state,
                loading: true,
            };

        case ADD_CHILD_ACTIONS.ADD_CHILD_SUCCESS:
            return {
                ...state,
                loading: false,
                addedChildData: action.payload.data,
            };

        case ADD_CHILD_ACTIONS.ADD_CHILD_FAILURE:
            return {
                ...state,
                loading: false,
                addedChildData: {},
            };

        case GET_CHILD_LIST_ACTIONS.GET_CHILD_LIST:
            return {
                ...state,
                loading: true,
            };

        case GET_CHILD_LIST_ACTIONS.GET_CHILD_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                childrenList: action.payload.parentData,
                otherChildrenList: action.payload.otherData,
            };

        case GET_CHILD_LIST_ACTIONS.GET_CHILD_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                childrenList: [],
                otherChildrenList: [],
            };

        case GET_CHILD_ACTIONS.GET_CHILD:
            return {
                ...state,
                loading: true,
            };

        case IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD:
            return {
                ...state,
                loading: true,
            };
        case IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_CHILD_ACTIONS.GET_CHILD_SUCCESS:
            return {
                ...state,
                loading: false,
                currentChild: action.payload.data,
            };

        case GET_CHILD_ACTIONS.GET_CHILD_FAILURE:
            return {
                ...state,
                loading: false,
                currentChild: {},
            };

        case EDIT_CHILD_ACTIONS.EDIT_CHILD:
            return {
                ...state,
                loading: true,
            };

        case EDIT_CHILD_ACTIONS.EDIT_CHILD_SUCCESS:
            return {
                ...state,
                loading: false,
                currentChild: action.payload.data,
            };

        case EDIT_CHILD_ACTIONS.EDIT_CHILD_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case EDIT_CHILD_ACTIONS.RESET_EDIT_CHILD_DATA:
            return {
                ...state,
                currentChild: {},
            };

        case SET_CURRENT_CHILD_ACTIONS.SET_CURRENT_CHILD:
            let tempArray = [...state.childrenList];
            for (let i = 0; i < state.otherChildrenList.length; i++) {
                tempArray = [...tempArray, ...state.otherChildrenList[i].childProfiles];
            }
            let answer = tempArray.find((child) => child.childId === action.payload.id);
            localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_CHILD, JSON.stringify(answer));

            return {
                ...state,
                selectedChild: answer,
            };

        case SET_CURRENT_CHILD_ACTIONS.SET_SINGLE_USER_CHILD:
                
            let singleUserChild = action.payload.child;

            localStorage.setItem(
                LOCAL_STORAGE_KEYS.SELECTED_CHILD, 
                JSON.stringify(singleUserChild));

            return {
                ...state,
                selectedChild: singleUserChild,
            };

        case CHILD_EXISTS_ACTIONS.CHILD_EXISTS:
            let tempArray2 = [...state.childrenList];
            for (let i = 0; i < state.otherChildrenList.length; i++) {
                tempArray2 = [...tempArray2, ...state.otherChildrenList[i].childProfiles];
            }
            let answer2 = tempArray2.findIndex((child) => child.childId === action.payload.id);
            action.payload.exists = answer2 > -1 && tempArray2[answer2].isActive ? true : false;
            return {
                ...state,
            };

        case CHILD_EXISTS_ACTIONS.CHILD_EXISTS_ONLY:
            let tempArray3 = [...state.childrenList];
            for (let i = 0; i < state.otherChildrenList.length; i++) {
                tempArray3 = [...tempArray3, ...state.otherChildrenList[i].childProfiles];
            }
            let answer3 = tempArray3.findIndex((child) => child.childId === action.payload.id);
            action.payload.exists = answer3 > -1 ? true : false;
            return {
                ...state,
            };

        case SIGN_OUT_ACTIONS.SIGN_OUT_SUCCESS:
            return {
                ...state,
                loading: false,
                addedChildData: {},
                childrenList: [],
                currentChild: {},
                otherChildrenList: [],
            };

        case DELETE_CHILD_ACTIONS.DELETE_CHILD:
            return {
                ...state,
                loading: true,
            };

        case DELETE_CHILD_ACTIONS.DELETE_CHILD_SUCCESS:
            return {
                ...state,
                loading: false,
                currentChild: {},
                selectedChild: {},
            };

        case DELETE_CHILD_ACTIONS.DELETE_CHILD_FAILURE:
            return {
                ...state,
                loading: false,
            };


        case LOGOUT_WATCH_CHILD_ACTIONS.LOGOUT_WATCH_CHILD:
            return {
                ...state,
                loading: true,
            };


        case LOGOUT_WATCH_CHILD_ACTIONS.LOGOUT_WATCH_CHILD_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case LOGOUT_WATCH_CHILD_ACTIONS.LOGOUT_WATCH_CHILD_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_CHILD_ACT_TRACKING_ACTIONS.GET_CHILD_ACT_TRACKING:
            return {
                ...state,
                loading: true,
            };

        case GET_CHILD_ACT_TRACKING_ACTIONS.GET_CHILD_ACT_TRACKING_SUCCESS:
            return {
                ...state,
                loading: false,
                activityProgress: action.payload.data,
            };

        case GET_CHILD_ACT_TRACKING_ACTIONS.GET_CHILD_ACT_TRACKING_FAILURE:
            return {
                ...state,
                loading: false,
                activityProgress: [],
            };

        case REGENERATE_WATCH_CODE_ACTIONS.REGENERATE_WATCH_CODE:
            return {
                ...state,
                loading: true,
            };

        case REGENERATE_WATCH_CODE_ACTIONS.REGENERATE_WATCH_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case REGENERATE_WATCH_CODE_ACTIONS.REGENERATE_WATCH_CODE_FAILURE:
            return {
                ...state,
                loading: false,
            };


        default:
            return state;
    }
};

export default child;
