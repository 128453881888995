import { useEffect } from "react";
import { NOTIFICATION_TYPE } from "../../constants/GenericConstants";
import RouteConstants from "../../constants/RouteConstants";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../store/actions/Notifications";
import { getChildrenList, setCurrentChild } from "../../store/actions/Child";
import { updateUserStatus } from "../../store/actions/Authentication";

export default function NotificationHandler() {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.authentication);

    useEffect(() => {

        window.OneSignal = window.OneSignal || [];
        const OneSignal = window.OneSignal;

        OneSignal.push(function () {
            const onNotificationClicked = function (data) {
                // console.log("Received NotificationOpened:");
                // console.log(data);

                if (data.data.notificationData) {
                    const { notificationData } = data.data;
                    // console.log("Notification Data:");
                    // console.log(notificationData);
                    const { entityId, notificationType } = notificationData;

                    // console.log("Notification Type:");
                    // console.log(notificationType);
                    // console.log("Entity Id:");
                    // console.log(entityId);
                    switch (notificationType) {
                        case NOTIFICATION_TYPE.WATCH_ADD:
                        case NOTIFICATION_TYPE.MOBILE_ADD:
                            // console.log("Inside watch add scenario");
                            // console.log(history);
                            if(!userState.isSingleUser) {
                                history.push({ pathname: RouteConstants.EDIT_CHILD_PROFILE.replace(':childId', entityId) });
                            }
                            break;
                        case NOTIFICATION_TYPE.GENERAL_NOTIF_TIMEZONE:
                            if(!userState.isSingleUser) {
                                history.push({ pathname: RouteConstants.EDIT_CHILD_PROFILE.replace(':childId', entityId) });
                            }
                            break;

                        case NOTIFICATION_TYPE.GENERAL_NOTIF_STATUS_CHANGE:
                            history.push({ pathname: RouteConstants.ACCOUNT_SETTINGS });
                            break;

                        case NOTIFICATION_TYPE.ACTIVITY_CREATION:
                            history.push({ pathname: RouteConstants.CHILD_ACTIVITIES.replace(':childId', entityId) });
                            break;

                        case NOTIFICATION_TYPE.SCHEDULE_UPDATE:
                            history.push({ pathname: RouteConstants.SCHEDULE_ACTIVITY.replace(':childId', entityId) });
                            break;

                        case NOTIFICATION_TYPE.EXTERNAL_USER_INVITE:
                            dispatch(getChildrenList(true, entityId, () => { history.push({ pathname: RouteConstants.SCHEDULE_ACTIVITY.replace(':childId', entityId) }); }));
                            break;

                        case NOTIFICATION_TYPE.CHAT_NEW_MESSAGE:
                            history.push({ pathname: RouteConstants.SELECTED_CHAT.replace(':id', entityId) });
                            break;
                        case NOTIFICATION_TYPE.GROUP_NEW_MESSAGE:
                            history.push({ pathname: RouteConstants.SELECTED_GROUP.replace(':id', entityId) });
                            break;

                        default:
                            return;
                    }
                } else {
                    console.log("No data received!");
                }

            };

            const handler = function (data) {
                // call your primary handler
                onNotificationClicked(data);

                // add another handler right away
                OneSignal.addListenerForNotificationOpened(handler);
            };

            // attach your handler for the first time
            OneSignal.addListenerForNotificationOpened(handler);
        });


        OneSignal.push(function () {
            OneSignal.on('notificationDisplay', function (data) {
                console.log('OneSignal notification displayed:', data);
                // dispatch(getNotifications(1, () => { }));
                // alert("received")
                if (data.data.notificationData) {
                    const { notificationData } = data.data;
                    // console.log("Notification Data:");
                    // console.log(notificationData);
                    const { entityId, notificationType } = notificationData;
                    // alert("received")
                    // console.log("Notification Type:");
                    // console.log(notificationType);
                    // console.log("Entity Id:");
                    // console.log(entityId);
                    switch (notificationType) {
                        case NOTIFICATION_TYPE.WATCH_ADD:
                        case NOTIFICATION_TYPE.MOBILE_ADD:
                            // console.log("Inside watch add scenario");
                            // console.log(history);
                            dispatch(getChildrenList(true, entityId));

                            // alert(location.pathname)

                            // if (location.pathname.includes(entityId) && location.pathname.includes("edit")) {
                            //     history.go(0)
                            // }
                            break;
                        case NOTIFICATION_TYPE.GENERAL_NOTIF_TIMEZONE:
                            history.push({ pathname: RouteConstants.DASHBOARD });
                            break;

                        case NOTIFICATION_TYPE.GENERAL_NOTIF_STATUS_CHANGE:
                            dispatch(updateUserStatus())
                            history.push({ pathname: RouteConstants.DASHBOARD });
                            break;
                        case NOTIFICATION_TYPE.EXTERNAL_USER_STATUS:
                            dispatch(getChildrenList());
                            history.push({ pathname: RouteConstants.DASHBOARD });
                            break;
                        default:
                            return;
                    }
                } else {
                    console.log("No data received!");
                }




            });

            //This event can be listened to via the `on()` or `once()` listener
        });




    }, [])

    return null;
}