import React, { useState } from "react";
import { Box, Menu, MenuItem } from "@material-ui/core";

import ChatIcon from "../../icons/ChatIcon";

const SettingsMenu = ({ onClick, children }) => {
  return <MenuItem onClick={onClick}>{children}</MenuItem>;
};

const Settings = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box>
        <ChatIcon onClick={handleClick}>
          <img
            alt="Settings"
            src={require("../../../../../assets/images/chat-setting.svg")}
          />
        </ChatIcon>

        <Menu
          id="chat-settings"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {children}
        </Menu>
      </Box>
    </>
  );
};

Settings.Menu = SettingsMenu;

export default Settings;
