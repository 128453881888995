import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";
import {
  addAttachment,
  incrementAttachmentCounter,
} from "../../../../store/actions/Chat/MessagePanel";
import { formatBytes } from "../../../../helpers/GenericHelper";
import useToast from "../../../../hooks/useToast";

const MAX_SIZE = 1_048_576 * 5; // 5MB

export const MessageAttachmentActionIcon = () => {
  const { error } = useToast();
  const { t } = useTranslation();

  const attachmentIconRef = useRef(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  // const { error } = useToast({ theme: 'dark' });

  const { attachmentCounter, attachments } = useSelector(
    (state) => state.messagePanel
  );

  const onClick = (e) => {
    // eslint-disable-next-line no-unused-expressions
    fileInputRef.current?.click();
  };

  const onChange = (e) => {
    const { files } = e.target;
    if (!files) return;
    const maxFilesDropped = 5 - attachments.length;

    if (files.length > 5 || maxFilesDropped === 0) {
      error("Only 5 files or documents allowed to upload at a time");
      return;
    }

    const filesArray = Array.from(files);

    const selectedFiles = filesArray.filter((file) => file.size >= MAX_SIZE);
    if (selectedFiles.length > 0) {
      error(t("exceed_fileSize") + ` ${formatBytes(MAX_SIZE)}`);
      return;
    }

    let localCounter = attachmentCounter;
    for (let i = 0; i < filesArray.length; i++) {
      if (i === maxFilesDropped) break;
      dispatch(addAttachment({ id: localCounter++, file: filesArray[i] }));
      dispatch(incrementAttachmentCounter());
    }
  };

  return (
    <div ref={attachmentIconRef} onClick={onClick}>
      <AttachFileOutlinedIcon className={styles.fileIcon} cursor="pointer" />
      <input
        multiple={false}
        ref={fileInputRef}
        type="file"
        accept="image/*, audio/*, video/*, application/pdf"
        onChange={onChange}
        style={{ display: "none" }}
      />
    </div>
  );
};
