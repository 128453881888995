import React from "react";
import { Grid } from "@material-ui/core";
import PaymentSignup from "../../components/PaymentSignup/PaymentSignup";
import Header from "../../components/Header/Header";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";

const PaymentSignupScreen = (props) => {
  return (
    <Grid container className="app">
      {localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN) &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.USER) ? (
        <Grid xs={12} item className="app-header">
          <Header />
        </Grid>
      ) : null}
      <Grid item xs={12} className="grid-body">
        <PaymentSignup />
      </Grid>
    </Grid>
  );
};

export default PaymentSignupScreen;
