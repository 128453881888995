import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Grid,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { isEmpty } from "../../helpers/Validation";
import { toTitleCase, hasValidPayment } from "../../helpers/GenericHelper";
import ChildProfileItem from "../../components/ChildProfileItem/ChildProfileItem";
import {
  getChildrenActivityProgress,
  getChildrenList,
} from "../../store/actions/Child";
import Loader from "../../components/Loader/Loader";
import RouteConstants from "../../constants/RouteConstants";
import AssetConstants from "../../constants/AssetConstants";
import {
  GENERIC_STATUS,
  ROLE_TYPES,
  APP_CONSTANTS,
} from "../../constants/GenericConstants";
import {
  getViewPermValue,
  getViewPermValueIndividual,
} from "../../helpers/PermissionCalculator";
import { PRIV_CONSTS } from "../../constants/StaticData";
import DateHelper from "../../helpers/DateHelper";
import { firstLogin } from "../../store/actions/Authentication";
import {
  getUser,
  resubscribePayment,
} from "../../store/actions/Authentication";
import SnackbarAlert from "../../components/SnackbarAlert/SnackbarAlert";

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const userState = useSelector((state) => state.authentication);
  const childState = useSelector((state) => state.child);
  const dispatch = useDispatch();
  const [childViewAccess, setChildViewAccess] = useState(false);
  const [showRenewalPopup, setShowRenewalPopup] = useState(false);
  const [showExternalUserPaymentPopup, setShowExternalUserPaymentPopup] =
    useState(false);
  const [internalLoading, setInternalLoading] = useState(false);

  const [resubscribeFailure, setResubscribeFailure] = useState(false);
  const [resubscribeFailureMessage, setResubscribeFailureMessage] =
    useState("");

  useEffect(() => {
    if (userState.isParent) {
      setShowRenewalPopup(!hasValidPayment(userState.user));
    }

    const OneSignal = window.OneSignal;

    OneSignal.push(function () {
      OneSignal.showHttpPrompt({ force: true });
    });

    dispatch(getChildrenList());
    let childViewPerm = getViewPermValue(PRIV_CONSTS.CHILDREN_PRIV);
    setChildViewAccess(childViewPerm);

    if (
      userState.user &&
      userState.isParent === true &&
      userState.user.loggedBefore === false
    ) {
      dispatch(
        firstLogin(
          () => {
            if (!userState.isSingleUser) {
              moveToAddChildScreen();
            }
          },
          (err) => {}
        )
      );
    }

    dispatch(
      getChildrenActivityProgress(
        DateHelper.formatDate(new Date(), "yyyy-MM-dd"),
        (res) => {},
        (err) => {}
      )
    );
  }, []);

  const moveToAddChildScreen = () => {
    history.push({ pathname: RouteConstants.SETUP_CHILD_PROFILE });
  };

  const displayRole = (role) => {
    switch (role) {
      case ROLE_TYPES.TEACHER:
        return toTitleCase(role);

      case ROLE_TYPES.THERAPIST:
        return toTitleCase(role);

      case ROLE_TYPES.OTHER:
        return toTitleCase(role);

      case ROLE_TYPES.CO_PARENT_GUARDIAN:
        return t("coParentGuardian");

      case ROLE_TYPES.ACADEMIC_ADVISOR:
        return t("academicAdvisor");

      case ROLE_TYPES.PARTNER_SPOUSE:
        return t("partnerSpouse");

      default:
        return "";
    }
  };

  const moveToPaymentScreen = () => {
    history.push({ pathname: RouteConstants.PAYMENT });
  };

  const handleClosePaymentDialog = () => {
    setShowExternalUserPaymentPopup(false);
  };

  const onFailureResubscribe = (errorMessage) => {
    setInternalLoading(true);
    setResubscribeFailure(true);
    setResubscribeFailureMessage(errorMessage);
  };

  const onGetUserSuccess = (data) => {
    dispatch(
      resubscribePayment(
        data.subscription.planId,
        () => {},
        onFailureResubscribe
      )
    );
  };

  const onGetUserFailure = () => {
    setInternalLoading(false);
  };

  const resubscribeToPayment = () => {
    setInternalLoading(true);
    history.push(RouteConstants.PAYMENT);
    // dispatch(getUser(onGetUserSuccess, onGetUserFailure));
  };

  const getParentStatus = () => {
    if (userState.user && Object.entries(userState.user).length > 0) {
      const parentArray = userState.user.accounts.filter(
        (account) => account.role === ROLE_TYPES.PARENT
      );
      if (parentArray.length > 0) {
        return parentArray[0].status;
      }
      return GENERIC_STATUS.ACTIVE;
    }
    return GENERIC_STATUS.ACTIVE;
  };

  // console.log("list",childState)

  return (
    <Container maxWidth="md" className="db-child-list">
      {childState.loading && <Loader />}
      {internalLoading && <Loader />}
      <SnackbarAlert
        isError={true}
        show={resubscribeFailure}
        message={resubscribeFailureMessage}
        duration={5000}
        onCloseAlert={() => {
          setResubscribeFailure(false);
        }}
      />
      <Dialog
        maxWidth="xs"
        open={showExternalUserPaymentPopup}
        onClose={handleClosePaymentDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseIcon className="popup-close" onClick={handleClosePaymentDialog} />
        <DialogTitle id="alert-dialog-title">
          {t("externalUserPaymentMessage")}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClosePaymentDialog}
            color="primary"
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={moveToPaymentScreen}
            color="primary"
          >
            {t("proceedToPayment")}
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className={
          ((userState.isParent &&
            getParentStatus() === GENERIC_STATUS.DISABLE) ||
            showRenewalPopup) &&
          "dashboard-first-block"
        }
      >
        {childState.childrenList.length > 0 ? (
          <div className="mb-40">
            <h1 className="main-title">
              {t(
                userState.isSingleUser
                  ? "dashboardWelcomeMessageSingleUser"
                  : "dashboardWelcomeMessage"
              ).replace(
                /{userName}/,
                !isEmpty(userState.user) ? userState.user.firstName : ""
              )}
            </h1>
            <div>
              <h4 className="title-center-inline">
                <span>
                  {t(
                    userState.isSingleUser
                      ? "yourSingleUserProfileTitle"
                      : "yourChildProfilesTitle"
                  )}
                </span>
              </h4>
              <Grid container spacing="3" className="child-container">
                {childState.childrenList.map((child, index) => {
                  return (
                    <Grid item md={6} xs={12} key={index}>
                      <ChildProfileItem child={child} />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        ) : (
          userState.isParent &&
          !childState.loading && (
            <div className="no-child-msg">
              <img src={AssetConstants.boyonbook} />
              <span>{t("noChildProfilesMessage")}</span>
            </div>
          )
        )}
        {!childState.loading &&
          childState.childrenList.length < 3 &&
          userState.isParent &&
          !userState.isSingleUser && (
            <div className="add-child-block">
              <h5>{t("wantToAddChild")}</h5>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={moveToAddChildScreen}
              >
                <AddIcon />
                {t("createNewChildProfile")}
              </Button>
            </div>
          )}
        {
          // !childState.loading && !userState.isParent && userState.isExternalUser &&
          // <div className="add-child-block">
          //     <h5>{t("wantToAddChild")}</h5>
          //     {/* <Button color="primary" variant="contained" disableElevation onClick={() => { setShowExternalUserPaymentPopup(true); }}> */}
          //     <Button color="primary" variant="contained" disableElevation onClick={moveToAddChildScreen}>
          //         <AddIcon />
          //         {t("createNewChildProfile")}
          //     </Button>
          // </div>
        }
        {showRenewalPopup &&
          !(userState.user.status === GENERIC_STATUS.DISABLE) && (
            <div className="warning-popup">
              <img src={AssetConstants.warninglogo} />
              <p>{t("nonPaymentDisabledAccountMessage")}</p>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={resubscribeToPayment}
              >
                {t("resumeSubscription")}
              </Button>
            </div>
          )}
        {userState.isParent && getParentStatus() === GENERIC_STATUS.DISABLE && (
          <div className="warning-popup">
            <img src={AssetConstants.warninglogo} />
            <p>
              {t("accessDisabledMessage").replace(
                /{supportEmail}/,
                APP_CONSTANTS.SUPPORT_EMAIL_ADDRESS
              )}
            </p>
          </div>
        )}
      </div>
      {childViewAccess &&
        !childState.loading &&
        childState.otherChildrenList.map((clientData, index) => {
          return (
            <div
              key={index}
              className={!clientData.isActive && "dashboard-first-block"}
            >
              <div className="dual-title-center-inline">
                <p>
                  <span>{t("clientName")}</span>
                  <h5>{clientData.clientName}</h5>
                </p>
                <p>
                  <span>{t("yourRole")}</span>
                  <h5
                    className={
                      clientData.role === ROLE_TYPES.THERAPIST
                        ? "role-therapist"
                        : clientData.role === ROLE_TYPES.TEACHER
                        ? "role-teacher"
                        : "role-co-guardian"
                    }
                  >
                    {displayRole(clientData.role)}
                  </h5>
                </p>
              </div>
              <Grid container spacing="3" className="child-container">
                {clientData.childProfiles.map((child, index) => {
                  return (
                    <Grid item md={6} xs={12} key={index}>
                      {/* {getViewPermValueIndividual(child.userPermissions, PRIV_CONSTS.CHILDREN_PRIV) ? <ChildProfileItem child={child} /> : null} */}
                      <ChildProfileItem child={child} />
                    </Grid>
                  );
                })}
              </Grid>
              {!clientData.isActive && (
                <div className="warning-popup">
                  <img src={AssetConstants.warninglogo} />
                  <p>
                    {t("externalUserDisabledMessage").replace(
                      /{supportEmail}/,
                      APP_CONSTANTS.SUPPORT_EMAIL_ADDRESS
                    )}
                  </p>
                </div>
              )}
            </div>
          );
        })}

      {childViewAccess &&
        !childState.loading &&
        childState.childrenList.length === 0 &&
        childState.otherChildrenList.length === 0 && (
          <div className="no-child-msg">
            <img src={AssetConstants.boyonbook} alt="No User Profile Found" />
            <span>{t("noProfilesMessage")}</span>
          </div>
        )}
    </Container>
  );
};

export default Dashboard;
